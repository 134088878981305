import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LuEye } from "react-icons/lu";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getShortlistedApplications } from "../../../../../redux/InterView/actions";
import { getRecruitment } from "../../../../../redux/recruitmentprocess/actions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { BsFillSendCheckFill } from "react-icons/bs";

import { AiOutlineSchedule } from "react-icons/ai";
import Loader from "../../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import {
  formatDate,
  getColorForStatus,
} from "../../../../../Constants/dateFormat";
import AddInterview from "./AddInterview";

import ViewDetails from "./ViewDetails";

import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import ViewOfferLetter from "./ViewOfferLetter";

const InterView = () => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let companyname = userData.companyname ? userData.companyname : "";

  const [offerLoad, setOfferLoad] = useState(false);

  //for offerletter view open
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerSelectRow, setofferSelectRow] = useState(null);

  const handleOfferViewClick = (paramsRow) => {
    setofferSelectRow(paramsRow);
    setOfferModalOpen(true);
  };

  // REDUX PART
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.interViewReducer
  );
  const { data: recruitmentMaster } = useSelector(
    (state) => state.recruitmentReducer
  );

  //for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [offerDrawerOpen, setOfferDrawerOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleAdd = (id) => {
    setSelectedRowId(id);
    setDrawerOpen(true);
  };


  const handleCloseDrawer = () => {
    setSelectedRowId(null);
    setDrawerOpen(false);
  };


  //for view modal
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
    setSelectedRow(params.row.id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  //for alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const interviewColumn = [
    { field: "candidateid", headerName: "CID" },
    { field: "recruitmentid", headerName: "RID", flex: 1 },
    { field: "createdAt", headerName: "Applied Date" },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "fullname", headerName: "Name", flex: 1 },
    { field: "budget", headerName: "Budget" },
    { field: "expectedctc", headerName: "ECTC in LPA" },
    {
      field: "cv",
      headerName: "Resume",
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => handleJDClick(params.row.cv)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    {
      field: "interviewstatus",
      headerName: "Interview Status",
      flex: 1,
      cellClassName: "MuiDataGrid-cell--withRenderer",
      renderCell: (params) => (
        <Button
          sx={{
            color: getColorForStatus(params.row.interviewstatus),
          }}
        >
          {params.row.onboardstatushistory[0]?.status == "Offerletter sent"
            ? "Offerletter sent"
            : params.row.interviewstatus}
        </Button>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Tooltip title="Schedule Interview" placement="top-end">
              <IconButton onClick={() => handleAdd(params.row.id)}>
                <AiOutlineSchedule />
              </IconButton>
            </Tooltip>

            {params.row.onboardstatushistory[0]?.status ===
              "Offerletter sent" && (
              <Tooltip title={"Offerletter Already Sent"} placement="top-end">
                <IconButton>
                  <BsFillSendCheckFill 
                  color="green"
                  // onClick={() => handleSendEmail(params.row)}
                   />
                </IconButton>
              </Tooltip>
            )}
            {params.row.interviewstatus == "Offer Letter Initiated" && (
  <Tooltip title="View Offer Letter" placement="top-end">
  <IconButton onClick={() => handleOfferViewClick(params.row)}>
    <LuEye />
  </IconButton>
</Tooltip>
            )}
          </Box>
        );
      },

      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
  ];

  const interviewRow =
    data.length > 0
      ? data.map((application) => {
          const recruitMent = recruitmentMaster.find(
            (recruit) => recruit.id === application.recruitmentid
          );

          return {
            id: application.id,
            candidateid: application.candidateid,
            recruitmentid: `RID-${application.recruitmentid}`,
            createdAt: formatDate(application.createdAt),
            position: application.position,
            fullname: application.fullname,
            budget: recruitMent
              ? `${recruitMent.frombudget} - ${recruitMent.tobudget}`
              : "-",
            expectedctc: application.expectedctc,
            cv: application.cv,
            interviewstatus: application.history
              ? application.history[application.history.length - 1]
                  ?.offerletterstatus
                ? "Offer Letter Initiated"
                : application.history[application.history.length - 1]
                    ?.interviewstatus || "N/A"
              : "N/A",
            onboardstatushistory: application.onboardstatushistory || [],
            data: application,
          };
        })
      : [];

  const handleJDClick = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop() || "File";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSendEmail = async (paramsRow) => {
    // console.log(paramsRow,"paramsrow")

    try {
      let res = await axios.post(
        "https://hrms.vliv.app/jobapplication/confirmmail",
        {
          ...paramsRow.data,
          companyname: companyname,
          date: new Date(),
          finalctc:
            paramsRow.data.history[paramsRow.data.history.length - 1].finalctc,
          joiningdate:
            paramsRow.data.history[paramsRow.data.history.length - 1]
              .joiningdate,
        },{withCredentials:true}
      );
      // console.log(res, "res");
      if (res.data.message == "Job application updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Offer Letter has been sent");
        setOfferLoad(true);
      } else {
        console.log(error, "error");
        setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message || "Something went wrong");
        setOfferLoad(true);
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
      setOfferLoad(false);
    }
  };

  useEffect(() => {
    dispatch(
      getShortlistedApplications(
        { companyid, companyemail },
        setOpen,
        setMsg,
        setAlertType
      )
    );
  }, [dispatch, drawerOpen, offerModalOpen]);

  useEffect(() => {
    dispatch(getRecruitment({ companyid, companyemail }));
  }, []);

  const isRowSelectable = (params) => {
    return params.row.interviewstatus.toLowerCase() !== "failed";
  };

  const getRowClassName = (params) => {
    return params.row.interviewstatus.toLowerCase() === "failed"
      ? "MuiDataGrid-row--disabled"
      : "";
  };

  // console.log(data, "list interview data");

  return (
    <>
      <Box sx={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={loading ? [] : interviewRow}
          columns={interviewColumn}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20, interviewRow.length]}
          autoHeight
          components={{
            LoadingOverlay: Loader,
          }}
          loading={loading}
          checkboxSelection
          disableRowSelectionOnClick
          isRowSelectable={isRowSelectable}
          getRowClassName={getRowClassName}
          onRowClick={handleRowClick}
        />
      </Box>
      <AddInterview
        drawerOpen={drawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        rowId={selectedRowId}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
      <ViewDetails
        open={modalOpen}
        handleClose={handleCloseModal}
        rowId={selectedRow}
      />
      <ViewOfferLetter
        offerModalOpen={offerModalOpen}
        offerSelectRow={offerSelectRow}
        setOfferModalOpen={setOfferModalOpen}
        setofferSelectRow={setofferSelectRow}
      />
    </>
  );
};

export default InterView;
