import React, { useEffect, useState } from "react";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import WestIcon from "@mui/icons-material/West";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateEmpExit,
} from "../../../../../redux/actions/actions.hrms/employeeExit.actions";
import { employeeExitGetbyID } from "../../../../../Constants/api";
import axios from "axios";
import { formatDate } from "../../../../../Constants/dateFormat";

const EditSeparation = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");
  const reportmanager = queryParams.get("report");

  const [data, setData] = useState({});
  const [showEmptyError, setShowEmptyError] = useState(false);

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const fetchEmployeeExitData = async () => {
    try {
      const res = await axios.post(
        employeeExitGetbyID,
        { id, reportmanager },
        { withCredentials: true }
      );
      setData((prev) => ({
        ...prev,
        ...res.data?.[0],
      }));
    } catch (err) {
      console.log(err);
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "exittype") {
      setData((prev) => ({
        ...prev,
        [name]: value,
        servenoticeperiod: "",
        resignationdate: "",
        lwddate: "",
        expectlwddate: "",
        shortfalldays: "",
      }));
    } else {
      let updatedData = { ...data, [name]: value };

      if (
        name === "servenoticeperiod" ||
        name === "resignationdate" ||
        name === "lwddate" ||
        name === "expectlwddate"
      ) {
        updatedData = calculateDates(updatedData);
      }

      setData(updatedData);
    }
  };

  const calculateDates = (updatedData) => {
    if (
      updatedData.servenoticeperiod === "Yes" &&
      updatedData.resignationdate
    ) {
      updatedData.lwddate = dayjs(updatedData.resignationdate)
        .add(updatedData.lwdperpolicy, "day")
        .format("YYYY-MM-DD");
      updatedData.shortfalldays = 0;
      updatedData.expectlwddate = ""; // Clear expectlwddate for Yes case
    } else if (
      updatedData.servenoticeperiod === "No" &&
      updatedData.resignationdate &&
      updatedData.expectlwddate
    ) {
      updatedData.lwddate = ""; // Clear lwddate for No case
      const resignationDate = dayjs(updatedData.resignationdate);
      const expectLwdDate = dayjs(updatedData.expectlwddate);
      const servedDays = expectLwdDate.diff(resignationDate, "day");
      updatedData.shortfalldays = updatedData.lwdperpolicy - servedDays;
    }
    return updatedData;
  };

  const handleUpdate = () => {
    dispatch(updateEmpExit(data, setOpen, setMsg, setAlertType, navigate));
  };

  const handleStatusChange=async(e,employeeid)=>{
    try {
      let res= await axios.post("https://hrms.vliv.app/exit/addstatus",{id:data.id,employeeid,status:e.target.value},{withCredentials:true});
      // console.log(res,"res")
      if(res.data && res.data.message=="Status updated successfully"){
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        fetchEmployeeExitData()
      }
    } catch (error) {
      console.log(error,"error while changing status");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message)
    }
  }

  useEffect(() => {
    if (id) {
      fetchEmployeeExitData();
    }
  }, [id]);

  return (
    <>
      <Box>
        <Box p={2} m={1} bgcolor="#F2F4F7" borderRadius={3}>
          <Typography
            variant="h5"
            color="#0B4A6F"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <IconButton
              onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
            >
              <WestIcon sx={{ color: "#0B4A6F" }} />
            </IconButton>
            <span>View & Edit Separation</span>
          </Typography>
        </Box>

        <Box p={2} m={1} bgcolor="#F2F4F7" borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Employee Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Name</Typography>
              <Typography className="view-page-title">{data.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Employee ID</Typography>
              <Typography className="view-page-title">
                {data.empid || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Department</Typography>
              <Typography className="view-page-title">
                {data.department || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Position</Typography>
              <Typography className="view-page-title">
                {data.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Reporting To</Typography>
              <Typography className="view-page-title">
                {data.reportmanager || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Date of Joining
              </Typography>
              <Typography className="view-page-title">
                {data.joiningdate?formatDate(data.joiningdate):"-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                LWD As per policy
              </Typography>
              <Typography className="view-page-title">
                {data.lwdperpolicy || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box p={2} m={1} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Exit Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="view-page-value">Type of Exit</Typography>
              <Select
                size="small"
                fullWidth
                className="formInputs"
                inputProps={{
                  className: "selectInput",
                }}
                name="exittype"
                value={data.exittype || ""}
                onChange={handleChange}
              >
                <MenuItem value="Voluntary">Voluntary</MenuItem>
                <MenuItem value="Termination">Termination</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="view-page-value">
                Serve Notice Period
              </Typography>
              <Select
                size="small"
                fullWidth
                className="formInputs"
                inputProps={{
                  className: "selectInput",
                }}
                name="servenoticeperiod"
                value={data.servenoticeperiod || ""}
                onChange={handleChange}
                error={!data.servenoticeperiod && showEmptyError}
              >
                {data.exittype == "Voluntary" && (
                  <MenuItem value="Yes">Yes</MenuItem>
                )}
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Grid>

            {data.exittype && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography className="view-page-value">
                    Resignation Date
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    type="date"
                    value={data.resignationdate}
                    onChange={handleChange}
                    name="resignationdate"
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                    }}
                    error={!data.resignationdate && showEmptyError}
                  />
                </Grid>

                <>
                  {data.servenoticeperiod === "Yes" ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="view-page-value">
                        LWD Date
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        type="date"
                        value={data.lwddate}
                        onChange={handleChange}
                        name="lwddate"
                        disabled={true}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography className="view-page-value">
                        Expected LWD Date
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        type="date"
                        value={data.expectlwddate}
                        onChange={handleChange}
                        name="expectlwddate"
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography className="view-page-value">
                      Leaving Reason
                    </Typography>
                    <Select
                      size="small"
                      fullWidth
                      className="formInputs"
                      name="leavingreason"
                      value={data.leavingreason}
                      onChange={handleChange}
                    >
                      <MenuItem value="Transfer">Transfer</MenuItem>
                      <MenuItem value="Resignation">Resignation</MenuItem>
                      <MenuItem value="Retirement">Retirement</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography className="view-page-value">
                      Shortfall Days
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      value={data.shortfalldays}
                      // onChange={handleChange}
                      name="shortfalldays"
                      disabled
                    />
                  </Grid>
                </>
              </>
            )}
          </Grid>
        </Box>

        {data.exittype && (
          <Box p={2} m={1} bgcolor="#F2F4F7" borderRadius={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Alternate Email ID
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={data.altemail}
                  name="altemail"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Alternate Phone No
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={data.altphone}
                  name="altphone"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Remarks
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  value={data.remarks}
                  name="remarks"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        )}

<Box p={1} m={1} px={3} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Reporting Managers approval logs
          </Typography>
          <Grid container spacing={3}>
            {data.managers?.map((manager, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  p={2}
                  bgcolor={"white"}
                  borderRadius={3}
                  boxShadow={3}
                  height="100%" // Ensures equal height for all boxes
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography className="view-page-value" mb={1}>
                    Name: {manager.name}
                  </Typography>
                  <Typography className="view-page-value" mb={1}>
                    Position: {manager.position}
                  </Typography>
                  <Typography className="view-page-value" mb={1}>
                    Email: {manager.email}
                  </Typography>

                  {/* Status Select */}
                  <FormControl fullWidth sx={{mt:1}}>
                    <InputLabel>Status</InputLabel>
                    <Select
                    size="small"
                      value={manager.status || "Pending"}
                      label="Status"
                      onChange={(e) => handleStatusChange(e, companyInfo.empid)} 
                      disabled={companyInfo.email!==manager.email}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {data.exittype && (
        <Box
          mt="auto"
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          bgcolor="background.paper"
          p={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Button className="saveBtn" onClick={handleUpdate}>
            Save
          </Button>
          <Button
            className="cancelBtn"
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
          >
            Cancel
          </Button>
        </Box>
      )}
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EditSeparation;
