// NotificationAlert.js
import React, { createContext, useContext } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

function NotificationProvider({ children }) {
    const { enqueueSnackbar } = useSnackbar();

    const notifySuccess = (message) => enqueueSnackbar(message, { variant: 'success' });
    const notifyWarning = (message) => enqueueSnackbar(message, { variant: 'warning' });
    const notifyError = (message) => enqueueSnackbar(message, { variant: 'error' });
    const notifyInfo = (message) => enqueueSnackbar(message, { variant: 'info' });

    return (
        <NotificationContext.Provider value={{ notifySuccess, notifyWarning, notifyError, notifyInfo }}>
            {children}
        </NotificationContext.Provider>
    );
}

export default function NotificationAlert({ children }) {
    return (
        <SnackbarProvider maxSnack={3}>
            <NotificationProvider>{children}</NotificationProvider>
        </SnackbarProvider>
    );
}
