import React,{useState} from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";


export const AuthContext =React.createContext();

function AuthContextProvider ({children}) {
    const [authContext, setAuthContext] = useState({
        token:  Cookies.get("companytoken") || null,
        userData:{}
    })
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 425);
    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth <= 425);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    return (<AuthContext.Provider value={{authContext,setAuthContext, isMobileView}}>{children}</AuthContext.Provider>)
}
export default AuthContextProvider;