import React, { useEffect, useState, useCallback } from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../../Context/AuthContextProvider';
import { useNotification } from '../../../Constants/NotificationAlert';
import { Box, Button, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from 'axios';
import Modal from './CommonModal';
import CommonModal from './CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { reloadPage } from '../../../redux/actions/action.mobile/load.action';
import { formatAttendanceTime, formatDate, formatTimeToAmPm } from '../../../Constants/dateFormat';
import { addAttendanceCheckin, addAttendanceCheckout } from '../../../Constants/api';
import { employeeinfo, getTodaysAttendance } from '../../../redux/actions/action.mobile/employeeinfo.action';
import { CHECKIN_TIME, CHECKOUT_TIME } from '../../../redux/actionTypes';
import { FaDoorOpen } from "react-icons/fa";
import { FaDoorClosed } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";

function Attendance() {
  const { authContext } = useContext(AuthContext);
  const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail } = authContext.userData;
  // console.log(authContext)
  const { notifySuccess, notifyError, notifyWarning, notifyInfo } = useNotification();
  const GOOGLE_MAPS_API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54";
  const loadData = useSelector((state) => state.loadReducer);
  const dispatch = useDispatch();
  const { employeeData: empInfo, checkInTime, checkOutTime, totalHours } = useSelector((state) => state.employeeReducer);
  const checkInButton = !checkInTime ? "Check In" : checkInTime;
  const checkOutButton = !checkOutTime ? "Check Out" : checkOutTime;
  // console.log("empInfo", empInfo)
  // console.log("checkInTime", checkInTime)
  // console.log("checkOutTime", checkOutTime)
  // console.log("totalHours", totalHours)
  //State
  const [currentPosition, setCurrentPosition] = useState(null);
  const [map, setMap] = useState(null)
  const [locationData, setLocationData] = useState({
    city: "",
    location: "",
    country: "",
    state: "",
  });
  const [open, setOpen] = useState(false);
  const [currentButton, setCurrentButton] = useState("")

  const handleOpen = (buttonname) => {
    setCurrentButton(buttonname);
    setOpen(true)
  };
  const handleClose = () => {
    setCurrentButton("");
    setOpen(false)
  };

  const fetchLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getAddressFromCoordinates(latitude, longitude);
        setCurrentPosition({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error fetching location: ", error);
      }
    );
  }

  const getAddressFromCoordinates = async (lat = currentPosition.lat, lon = currentPosition.lng) => {
    const API_KEY = GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

    try {
      const response = await axios.get(url);
      const result = response.data.results[0];
      if (result) {
        const addressComponents = result.address_components;

        let city = "";
        let state = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        // Update the cred object with the location details
        setLocationData((prevCred) => ({
          ...prevCred,
          location: result.formatted_address, // Full address
          city: city,
          state: state,
          country: country
        }));
      } else {
        console.error("No results found for the given coordinates");
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  useEffect(() => {
    dispatch(reloadPage(false));
    fetchLocation();
    if (companyemail) {
      const backendendData = {
        companyid,
        companyemail,
        email
      }
      dispatch(employeeinfo(backendendData));
    }

  }, [loadData, companyemail]);

  useEffect(() => {
    if (map && currentPosition) {
      // Add a marker directly using Google Maps JavaScript API
      new window.google.maps.Marker({
        position: currentPosition,
        map: map,
        title: "Your Location"
      });
    }
  }, [map, currentPosition]);

  useEffect(() => {
    if (empInfo?.empid) {
      const backendData = {
        companyid,
        companyemail,
        employeeid: empInfo?.empid,
        date: formatDate(new Date()),
      }
      // getPayslipForEmployee(empInfo.empid);
      dispatch(getTodaysAttendance(backendData));
    }
  }, [empInfo])

  // useEffect(() => {
  //   if (!checkInTime) {
  //     getAddressFromCoordinates();
  //   }
  // }, [checkInTime])


  // Function

  const mapOptions = {
    zoom: 16,
    center: currentPosition,
    disableDefaultUI: true,
  };

  const mapContainerStyle = {
    width: "100vw",
    height: "calc(100vh - 60px)",
  };

  const handleCheckIn = async () => {
    if (checkInTime && checkOutTime) return;

    handleOpen("Check In");
    setTimeout(() => {
      handleClose()
    }, 5000);

    try {

      const now = new Date();
      const temp = formatAttendanceTime(now);
      const reqbodydata = {
        employeeid: empInfo.empid ? empInfo.empid : "",
        tableemployeeid: empInfo.id || "",
        name: empInfo.name ? empInfo.name : "",
        date: temp.date,
        day: temp.day,
        month: temp.month,
        year: temp.year,
        checkin: formatTimeToAmPm(now),
        checkout: "",
        totalhours: "",
        status: "",
        location: locationData?.location,
        companyemail,
        companyid,
      };

      let res = await axios.post(addAttendanceCheckin, reqbodydata, { withCredentials: true });
      if (res.data.message == "Attendance added successfully") {
        notifySuccess(res.data.message)
        dispatch({ type: CHECKIN_TIME, payload: formatTimeToAmPm(now) });
      }
    } catch (error) {
      console.log(`Error while checkin`, error.response.data.message || "Something went wrong");
      notifyError(error.response.data.message || "Something went wrong");
    }
  };

  const handleCheckOut = async () => {
    if (!checkInTime) {
      notifyWarning("Please check in first.");
      return;
    }

    if (checkOutTime) return;

    handleOpen("Check Out");
    setTimeout(() => {
      handleClose()
    }, 5000)

    try {
      const now = new Date();
      const temp = formatAttendanceTime(now);
      const checkInDate = new Date(`1970-01-01 ${checkInTime}`);
      const checkOutDate = new Date(`1970-01-01 ${formatTimeToAmPm(now)}`);

      // Calculate the difference in milliseconds
      const diffInMilliseconds = checkOutDate - checkInDate;

      // Convert milliseconds to hours
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      // Return the result formatted to 2 decimal places
      let avgHr = diffInHours.toFixed(2);
      // Make API call
      let res = await axios.post(
        addAttendanceCheckout,
        {
          date: temp.date,
          employeeid: empInfo.empid ? empInfo.empid : "",
          checkout: formatTimeToAmPm(now),
          companyid,
          totalhours: avgHr,
          location2: locationData?.location,
        },
        { withCredentials: true }
      );

      if (res.data.message === "Attendance updated successfully") {
        notifySuccess("Checkout Successful");
        dispatch({ type: CHECKOUT_TIME, payload: formatTimeToAmPm(now) });
      }
    } catch (error) {
      console.log(`Error while checkout`, error.response.data.message || "Something went wrong");
      notifyError(error.response.data.message || "Something went wrong");
    }
  };

  // console.log("location", locationData)
  return (
    <Grid container className='google-map'>
      <Grid item xs={12}>
        {currentPosition ?
          <>
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={currentPosition}
                options={mapOptions}
                onLoad={(map) => {
                  setMap(map)
                }}
              />
              {/* <Marker position={currentPosition} /> */}
              {/* </GoogleMap > */}

            </LoadScript>
            <Stack className='attendance-button'>
              <Button
                fullWidth
                className={`mobile-commonbutton checkin`}
                onClick={checkInButton === "Check In" ? handleCheckIn : null}
                disabled={!checkInButton === "Check In"}
                startIcon={<FaDoorOpen />}
                sx={{
                  '&.mobile-commonbutton': {
                    '@media (max-width: 412px)': {
                      fontSize: '1.2rem',
                      padding: '7px',
                    },

                    '@media (max-width: 360px)': {
                      fontSize: '1rem',
                    },
                  },
                }}
              >
                {checkInButton}
              </Button>
              <Button
                fullWidth
                className='mobile-commonbutton checkout'
                onClick={checkOutButton === "Check Out" ? handleCheckOut : null}
                disabled={!checkOutButton === "Check Out"}
                endIcon={<FaDoorClosed />}
                sx={{
                  '&.mobile-commonbutton': {
                    '@media (max-width: 412px)': {
                      fontSize: '1.2rem',
                      padding: '7px',
                    },

                    '@media (max-width: 360px)': {
                      fontSize: '1rem',
                    },
                  },
                }}
              >
                {checkOutButton}
              </Button>
            </Stack>
          </>
          :
          <Stack spacing={2}>
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
            <Skeleton variant="rounded" sx={{ width: "100vw", height: "10vh" }} />
          </Stack>
        }
      </Grid>
      <Grid item xs={12}>
        <CommonModal open={open} handleOpen={handleOpen} handleClose={handleClose} buttonname={currentButton}>
          <Stack>
            <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
              <Typography variant='subtitle1'>Location:</Typography>
              <Typography variant="subtitle2" sx={{ color: `${currentButton === "Check In" ? "var(--payment-sucess)" : "var(--danger-color)" }`, fontSize: "1.5rem" }}><FaCheckCircle /></Typography>
            </Stack>

            {!locationData.location ?
              <>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </>
              :
              <Typography variant="subtitle2">
                {locationData.location}
              </Typography>}
          </Stack>
        </CommonModal>
      </Grid>
    </Grid>
  )
}

export default Attendance