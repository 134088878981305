import {
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../../Constants/Loader";
import AddTeamBuildingKpi from "./AddTeamBuildingKpi";


const TeamBuildingKpi = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";

  const [loading, setLoading] = useState(false);
  const [empInfo, setEmpInfo] = useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email == email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });
  }, []);

  useEffect(() => {
    if (empInfo.email) {
      setLoading(true);
      axios
        .post("https://hrms.vliv.app/performance/teamkpi/get", {
          companyemail,
          companyid,
          email: empInfo.email,
        },{withCredentials:true})
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setData(res.data);
            setFilteredData(res.data);
          } else {
            setData([]);
            setFilteredData([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "err");
          setLoading(false);
        });
    }
  }, [empInfo, drawerOpen]);

  useEffect(() => {
    const filteredRows = data.filter((row) => {
      return (
        row.kpiname.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        row.description.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    });
    setFilteredData(filteredRows);
  }, [searchKeyword, data]);

  const columns = [
    { field: "kpiname", headerName: "KPI Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
  ];

  const rows =
    filteredData.length > 0
      ? filteredData.map((kpi) => ({
          id: kpi.id,
          kpiname: kpi.kpiname,
          description: kpi.description,
        }))
      : [];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(data, "empinfo");

  return (
    <>
      <Box borderRadius={2} my={2} className="commonShadow">
        <Box>
          <Typography
            variant={isSmallScreen ? "h6" : "h5"}
            className="master-heading"
            p={2}
          >
           Team Building KPI
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            p={1}
          >
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              style={{ marginRight: "16px" }}
            />
            <Button className="saveBtn" onClick={handleDrawerOpen}>
              Add
            </Button>
          </Box>
        </Box>
        <Box my={1}>
          <Paper style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={loading ? [] : rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, rows.length]}
              checkboxSelection
              getRowId={(row) => row.id}
              autoHeight
              components={{
                LoadingOverlay: Loader,
              }}
              loading={loading}
              // onRowClick={handleRowClick}
            />
          </Paper>
        </Box>
      </Box>
      <AddTeamBuildingKpi
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        empInfo={empInfo}
        isSmallScreen={isSmallScreen}
      />
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default TeamBuildingKpi;
