import React, { useContext, useEffect, useState } from 'react'
import useFetch from '../../../Constants/hooks/useFetch'
import { AuthContext } from '../../../Context/AuthContextProvider';
import { FaChevronLeft, FaDoorClosed, FaDoorOpen } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { getTimeAttendance } from '../../../redux/actions/actions.hrms/timeAttendance.actions';
import { formatDate } from '../../../Constants/dateFormat';
import { useLocation, useParams } from 'react-router-dom';

function AttendanceLogs() {
  const { authContext } = useContext(AuthContext);
  const { userid, username, userrole, email, userphone, companyname, clientimage, companyid, companyemail, empid } = authContext.userData;
  // const options = { companyid, companyemail }
  const {
    timeAttendanceData,
    timeAttendance_error,
    timeAttendance_loading,
    timeAttendanceByIDData,
  } = useSelector((state) => state.timeAttendanceReducer);
  const loadData = useSelector((state) => state.loadReducer);
  const dispatch = useDispatch();
  // const { data, error, loading } = useFetch("", options);

  //state
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [startDay, setStartDay] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [attendanceByDate, setAttendanceByDate] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [showCheckinDetails, setShowCheckINDetails] = useState(false)

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const date = new Date(year, month, 1);
    const days = [];

    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);

    }

    setDaysInMonth(days);
    const firstDayOfWeek = new Date(year, month, 1).getDay();

    setStartDay((firstDayOfWeek + 6) % 7);

  }, [currentDate]);

  useEffect(() => {
    dispatch(
      getTimeAttendance({
        companyid,
        companyemail,
      })
    );
  }, [dispatch, currentDate]);

  useEffect(() => {

    if (timeAttendanceData.length > 0) {
      const filteredEmployeeData = timeAttendanceData && timeAttendanceData?.filter(record => record.employeeid === empid);

      const attendance = {};
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      filteredEmployeeData.forEach(record => {
        const recordDate = new Date(record.date.split("/").reverse().join("-"));
        recordDate.setHours(0, 0, 0, 0);

        const date = record.date;
        const totalhours = Number(record.totalhours) || 0;

        if (record.checkin && record.checkout) {
          // attendance[date] = "Present";
          if (totalhours >= 9 ) {
            attendance[date] = "Present";
          } 
          else if (totalhours >= 4 && totalhours <= 9) {
            attendance[date] = "Incomplete";
          }
          else {
            attendance[date] = "Absent";
          }
        } else if (record.checkin && !record.checkout) {
          if (recordDate < today && totalhours >= 4) {
            attendance[date] = "Incomplete";
          }
        } else if (recordDate < today) {
          attendance[date] = "Absent";
        }
      });

      daysInMonth.forEach(day => {
        const dateStr = day.toLocaleDateString('en-GB'); // Format as "DD/MM/YYYY"

        if (!(dateStr in attendance) && day < today && day.toDateString() !== today.toDateString()) {
          attendance[dateStr] = "Absent";
        }
      });
      setShowCheckINDetails(true);
      setAttendanceByDate(attendance);
    }
  }, [loadData, timeAttendanceData, empid]);

  useEffect(() => {
    if (showCheckinDetails && timeAttendanceData.length > 0) {
      attendanceInfo(new Date());
    }

  }, [showCheckinDetails]);

  const daysName = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handlePrev = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  const handleNext = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  const attendanceInfo = (currentDate) => {
    if (timeAttendanceData.length > 0) {
      const filteredEmployeeData = timeAttendanceData && timeAttendanceData?.filter(record => record.employeeid === empid);

      const formatedDate = formatDate(currentDate);
      const employeeData = filteredEmployeeData && filteredEmployeeData?.find(item => item.date == formatedDate);

      setEmployeeData(employeeData);
    }
  }

  const handleDate = (date) => {
    setSelectedDate(date);
    attendanceInfo(date);
  };

  const showNextButton = currentDate.getMonth() < new Date().getMonth() || currentDate.getFullYear() < new Date().getFullYear();
  const date = formatDate(selectedDate);
  return (
    <>
      <div className="calendar">
        <div className="calender-header">
          <button onClick={handlePrev}><FaChevronLeft /></button>
          <div>
            <span>{currentDate.toLocaleString("default", { month: "long" })}</span>
            <span>{currentDate.getFullYear()}</span>
          </div>
          <button onClick={handleNext}>{showNextButton && <FaChevronRight />}</button>
        </div>
        <div className="calender-day--name">
          {daysName.map((day) => {
            return (
              <div key={day} className="day-name">
                {day}
              </div>
            );
          })}
        </div>
        <div className="calender-date">
          {Array.from({ length: startDay }).map((_, index) => (
            <div className="empty-day" key={index}></div>
          ))}
          {/* {daysInMonth.map((day) => {

          return (
            <div
              key={day}
              className={`day ${day.getDate() === new Date().getDate() &&
                day.getMonth() === new Date().getMonth() &&
                day.getFullYear() === new Date().getFullYear()
                ? "today"
                : ""
                }  
                ${selectedDate &&
                  day.toDateString() === selectedDate.toDateString()
                  ? "selected"
                  : ""
                }`}
              onClick={() => handleDate(day)}
            >
              {day.getDate()}
            </div>
          )
        })} */}

          {daysInMonth.map((day) => {
            const dateStr = day.toLocaleDateString('en-GB'); // Format date as "DD/MM/YYYY" for matching
            const status = attendanceByDate[dateStr]; // Get status for the date

            // Determine CSS class based on attendance status
            let dayClass = "day";
            if (status === "Present") dayClass += " present";
            else if (status === "Absent") dayClass += " absent";
            else if (status === "Incomplete") dayClass += " incomplete"; // No color

            return (
              <div
                key={day}
                className={`${dayClass} ${day.getDate() === new Date().getDate() &&
                  day.getMonth() === new Date().getMonth() &&
                  day.getFullYear() === new Date().getFullYear() ? "today" : ""
                  } ${selectedDate && day.toDateString() === selectedDate.toDateString() ? "selected" : ""}`}

                onClick={() => handleDate(day)}
              >
                {day.getDate()}
              </div>
            );
          })}
        </div>
      </div>
      <div className='attendancestatus'>
        <span className="details present">
          Present
        </span>
        <span className="details absent">
          Absent
        </span>
        <span className="details incomplete">
          Half Day
        </span>
      </div>

      {
        <>
          <div className='attendance-date'>
            <span>{formatDate(selectedDate)}</span>
            <span className='time'>Total Hours: {!employeeData?.totalhours ? "0.00" : employeeData?.totalhours} hr</span>
          </div>
          <div className='attendancedetails'>
            <div className='attendanceinfo'>
              <span className='checkindoor-icon'><FaDoorOpen /></span>
              {!employeeData?.checkin ?
                <span className='time'>No record found</span> :
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className='time'>{!employeeData?.checkin ? "--" : employeeData.checkin}</span>
                  <span className='atten-address'>{!employeeData?.location ? "location not found" : employeeData.location}</span>
                </div>
              }
            </div>
            <div className='attendanceinfo'>
              <span className='checkoutdoor-icon'><FaDoorClosed /></span>
              {!employeeData?.checkout ?
                <span className='time'>No record found</span> :
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className='time'>{!employeeData?.checkout ? "--" : employeeData.checkout}</span>
                  <span className='atten-address'>{!employeeData?.location2 ? "--" : employeeData.location2}</span>
                </div>
              }
            </div>
          </div>
        </>
      }


    </>
  )
}

export default AttendanceLogs;