import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";

import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";

import {
  getAttLeaveReport,
} from "../../../../../redux/actions/actions.hrms/attLeaveReport.actions";

import axios from "axios";
import { capitalizeWords } from "../../../../../Constants/constants";

const LeaveList = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const [allLeaveTypes, setAllLeaveTypes] = useState([]);
  const dispatch = useDispatch();
  const { attLeaveReportData, attLeaveReport_error, attLeaveReport_loading } =
    useSelector((state) => state.attLeaveReportReducer);

  const employeeData =
    attLeaveReportData &&
    attLeaveReportData.result &&
    attLeaveReportData.result.length
      ? attLeaveReportData.result
      : [];

  useEffect(() => {
    dispatch(
      getAttLeaveReport({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  const getAllLeaVeMaster = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/attedance/leavetype/get",
        {
          companyid: companyInfo.companyid,
          companyemail: companyInfo.companyemail,
        },
        { withCredentials: true }
      );
      res.data && res.data.length
        ? setAllLeaveTypes(res.data)
        : setAllLeaveTypes([]);
    } catch (error) {
      console.log(error, "erro");
    }
  };

  useEffect(() => {
    getAllLeaVeMaster();
  }, []);

  const columns = [
    { field: "empid", headerName: "Employee ID", width: 120 },
    { field: "name", headerName: "Employee Name", width: 200 },
    { field: "position", headerName: "Position", width: 150 },
    { field: "department", headerName: "Department", width: 180 },
    ...allLeaveTypes.map((leave) => ({
      field: leave.leavetype,
      headerName: `${capitalizeWords(leave.leavetype)} (${leave.count || 0})`,
      width: 190,
      renderCell: (params) => params.value || 0,
    })),
  ];

  const rows = employeeData.map((employee) => {
    const leaveCounts = employee.leaves.reduce((acc, leave) => {
      acc[leave.leavetype] = leave.remainingLeaves;
      return acc;
    }, {});
    return { ...employee, ...leaveCounts };
  });

 

  return (
    <>
      <Box>
        <Box className="searchbar-container"></Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            // rows={attLeaveReport_loading ? [] : filteredData}
            rows={attLeaveReport_loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            // onRowClick={handleRowClick}
            pageSizeOptions={[5, 10, 15, 20]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={attLeaveReport_loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default LeaveList;
