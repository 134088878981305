import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../../Constants/Loader";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import DeleteModal from "../../../../Constants/DeleteModal";
import AddLeaveType from "./AddLeaveType";
import { DataGrid } from "@mui/x-data-grid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  deleteLeaveType,
  getLeaveType,
} from "../../../../redux/actions/actions.master/leaveType.actions";

const ListLeaveType = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { leaveTypeData, error, loading } = useSelector(
    (state) => state.leaveTypeReducer
  );

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [itemId, setItemid] = useState("");
  const [show, setShow] = useState(false);
  // const [refresh, setRefresh] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const columns = [
    {
      field: "checkbox",
      headerName: (
        <Checkbox
          indeterminate={
            selectedRowIds.length > 0 &&
            selectedRowIds.length < leaveTypeData.length
          }
          checked={selectedRowIds.length === leaveTypeData.length}
          onChange={(event) => {
            const checked = event.target.checked;
            const ids = checked ? leaveTypeData.map((row) => row.id) : [];
            setSelectedRowIds(ids);
          }}
        />
      ),
      headerClassName: "datagrid-header",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRowIds.includes(params.row.id)}
          onChange={(event) => handleCheckboxChange(event, params.row.id)}
        />
      ),
    },
    {
      field: "leavetype",
      headerName: "Leave Type",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "count",
      headerName: "No. of Leaves",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "datagrid-header",
      flex: 1,
    },
  ];

  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    let updatedSelectedRowIds = [...selectedRowIds];
    if (checked) {
      updatedSelectedRowIds.push(id);
    } else {
      updatedSelectedRowIds = updatedSelectedRowIds.filter(
        (rowId) => rowId !== id
      );
    }
    setSelectedRowIds(updatedSelectedRowIds);
  };
  const handleReset = () => {
    setSelectedRowIds([]);
  };
  const handleShow = (selectedRowIds) => {
    setShow(true);
    setItemid(selectedRowIds);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedRowIds([]);
  };

  const handleDelete = () => {
    dispatch(
      deleteLeaveType(
        {
          idarray: itemId,
        },
        handleClose
      )
    );
  };

  useEffect(() => {
    dispatch(
      getLeaveType({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  // console.log(leaveTypeData, "master leaev type");
  return (
    <>
      <Box>
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px"}
          marginTop={"20px"}
          borderRadius={"15px"}
        >
          <Typography className="colored-heading">
            List of all Leave Type
          </Typography>
          <Divider />
          {selectedRowIds.length > 0 ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant="h6"
                color={"#062C41"}
                fontWeight={"bold"}
                fontSize={"16px"}
                gutterBottom
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                textAlign={"center"}
                gap={"10px"}
                boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                borderRadius={"10px"}
                padding={"5px"}
              >
                {selectedRowIds.length === leaveTypeData.length
                  ? `All ${selectedRowIds.length} Records Selected`
                  : selectedRowIds.length == 1
                  ? `${selectedRowIds.length} Record Selected`
                  : `${selectedRowIds.length} Records Selected`}
                <span>
                  <Tooltip title="Reset Selected" placement="top">
                    <IconButton onClick={handleReset}>
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </span>
                <DeleteModal
                  show={show}
                  handleShow={() => handleShow(selectedRowIds)}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  name="Leave Type"
                />
              </Typography>
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              p={1}
              gap={2}
            >
              <AddLeaveType openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} leaveTypeData={leaveTypeData}/>
            </Box>
          )}
          <Box
            sx={{
              maxHeight: 350,
              overflow: "auto",
              position: "relative",
              p: 1,
            }}
          >
            <DataGrid
              rows={loading ? [] : leaveTypeData}
              autoHeight
              components={{
                LoadingOverlay: <Loader />,
              }}
              loading={loading}
              columns={columns}
              hideFooterPagination={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ListLeaveType;
