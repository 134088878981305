import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const OptionalHolidayModal = ({
  optionalHolidayModalOpen,
  closeOptionalHolidayModal,
  holidays,
}) => {
  return (
    <>
      <Dialog
        open={optionalHolidayModalOpen}
        onClose={closeOptionalHolidayModal}
        fullWidth
        maxWidth="md"
      >
        <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            List of Optional Holidays
          </Typography>

          <IconButton onClick={closeOptionalHolidayModal}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider/>
        <DialogContent px={2} sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2,gap:2  }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#E4E7EC" }}>
                  <TableCell>Occasion/Festival</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Day</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holidays
                  ?.filter((holiday) => holiday.type == "Optional")
                  .map((holiday) => (
                    <TableRow key={holiday.id}>
                      <TableCell>{holiday.name}</TableCell>
                      <TableCell>
                        {new Date(holiday.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(holiday.date).toLocaleDateString("en-US", {
                          weekday: "long",
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OptionalHolidayModal;
