import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const EmployeeDocuments = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let email = userData.email ? userData.email : "";
  let username = userData.username ? userData.username : "";
  // console.log(username, "username");

  const [empInfo, setEmpInfo] = useState({});
  const [personal, setPersonal] = useState({});
  const [educational, setEducational] = useState([]);

  const [letter, setLetter] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleViewClick = (url) => {
    setModalContent(url);
    setModalOpen(true);
  };

  const handleDownloadClick = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // For letter documents view/ download start by sahid
  const [openLetter, setOpenLetter] = useState(false);
  const [letterData, setLetterData] = useState("");
  const handleViewLetterClick = (data) => {
    setLetterData(data);
    setOpenLetter(true);
  };

  const handleDownloadLetterClick = async (data, name) => {
    if (!data) {
        return;
    }
    // console.log(name, "letter name")

    // Create a container div and set its innerHTML to the provided data
    const container = document.createElement('div');
    container.innerHTML = data;
    
    // Apply styles to the container to add padding and ensure it fits within an A4 page
    container.style.padding = '20px'; // Add padding around the content
    container.style.width = '210mm'; // A4 width in mm
    container.style.height = '297mm'; // A4 height in mm
    container.style.boxSizing = 'border-box'; // Ensure padding is included in the dimensions

    // Append the container to the document body
    document.body.appendChild(container);

    try {
        // Use html2canvas to render the element into a canvas
        const canvas = await html2canvas(container, {
            scale: 2,
            useCORS: true,
            logging: true,
        });

        // Convert the canvas to an image
        const imgData = canvas.toDataURL('image/png');

        // Create a new jsPDF instance with A4 size
        const pdf = new jsPDF('p', 'mm', 'a4');

        // Calculate the width and height to fit the image within the A4 page
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        // Save the PDF
        pdf.save(`${name}pdf.pdf`);
    } catch (error) {
        console.error('Error generating PDF:', error);
    } finally {
        // Remove the container from the document body
        document.body.removeChild(container);
    }
};



  const handleLetterDialogClose = () => {
    setOpenLetter(false);
  };
  // For letter document view/download end by sahid

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/employee/get", { companyemail, companyid },{withCredentials:true})
      .then((res) => {
        if (res.data && res.data.length > 0) {
          let data = res.data.find((el) => el.email === email);
          if (data) {
            setEmpInfo(data);
          } else {
            setEmpInfo({});
          }
        } else {
          setEmpInfo({});
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setAlertType("error");
        setOpen(true);
        setMsg(err.message);
      });
  }, []);

  useEffect(() => {
    // console.log("user")
    if (empInfo.empid) {
      axios
        .post("https://hrms.vliv.app/employee/getdocuments", {
          companyemail,
          companyid,
          empid: empInfo.empid,
          tableemployeeid:empInfo.id,
        },{withCredentials:true})
        .then((res) => {
          // console.log(res.data, "employee documents");
          if (res.data) {
            setPersonal(res.data.personal || {});
            setEducational(res.data.education || []);
            setLetter(res.data.letter || []);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          setAlertType("error");
          setOpen(true);
          setMsg(err.message);
        });
    }
  }, [empInfo]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box m={2} className="common-bg-color" borderRadius={2}>
        <Box p={2}>
          <Typography
            variant={isSmallScreen ? "h6" : "h5"}
            className="primarycolor"
          >
            Documents
          </Typography>
          <Divider />

          <Box mt={1} mb={3}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Personal Documents
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {Object.keys(personal).length > 0 ? (
                    <>
                      <Grid item xs={3}>
                        <Typography>Aadhaar</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() => handleViewClick(personal.aadhar)}
                        >
                          <VisibilityIcon
                            fontSize="small"
                            className="primarycolor"
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDownloadClick(personal.aadhar)}
                        >
                          <DownloadIcon fontSize="small" color="success" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Passport</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() => handleViewClick(personal.passport)}
                        >
                          <VisibilityIcon
                            fontSize="small"
                            className="primarycolor"
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDownloadClick(personal.passport)}
                        >
                          <DownloadIcon fontSize="small" color="success" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>PAN</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() => handleViewClick(personal.pan)}
                        >
                          <VisibilityIcon
                            fontSize="small"
                            className="primarycolor"
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDownloadClick(personal.pan)}
                        >
                          <DownloadIcon fontSize="small" color="success" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Bank Account No</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{personal.bankAccountNo}</Typography>
                      </Grid>
                    </>
                  ) : (
                    <Typography pl={2}>
                      No personal documents available
                    </Typography>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Educational Documents
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {educational.length > 0 ? (
                    educational.map((doc, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={3}>
                          <Typography>{doc.educationname}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() => handleViewClick(doc.marksheet)}
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDownloadClick(doc.marksheet)}
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{doc.passoutyear}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{doc.percentage}%</Typography>
                        </Grid>
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography pl={2}>
                      No educational documents available
                    </Typography>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Letter
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {letter.length > 0 ? (
                    letter.map((item, index) => (
                      <React.Fragment key={index}>
                 
                 <Grid item xs={3}>
                          <Typography>
                            Appraisal Letter
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() =>
                              handleViewLetterClick(
                                item.appraisalarray && item.appraisalarray.length > 0 && item.appraisalarray[0].format
                              )
                            }
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDownloadLetterClick(
                                item.appraisalarray&& item.appraisalarray.length > 0 && item.appraisalarray[0].format, "Appraisal"
                              )
                            }
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>
                
                        <Grid item xs={3}>
                          <Typography>
                            Promotion Letter
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() =>
                              handleViewLetterClick(
                                item.promotionarray && item.promotionarray.length > 0 && item.promotionarray[0].format
                              )
                            }
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDownloadLetterClick(
                                item.promotionarray && item.promotionarray.length > 0 && item.promotionarray[0].format, "Promotion"
                              )
                            }
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>
                    
                        <Grid item xs={3}>
                          <Typography>
                            NOC Document
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() =>
                              handleViewLetterClick(
                                item.nocarray && item.nocarray.length > 0 && item.nocarray[0].format
                              )
                            }
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDownloadLetterClick(
                                item.nocarray && item.nocarray.length > 0 && item.nocarray[0].format, "NOC"
                              )
                            }
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography>
                            F&F Document
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() =>
                              handleViewLetterClick(
                                item.fandaarray && item.fandaarray.length > 0 && item.fandaarray[0].format
                              )
                            }
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDownloadLetterClick(
                                item.fandaarray && item.fandaarray.length > 0 && item.fandaarray[0].format, "fandf"
                              )
                            }
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography>
                            Experience Letter
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() =>
                              handleViewLetterClick(
                                item.experiencearray && item.experiencearray.length > 0 && item.experiencearray[0].format
                              )
                            }
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDownloadLetterClick(
                                item.experiencearray && item.experiencearray.length > 0 && item.experiencearray[0].format, "Experience"
                              )
                            }
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography>
                            Recommendation Letter
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            onClick={() =>
                              handleViewLetterClick(
                                item.recommendarray && item.recommendarray.length > 0 && item.recommendarray[0].format
                              )
                            }
                          >
                            <VisibilityIcon
                              fontSize="small"
                              className="primarycolor"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDownloadLetterClick(
                                item.recommendarray && item.recommendarray.length > 0 && item.recommendarray[0].format, "Recommendation"
                              )
                            }
                          >
                            <DownloadIcon fontSize="small" color="success" />
                          </IconButton>
                        </Grid>
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography pl={2}>No letters available</Typography>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Document Viewer</DialogTitle>
        <DialogContent>
          {modalContent && (
            <iframe
              src={modalContent}
              width="100%"
              height="600px"
              title="Document Viewer"
              frameBorder="0"
            ></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for letter start */}
      <Dialog
        open={openLetter}
        onClose={handleLetterDialogClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Document Viewer</DialogTitle>
        <DialogContent sx={{padding:3}}>
          {letterData && (
            <Box >
            <Box
              sx={{ width: "100%", fontSize: "1rem" }}
              dangerouslySetInnerHTML={{ __html: letterData }}
            />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLetterDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for letter end */}
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EmployeeDocuments;
