export function calculateTotalHours(checkin, checkout) {
  // console.log("checkin", checkintime)
  // console.log("checkout", checkout)
  // Parse the check-in and check-out times into Date objects
  const checkinDate = new Date(`1970-01-01T${convertTo24HourFormat(checkin)}`);
  let checkoutDate = new Date(
    `1970-01-01T${convertTo24HourFormat(checkout)}`
  );

  if (checkoutDate < checkinDate) {
    checkoutDate.setDate(checkoutDate.getDate() + 1); // Add 1 day to checkout time
  }

  // Calculate the difference in milliseconds
  const timeDifference = checkoutDate - checkinDate;

  // Convert milliseconds to hours
  const totalHours = timeDifference / (1000 * 60 * 60);

  // Round to two decimal places and remove trailing .00 if present
  const totalHoursRounded = totalHours.toFixed(2).replace(/\.00$/, "");

  return totalHoursRounded;
}

function convertTo24HourFormat(time) {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":");

  // if (hours === "12") {
  //   hours = "00";
  // }

  // if (modifier === "PM") {
  //   hours = parseInt(hours, 10) + 12;
  // }

  // return `${hours}:${minutes}`;

  // Fix for 12 AM and 12 PM
  if (hours === "12") {
    hours = modifier === "AM" ? "00" : "12";
  } else if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;

}

export const leaveType = [
  {
    name: "Casual Leave",
    count: 14,
  },
  {
    name: "Comm Off.",
    count: 7,
  },
  {
    name: "LOP",
    count: 4,
  },
  {
    name: "Sick Leave",
    count: 5,
  },
  {
    name: "Maternity Leave",
    count: 16,
  },
  {
    name: "Paternity Leave",
    count: 10,
  },
  {
    name: "Earned Leave",
    count: 14,
  },
];
