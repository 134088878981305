import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import MobileLayout from '../Components/MobileComponents/MobileLayout/MobileLayout';
import { AuthContext } from '../Context/AuthContextProvider';

function MobileProtectRoute() {
  const { authContext } = useContext(AuthContext);
  const isLoggedIn = authContext.token;

  if (!isLoggedIn) {
    return <Navigate to="/mobile/signin" />;
  }

  return (
    <MobileLayout />
  )
}

export default MobileProtectRoute;