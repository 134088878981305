import React from 'react'
import { MdTimer } from "react-icons/md";

function Home() {
  return (
    <>
      <h1>Home comming soon <MdTimer /></h1>
      
    </>
  )
}

export default Home