import axios from "axios";
import {
  addJobApplicationApi,
  getJobApplicationsApi,
  jobApplicationMultiStatusChangeApi,
} from "../../Constants/api";

export const jobApplicationsTypes = {
  GET_JOBAPPLICATION_LOADING: "GET_JOBAPPLICATION_LOADING",
  GET_JOBAPPLICATION_SUCCESS: "GET_JOBAPPLICATION_SUCCESS",
  GET_JOBAPPLICATION_ERROR: "GET_JOBAPPLICATION_ERROR",

  ADD_JOBAPPLICATION_LOADING: "ADD_JOBAPPLICATION_LOADING",
  ADD_JOBAPPLICATION_SUCCESS: "ADD_JOBAPPLICATION_SUCCESS",
  ADD_JOBAPPLICATION_ERROR: "ADD_JOBAPPLICATION_ERROR",

  EDIT_JOBAPPLICATION_LOADING: "EDIT_JOBAPPLICATION_LOADING",
  EDIT_JOBAPPLICATION_SUCCESS: "EDIT_JOBAPPLICATION_SUCCESS",
  EDIT_JOBAPPLICATION_ERROR: "EDIT_JOBAPPLICATION_ERROR",

  DELETE_JOBAPPLICATION_LOADING: "DELETE_JOBAPPLICATION_LOADING",
  DELETE_JOBAPPLICATION_SUCCESS: "DELETE_JOBAPPLICATION_SUCCESS",
  DELETE_JOBAPPLICATION_ERROR: "DELETE_JOBAPPLICATION_ERROR",

  STATUSCHANGE_JOBAPPLICATION_LOADING: "STATUSCHANGE_JOBAPPLICATION_LOADING",
  STATUSCHANGE_JOBAPPLICATION_SUCCESS: "STATUSCHANGE_JOBAPPLICATION_SUCCESS",
  STATUSCHANGE_JOBAPPLICATION_ERROR: "STATUSCHANGE_JOBAPPLICATION_ERROR",

  GET_RESUMESCORE_LOADING: "GET_RESUMESCORE_LOADING",
  GET_RESUMESCORE_SUCCESS: "GET_RESUMESCORE_SUCCESS",
  GET_RESUMESCORE_ERROR: "GET_RESUMESCORE_ERROR",
};

//action for add
export const addJobApplication =
  (obj, setOpen, setMsg, setAlertType, setData, navigate) =>
  async (dispatch) => {
    try {
      dispatch({ type: jobApplicationsTypes.ADD_JOBAPPLICATION_LOADING });
      let res = await axios.post(addJobApplicationApi, obj);
      if (res.data.message == "Job application added successfully") {
        dispatch({
          type: jobApplicationsTypes.ADD_JOBAPPLICATION_SUCCESS,
          payload: { data: res.data.data, message: res.data.message },
        });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        setData({});
      } else {
        console.log(res, "error response");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: jobApplicationsTypes.ADD_JOBAPPLICATION_ERROR,
        payload: error.message,
      });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

//action for get
export const getJobApplications =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: jobApplicationsTypes.GET_JOBAPPLICATION_LOADING });
      let res = await axios.post(getJobApplicationsApi, obj,{withCredentials:true});
      // console.log(res,"response")
      if (res.data.data) {
        dispatch({
          type: jobApplicationsTypes.GET_JOBAPPLICATION_SUCCESS,
          payload: res.data.data,
        });
      } else {
        console.log(res, "eroor res");
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.message || "Somnething Went Wrong..");
      dispatch({
        type: jobApplicationsTypes.GET_JOBAPPLICATION_ERROR,
        payload: error.message,
      });
    }
  };

export const multiStatusChangeJobApplication =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({
        type: jobApplicationsTypes.STATUSCHANGE_JOBAPPLICATION_LOADING,
      });
      let res = await axios.post(jobApplicationMultiStatusChangeApi, obj,{withCredentials:true});
      // console.log(res, "res");
      if (res.data.message == "Status updated successfully") {
        dispatch({
          type: jobApplicationsTypes.STATUSCHANGE_JOBAPPLICATION_SUCCESS,
          payload: obj,
        });
        setAlertType("success");
        setOpen(true);
        setMsg("Status Updated..");
      } else {
        console.log(res, "error check");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: jobApplicationsTypes.STATUSCHANGE_JOBAPPLICATION_ERROR,
        payload: error.message,
      });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

// export const updateResumeScore = (array,setOpen, setMsg, setAlertType) => async (dispatch) => {
//   try {
//     // dispatch({
//     //   type: jobApplicationsTypes.GET_RESUMESCORE_LOADING,
//     // });
//     let res=await axios.post("https://hrms.vliv.app/jobapplication/updatescore",{array});
//     console.log(res,"res")
//   } catch (error) {
//      console.log(error,"error");
//      setAlertType("error");
//      setOpen(true);
//      setMsg(error.message || "Somnething Went Wrong..");
//     //  dispatch({
//     //    type: jobApplicationsTypes.GET_JOBAPPLICATION_ERROR,
//     //    payload: error.message,
//     //  });
//   }
// };
