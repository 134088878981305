import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import SavingsIcon from "@mui/icons-material/Savings";
import SpeedIcon from "@mui/icons-material/Speed";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import PaidIcon from "@mui/icons-material/Paid";
import PolicyIcon from "@mui/icons-material/Policy";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineEditCalendar } from "react-icons/md";
import { RiTaskLine } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import HomeIcon from "@mui/icons-material/Home";
import { TbLockAccess } from "react-icons/tb";
import Cookies from "js-cookie";
import { MdHomeFilled } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { TbLogs } from "react-icons/tb";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaDatabase } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";

let userInfo = null;
let userrole = null;
let planname = null;

const userInfoCookie = Cookies.get("userinfo");

if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
    userrole = userInfo?.userrole;
    planname = userInfo?.planname;

    // console.log(userrole, "userrole");
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}

export const mobileroutes = [
  // {
  //   path: "/mobile/home",
  //   name: "Home",
  //   icon: <MdHomeFilled />,
  // },
  {
    path: "/mobile/attendance",
    name: "Attendance",
    icon: <FaMapLocationDot />,
  },
  {
    path: "/mobile/logs",
    name: "Logs",
    icon: <TbLogs />,
  },
  {
    path: "/mobile/leave",
    name: "Leave",
    icon: <RiLogoutCircleRLine />,
  },
  {
    path: "/mobile/reimbursement",
    name: "Reimbursement",
    icon: <FaDatabase />,
  },
  // {
  //   path: "/mobile/profile",
  //   name: "Profile",
  //   icon: <FaUser />,
  // },
];

export const mobileadminroutes = [
  {
    path: "/mobile/admin/home",
    name: "Home",
    icon: <MdHomeFilled />,
  },
  {
    path: "/mobile/admin/logs",
    name: "Logs",
    icon: <TbLogs />,
  },
  {
    path: "/mobile/admin/leave",
    name: "Leave",
    icon: <RiLogoutCircleRLine />,
  },
  {
    path: "/mobile/admin/reimbursement",
    name: "Reimbursement",
    icon: <FaDatabase />,
  },
  // {
  //   path: "/mobile/profile",
  //   name: "Profile",
  //   icon: <FaUser />,
  // },
];

export const routes1 = [
  {
    path: "/hrm",
    name: "Hrm",
    iconComponent: <GroupsIcon />,
    subRoutes: [
      {
        path: "/hrm/summary",
        name: "Home",
        iconComponent: <HomeIcon />,
        subRoutes: [
          {
            path: "/hrm/summary/welcome",
            name: "Welcome",
          },
        ],
      },
      {
        path: "/hrm/recruitmanagement",
        name: "Recruitments",
        iconComponent: <PersonAddAlt1Icon />,
        subRoutes: [
          {
            path: "/hrm/recruitmanagement/recruit",
            name: "Recruitment",
          },
          {
            path: "/hrm/recruitmanagement/recruitprocess",
            name: "Recruitment Process",
          },
          {
            path: "/hrm/recruitmanagement/interviewprocess",
            name: "Interview Process",
          },
          {
            path: "/hrm/recruitmanagement/onboarding",
            name: "Onboarding Process",
          },
        ],
      },
      {
        path: "/hrm/employeemanagement",
        name: "Employee Management",
        iconComponent: <Diversity1Icon />,
        subRoutes: [
          {
            path: "/hrm/employeemanagement/registration",
            name: "Registration",
          },
          {
            path: "/hrm/employeemanagement/orgchart",
            name: "Organization Chart",
          },
          {
            path: "/hrm/employeemanagement/timeandattendance",
            name: "Time & Attendance",
          },
          {
            path: "/hrm/employeemanagement/reimbursement",
            name: "Reimbursement",
          },
          {
            path: "/hrm/employeemanagement/leavemanagement",
            name: "Leave Management",
          },
          {
            path: "/hrm/employeemanagement/documentgenerate",
            name: "Document Generation",
          },
          {
            path: "/hrm/employeemanagement/employeeexit",
            name: "Employee Exit",
          },
        ],
      },
      {
        path: "/hrm/employeeselfservice",
        name: "Employee Self-Service",
        iconComponent: <FollowTheSignsIcon />,
        subRoutes: [
          {
            path: "/hrm/employeeselfservice/summary",
            name: "Summary",
          },
          {
            path: "/hrm/employeeselfservice/timendattendance",
            name: "Time & Attendance",
          },
          {
            path: "/hrm/employeeselfservice/reimbursement",
            name: "Reimbursement",
          },
          {
            path: "/hrm/employeeselfservice/leave",
            name: "Leave Management",
          },
          {
            path: "/hrm/employeeselfservice/empdocs",
            name: "Employee Documents",
          },
          {
            path: "/hrm/employeeselfservice/employee-exit",
            name: "Employee Exit",
          },
          {
            path: "/hrm/employeeselfservice/apk",
            name: "Download Attendance App",
          },
        ],
      },
      {
        path: "/hrm/task&events",
        name: "Task & Events",
        iconComponent: <RiTaskLine />,
        subRoutes: [
          {
            path: "/hrm/task&events/tasks",
            name: "Task",
          },
          {
            path: "/hrm/task&events/events",
            name: "Events",
          },
        ],
      },
      {
        path: "/hrm/payroll",
        name: "Payroll",
        iconComponent: <SavingsIcon />,
        subRoutes: [
          {
            path: "/hrm/payroll/payrollinputs",
            name: "Payroll Inputs",
          },
          {
            path: "/hrm/payroll/salary",
            name: "Salary",
          },
          {
            path: "/hrm/payroll/reports",
            name: "Reports",
          },
        ],
      },

      {
        path: "/hrm/performance",
        name: "Performance",
        iconComponent: <SpeedIcon />,
        subRoutes: [
          {
            path: "/hrm/performance/summary",
            name: "Summary",
          },
          {
            path: "/hrm/performance/orggoalkpi",
            name: "Organization Goal KPI",
          },
          {
            path: "/hrm/performance/empdevkpi",
            name: "Employee Developement KPI",
          },
          {
            path: "/hrm/performance/teambuildkpi",
            name: "Team Building KPI",
          },
        ],
      },
      {
        path: "/hrm/compliance",
        name: "Compliance",
        iconComponent: <AssignmentTurnedInIcon />,
        subRoutes: [
          {
            path: "/hrm/compliance",
            name: "Compliance",
          },
        ],
      },
      {
        path: "/hrm/report",
        name: "Report",
        iconComponent: <MdOutlineDashboard />,
        subRoutes: [
          {
            path: "/hrm/report/analytics",
            name: "Analytics",
          },
          {
            path: "/hrm/report/stats",
            name: "Stats",
          },
          {
            path: "/hrm/report/auditlogs",
            name: "Audit Logs",
          },
        ],
      },
    ],
  },
  (userrole == "HR" || userrole == "masteradmin") ? {
    path: "/settings",
    name: "Settings",
    iconComponent: <SettingsIcon />,
    subRoutes: [
      {
        path: "/settings/general",
        name: "General",
        iconComponent: <SettingsIcon />,
        subRoutes: [
          {
            path: "/settings/general/invoicepaysliplogo",
            name: "Invoice/Payslip Logo",
          },
          {
            path: "/settings/general/values",
            name: "Values",
          },
        ],
      },
      {
        path: "/settings/employees",
        name: "Employees",
        iconComponent: <GroupsIcon />,
        subRoutes: [
          {
            path: "/settings/employees/employeeseries",
            name: "Employee Series",
          },
          {
            path: "/settings/employees/employeemasters",
            name: "Employee Masters",
          },
          {
            path: "/settings/employees/leavingreason",
            name: "Leaving Reason",
          },
          {
            path: "/settings/employees/assetgroupsetting",
            name: "Asset Group Setting",
          },
          {
            path: "/settings/employees/configureemployeedirectory",
            name: "Configure Employee Directory",
          },
        ],
      },
      {
        path: "/settings/payroll",
        name: "Payroll",
        iconComponent: <PaidIcon />,
        subRoutes: [
          {
            path: "/settings/payroll/structures",
            name: "Structure",
          },
          {
            path: "/settings/payroll/reimbursementreviewer",
            name: "Reimbursement Reviewer",
          },
        ],
      },
      {
        path: "/settings/leave",
        name: "Leave",
        iconComponent: <ExitToAppIcon />,
        subRoutes: [
          {
            path: "/settings/leave/leavetype",
            name: "Leave Type",
          },
          {
            path: "/settings/leave/regularize",
            name: "Regularize",
          },
        ],
      },

      {
        path: "/settings/attendance",
        name: "Attendance",
        iconComponent: <EventAvailableIcon />,
        subRoutes: [
          {
            path: "/settings/attendance/shift",
            name: "Shift",
          },
        ],
      },
      {
        path: "/settings/performance",
        name: "Performance",
        iconComponent: <SpeedIcon />,
        subRoutes: [
          {
            path: "/settings/performance/organizationKpi",
            name: "Organization Goal KPI",
          },
          {
            path: "/settings/performance/employeeDevelopementKpi",
            name: "Employee Developement KPI",
          },
          {
            path: "/settings/performance/teambuildingKpi",
            name: "Team Building KPI",
          },
        ],
      },
      userrole == "masteradmin" && {
        path: "/settings/acesscontrol",
        name: "Access Control",
        iconComponent: <TbLockAccess />,
        subRoutes: [
          {
            path: "/settings/acesscontrol",
            name: "Access Control",
          },
        ],
      },
    ],
  } :
    (userrole == "Manager") ?
      {
        path: "/settings",
        name: "Settings",
        iconComponent: <SettingsIcon />,
        subRoutes: [
          {
            path: "/settings/performance",
            name: "Performance",
            iconComponent: <SpeedIcon />,
            subRoutes: [
              {
                path: "/settings/performance/organizationKpi",
                name: "Organization Goal KPI",
              },
              {
                path: "/settings/performance/employeeDevelopementKpi",
                name: "Employee Developement KPI",
              },
              {
                path: "/settings/performance/teambuildingKpi",
                name: "Team Building KPI",
              },
            ],
          },
        ],
      } :
      {

      }
];

export const otherroutes = [];

export const userroute = [
  {
    path: "/hrm",
    name: "Hrm",
    iconComponent: <GroupsIcon />,
    subRoutes: [
      {
        path: "/hrm/employeeselfservice",
        name: "Employee Self-Service",
        iconComponent: <FollowTheSignsIcon />,
        subRoutes: [
          {
            path: "/hrm/employeeselfservice/summary",
            name: "Summary",
          },
          {
            path: "/hrm/employeeselfservice/timendattendance",
            name: "Time & Attendance",
          },
          {
            path: "/hrm/employeeselfservice/reimbursement",
            name: "Reimbursement",
          },
          {
            path: "/hrm/employeeselfservice/leave",
            name: "Leave Management",
          },
          {
            path: "/hrm/employeeselfservice/empdocs",
            name: "Employee Documents",
          },
          {
            path: "/hrm/employeeselfservice/employee-exit",
            name: "Employee Exit",
          },
          {
            path: "/hrm/employeeselfservice/apk",
            name: "Download Attendance App",
          },
        ],
      },
      {
        path: "/hrm/task&events",
        name: "Task & Events",
        iconComponent: <RiTaskLine />,
        subRoutes: [
          {
            path: "/hrm/task&events/tasks",
            name: "Task",
          },
          {
            path: "/hrm/task&events/events",
            name: "Events",
          },
        ],
      },
      {
        path: "/hrm/compliance",
        name: "Compliance",
        iconComponent: <AssignmentTurnedInIcon />,
        subRoutes: [
          {
            path: "/hrm/compliance",
            name: "Compliance",
          },
        ],
      },
    ],
  },
];




//for masteradmin && hr
export const checkRoutesM = [
  {
    path: "/hrm/summary",
    name: "Home",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/summary/welcome",
        name: "Welcome",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/recruitmanagement",
    name: "Recruitments",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/recruitmanagement/recruit",
        name: "Recruitment",
        checked: true, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/recruitprocess",
        name: "Recruitment Process",
        checked: true, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/interviewprocess",
        name: "Interview Process",
        checked: true, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/onboarding",
        name: "Onboarding Process",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/employeemanagement",
    name: "Employee Management",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/employeemanagement/registration",
        name: "Registration",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/orgchart",
        name: "Organization Chart",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/timeandattendance",
        name: "Time & Attendance",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/reimbursement",
        name: "Reimbursement",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/leavemanagement",
        name: "Leave Management",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/documentgenerate",
        name: "Document Generation",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/employeeexit",
        name: "Employee Exit",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/employeeselfservice",
    name: "Employee Self-Service",

    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/employeeselfservice/summary",
        name: "Summary",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/timendattendance",
        name: "Time & Attendance",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/reimbursement",
        name: "Reimbursement",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/leave",
        name: "Leave Management",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/empdocs",
        name: "Employee Documents",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/employee-exit",
        name: "Employee Exit",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/apk",
        name: "Download Attendance App",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/task&events",
    name: "Task & Events",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/task&events/tasks",
        name: "Task",
        checked: true, // Default to true
      },
      {
        path: "/hrm/task&events/events",
        name: "Events",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/payroll",
    name: "Payroll",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/payroll/payrollinputs",
        name: "Payroll Inputs",
        checked: true, // Default to true
      },
      {
        path: "/hrm/payroll/salary",
        name: "Salary",
        checked: true, // Default to true
      },
      {
        path: "/hrm/payroll/reports",
        name: "Reports",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/performance",
    name: "Performance",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/performance/summary",
        name: "Summary",
        checked: true, // Default to true
      },
      {
        path: "/hrm/performance/orggoalkpi",
        name: "Organization Goal KPI",
        checked: true, // Default to true
      },
      {
        path: "/hrm/performance/empdevkpi",
        name: "Employee Developement KPI",
        checked: true, // Default to true
      },
      {
        path: "/hrm/performance/teambuildkpi",
        name: "Team Building KPI",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/compliance",
    name: "Compliance",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/compliance",
        name: "Compliance",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/report",
    name: "Report",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/report/analytics",
        name: "Analytics",
        checked: true, // Default to true
      },
      {
        path: "/hrm/report/stats",
        name: "Stats",
        checked: true, // Default to true
      },
      {
        path: "/hrm/report/auditlogs",
        name: "Audit Logs",
        checked: true, // Default to true
      },
    ],
  },
];

//for managers
export const checkRoutesMN = [
  {
    path: "/hrm/summary",
    name: "Home",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/summary/welcome",
        name: "Welcome",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/recruitmanagement",
    name: "Recruitments",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/recruitmanagement/recruit",
        name: "Recruitment",
        checked: true, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/recruitprocess",
        name: "Recruitment Process",
        checked: true, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/interviewprocess",
        name: "Interview Process",
        checked: true, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/onboarding",
        name: "Onboarding Process",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/employeemanagement",
    name: "Employee Management",

    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/employeemanagement/registration",
        name: "Registration",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/orgchart",
        name: "Organization Chart",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/timeandattendance",
        name: "Time & Attendance",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/reimbursement",
        name: "Reimbursement",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/leavemanagement",
        name: "Leave Management",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/documentgenerate",
        name: "Document Generation",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeemanagement/employeeexit",
        name: "Employee Exit",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/employeeselfservice",
    name: "Employee Self-Service",
    checked: false, // Default to false
    subRoutes: [
      {
        path: "/hrm/employeeselfservice/summary",
        name: "Summary",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/timendattendance",
        name: "Time & Attendance",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/reimbursement",
        name: "Reimbursement",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/leave",
        name: "Leave Management",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/empdocs",
        name: "Employee Documents",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/employee-exit",
        name: "Employee Exit",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/apk",
        name: "Download Attendance App",
        checked: false, // Default to false
      },
    ],
  },
  {
    path: "/hrm/task&events",
    name: "Task & Events",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/task&events/tasks",
        name: "Task",
        checked: true, // Default to true
      },
      {
        path: "/hrm/task&events/events",
        name: "Events",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/payroll",
    name: "Payroll",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/payroll/payrollinputs",
        name: "Payroll Inputs",
        checked: false, // Default to true
      },
      {
        path: "/hrm/payroll/salary",
        name: "Salary",
        checked: false, // Default to true
      },
      {
        path: "/hrm/payroll/reports",
        name: "Reports",
        checked: false, // Default to true
      },
    ],
  },
  {
    path: "/hrm/performance",
    name: "Performance",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/performance/summary",
        name: "Summary",
        checked: true, // Default to true
      },
      {
        path: "/hrm/performance/orggoalkpi",
        name: "Organization Goal KPI",
        checked: true, // Default to true
      },
      {
        path: "/hrm/performance/empdevkpi",
        name: "Employee Developement KPI",
        checked: true, // Default to true
      },
      {
        path: "/hrm/performance/teambuildkpi",
        name: "Team Building KPI",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/compliance",
    name: "Compliance",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/compliance",
        name: "Compliance",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/report",
    name: "Report",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/report/analytics",
        name: "Analytics",
        checked: false, // Default to true
      },
      {
        path: "/hrm/report/stats",
        name: "Stats",
        checked: false, // Default to true
      },
    ],
  },
];

//for users
export const checkRoutesU = [
  {
    path: "/hrm/summary",
    name: "Home",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/summary/welcome",
        name: "Welcome",
        checked: false, // Default to true
      },
    ],
  },
  {
    path: "/hrm/recruitmanagement",
    name: "Recruitments",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/recruitmanagement/recruit",
        name: "Recruitment",
        checked: false, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/recruitprocess",
        name: "Recruitment Process",
        checked: false, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/interviewprocess",
        name: "Interview Process",
        checked: false, // Default to true
      },
      {
        path: "/hrm/recruitmanagement/onboarding",
        name: "Onboarding Process",
        checked: false, // Default to true
      },
    ],
  },
  {
    path: "/hrm/employeemanagement",
    name: "Employee Management",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/employeemanagement/registration",
        name: "Registration",
        checked: false, // Default to true
      },
      {
        path: "/hrm/employeemanagement/orgchart",
        name: "Organization Chart",
        checked: false, // Default to true
      },
      {
        path: "/hrm/employeemanagement/timeandattendance",
        name: "Time & Attendance",
        checked: false, // Default to true
      },
      {
        path: "/hrm/employeemanagement/reimbursement",
        name: "Reimbursement",
        checked: false, // Default to true
      },
      {
        path: "/hrm/employeemanagement/leavemanagement",
        name: "Leave Management",
        checked: false, // Default to true
      },
      {
        path: "/hrm/employeemanagement/documentgenerate",
        name: "Document Generation",
        checked: false, // Default to true
      },
      {
        path: "/hrm/employeemanagement/employeeexit",
        name: "Employee Exit",
        checked: false, // Default to true
      },
    ],
  },
  {
    path: "/hrm/employeeselfservice",
    name: "Employee Self-Service",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/employeeselfservice/summary",
        name: "Summary",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/timendattendance",
        name: "Time & Attendance",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/reimbursement",
        name: "Reimbursement",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/leave",
        name: "Leave Management",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/empdocs",
        name: "Employee Documents",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/employee-exit",
        name: "Employee Exit",
        checked: true, // Default to true
      },
      {
        path: "/hrm/employeeselfservice/apk",
        name: "Download Attendance App",
        checked: false, // Default to false
      },
    ],
  },
  {
    path: "/hrm/task&events",
    name: "Task & Events",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/task&events/tasks",
        name: "Task",
        checked: true, // Default to true
      },
      {
        path: "/hrm/task&events/events",
        name: "Events",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/payroll",
    name: "Payroll",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/payroll/payrollinputs",
        name: "Payroll Inputs",
        checked: false, // Default to true
      },
      {
        path: "/hrm/payroll/salary",
        name: "Salary",
        checked: false, // Default to true
      },
      {
        path: "/hrm/payroll/reports",
        name: "Reports",
        checked: false, // Default to true
      },
    ],
  },
  {
    path: "/hrm/performance",
    name: "Performance",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/performance/summary",
        name: "Summary",
        checked: false, // Default to true
      },
      {
        path: "/hrm/performance/orggoalkpi",
        name: "Organization Goal KPI",
        checked: false, // Default to true
      },
      {
        path: "/hrm/performance/empdevkpi",
        name: "Employee Developement KPI",
        checked: false, // Default to true
      },
      {
        path: "/hrm/performance/teambuildkpi",
        name: "Team Building KPI",
        checked: false, // Default to true
      },
    ],
  },
  {
    path: "/hrm/compliance",
    name: "Compliance",
    checked: true, // Default to true
    subRoutes: [
      {
        path: "/hrm/compliance",
        name: "Compliance",
        checked: true, // Default to true
      },
    ],
  },
  {
    path: "/hrm/report",
    name: "Report",
    checked: false, // Default to true
    subRoutes: [
      {
        path: "/hrm/report/analytics",
        name: "Analytics",
        checked: false, // Default to true
      },
      {
        path: "/hrm/report/stats",
        name: "Stats",
        checked: false, // Default to true
      },
    ],
  },
];