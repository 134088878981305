import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { LiaEdit } from "react-icons/lia";
import { VscFeedback } from "react-icons/vsc";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  formatDate,
  formatDateTime,
  formatTime,
  getColorForStatus,
} from "../../../../../Constants/dateFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { scheduleInterview } from "../../../../../Constants/api";
import AlertPopUp from "../../../../../Constants/AlertPopUp";

import Cookies from "js-cookie";
import {
  getInterviewScheduledTemplate,
  getUpdatedInterviewTemplate,
} from "../../../../../Constants/MailTemplates";

const AddInterview = ({ drawerOpen, handleCloseDrawer, rowId }) => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let companyname = userData.companyname ? userData.companyname : "";
  let username = userData.username ? userData.username : "";
  let userRole = userData.userrole ? userData.userrole : "";
  // COOKIES PART

  const [allEmp, setAllEmp] = useState([]);

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    id: rowId,
    fullname: "",
    position: "",
    phoneno: "",
    contactno: "",
    email: "",
    currentlocation: "",
    company: "",
    workexperience: "",
    currentctc: "",
    expectedctc: "",
    linkedin: "",
    additionalinfo: "",
    cv: "",
    companyemail: "",
    candidateid: "",
    status: "",
    history: [],
    statushistory: [],
    remarkshistory: [],
    onboardstatushistory: [],
    remarks: "",
    companyid: "",
    recruitmentid: "",
    createdAt: "",
    updatedAt: "",
  });

  const [isScheduled, setIsScheduled] = useState(false);
  const [interviewData, setInterviewData] = useState({
    interviewname: "",
    interviewername: "",
    interviewdate: "",
    interviewtime: "",
    interviewtype: "Online",
    interviewlink: "",
    interviewaddress: "",
    remarks: "",
    interviewstatus: "Scheduled",
    offerletterstatus: "",
    finalctc: "",
    joiningdate: "",
    scheduleddate: "",
    updatedate: "",
  });

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedObj, setSelectedObj] = useState({
    remarks: "",
    interviewdate: "",
    interviewlink: "",
    interviewname: "",
    interviewtime: "",
    interviewtype: "",
    interviewername: "",
    interviewstatus: "",
    interviewaddress: "",
    offerletterstatus: "",
    finalctc: "",
    joiningdate: "",
    scheduleddate: "",
    updatedate: "",
  });

  const handleEditClick = (ind, interview) => {
    setSelectedIndex(reversedHistory.length - ind);
    setSelectedObj((prev) => ({ ...prev, ...interview }));
    setDialogOpen(true);
  };

  const handleDialogEditClick = (ind, interview) => {
    setSelectedIndex(reversedHistory.length - ind);
    setSelectedObj((prev) => ({ ...prev, ...interview }));
    setEditDialogOpen(true);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedIndex(null);
    setSelectedObj({
      remarks: "",
      interviewdate: "",
      interviewlink: "",
      interviewname: "",
      interviewtime: "",
      interviewtype: "",
      interviewername: "",
      interviewstatus: "",
      interviewaddress: "",
      offerletterstatus: "",
      finalctc: "",
      joiningdate: "",
      scheduleddate: "",
      updatedate: "",
    });
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedIndex(null);
    setSelectedObj({
      remarks: "",
      interviewdate: "",
      interviewlink: "",
      interviewname: "",
      interviewtime: "",
      interviewtype: "",
      interviewername: "",
      interviewstatus: "",
      interviewaddress: "",
      offerletterstatus: "",
      finalctc: "",
      joiningdate: "",
      scheduleddate: "",
      updatedate: "",
    });
  };

  const handleEdit = async () => {
    const reqBody = {
      id: rowId,
      index: selectedIndex - 1,
      obj: { ...selectedObj, updatedate: new Date() },
    };
    // console.log(reqBody,"reqbody")
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/jobapplication/updateinterview",
        reqBody,
        { withCredentials: true }
      );
      // console.log(res);

      if (res.data.message == "Interview updated") {
        handleDialogClose();
        fetchData(rowId);
        setAlertType("success");
        setOpen(true);
        setMsg("Feedback Added..");
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }

    // console.log(reqBody, "reqbody to send backend");
  };

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInterviewData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedObj((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchData = async (rowId) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://hrms.vliv.app/jobapplication/getbyid",
        { id: rowId }
      );
      if (res.data?.data.length) {
        setData((prev) => ({
          ...prev,
          ...res.data.data[0],
        }));
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setAlertType("error");
      setOpen(true);
      setMsg(err.message);
      setLoading(false);
    }
  };

  const getAllEmp = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/get",
        {
          companyid,
          companyemail,
        },
        { withCredentials: true }
      );
      res.data && res.data.length ? setAllEmp(res.data) : setAllEmp([]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (rowId) {
      fetchData(rowId);
      getAllEmp();
    }
  }, [rowId]);

  const handleSave = async () => {
    if (
      !interviewData.interviewername ||
      !interviewData.interviewtime ||
      !interviewData.interviewdate ||
      !interviewData.interviewtype ||
      !interviewData.interviewername
    ) {
      setShowEmptyError(true);
    } else {
      try {
        let res = await axios.post(
          scheduleInterview,
          {
            id: rowId,
            interview: { ...interviewData, scheduleddate: new Date() },
          },
          { withCredentials: true }
        );
        if ((res.data.message = "Interview added")) {
          setAlertType("success");
          setOpen(true);
          setMsg("Interview Scheduled");
          const emailContent = getInterviewScheduledTemplate({
            candidateName: data.fullname,
            position: data.position,
            interviewRound: interviewData.interviewname,
            interviewDate: interviewData.interviewdate,
            interviewTime: interviewData.interviewtime,
            meetingLink:
              interviewData.interviewtype == "Online"
                ? interviewData.interviewlink
                : interviewData.interviewaddress,
            interviewerName: interviewData.interviewername,
            interviewerTitle: "Recruiter",
            senderName: username,
            senderTitle: "Recruitment Coordinator",
            companyName: companyname,
            companyNameEmail: companyemail,
          });

          const mailtoLink = `mailto:${
            data.email
          }?subject=Interview Scheduled&body=${encodeURIComponent(
            emailContent
          )}`;
          window.location.href = mailtoLink;
        }
      } catch (error) {
        console.log(error, "error");
        setAlertType("error");
        setOpen(true);
        setMsg(error.message);
      }

      handleCloseDrawer();
      handleCancel();
    }
  };

  const handleEditSave = async () => {
    if (
      !selectedObj.interviewername ||
      !selectedObj.interviewtime ||
      !selectedObj.interviewdate ||
      !selectedObj.interviewtype ||
      !selectedObj.interviewername
    ) {
      setShowEmptyError(true);
    } else {
      const reqBody = {
        id: rowId,
        index: selectedIndex - 1,
        obj: { ...selectedObj, updatedate: new Date() },
      };
      try {
        let res = await axios.post(
          "https://hrms.vliv.app/jobapplication/updateinterview",
          reqBody,
          { withCredentials: true }
        );
        // console.log(res, "res..");

        if (res.data.message == "Interview updated") {
          handleEditDialogClose();
          fetchData(rowId);
          setAlertType("success");
          setOpen(true);
          setMsg("Interview Updated");
          const emailContent = getUpdatedInterviewTemplate({
            candidateName: data.fullname,
            position: data.position,
            interviewRound: selectedObj.interviewname,
            interviewDate: selectedObj.interviewdate,
            interviewTime: selectedObj.interviewtime,
            meetingLink:
              selectedObj.interviewtype == "Online"
                ? selectedObj.interviewlink
                : selectedObj.interviewaddress,
            interviewerName: selectedObj.interviewername,
            interviewerTitle: "Recruiter",
            senderName: username,
            senderTitle: "Recruitment Coordinator",
            companyName: companyname,
            companyNameEmail: companyemail,
          });

          const mailtoLink = `mailto:${
            data.email
          }?subject=Interview Rescheduled&body=${encodeURIComponent(
            emailContent
          )}`;
          window.location.href = mailtoLink;
        }
      } catch (error) {
        console.log(error, "error");
        setAlertType("error");
        setOpen(true);
        setMsg(error.message);
      }
    }
  };

  const handleCancel = () => {
    setInterviewData({
      interviewname: "",
      interviewername: "",
      interviewdate: "",
      interviewtime: "",
      interviewtype: "Online",
      interviewlink: "",
      interviewaddress: "",
      remarks: "",
      interviewstatus: "Scheduled",
      offerletterstatus: "",
      finalctc: "",
      joiningdate: "",
      scheduleddate: "",
      updatedate: "",
    });
    setIsScheduled(false);
  };

  //reversing the data.history
  const reversedHistory = data.history ? [...data.history].reverse() : [];

  // console.log(selectedObj, "selected Object");

  return (
    <>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={() => {
          handleCloseDrawer();
          handleCancel();
        }}
        sx={{
          width: "50vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "50vw",
            boxSizing: "border-box",
            "@media (max-width: 600px)": {
              width: "90vw",
            },
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            {data.candidateid}
          </Typography>

          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            RID-{data.recruitmentid}
          </Typography>

          <IconButton
            onClick={() => {
              handleCloseDrawer();
              handleCancel();
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />

        <Box
          sx={{ backgroundColor: "#F4FAFF", flex: 1, px: 1, gap: 2, py: 1.5 }}
        >
          <Box px={1}>
            <Grid
              container
              spacing={1}
              gap={1}
              display="flex"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={5} md={3.9}>
                <h3 className="view-page-title">Name</h3>
                <Typography className="view-page-value">
                  {data.fullname}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} md={3.9}>
                <h3 className="view-page-title">Candidate ID</h3>
                <Typography className="view-page-value">
                  {data.candidateid}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} md={3.9}>
                <h3 className="view-page-title">Mail</h3>
                <Typography className="view-page-value">
                  {data.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} md={3.9}>
                <h3 className="view-page-title">Position</h3>
                <Typography className="view-page-value">
                  {data.position}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3.9} sm={3.9}>
                <h3 className="view-page-title">Applied Date</h3>
                <Typography className="view-page-value">
                  {formatDate(data.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3.9} sm={3.9}>
                <h3 className="view-page-title">Resume</h3>
                <IconButton href={data.cv}>
                  <AttachFileIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Divider />
          {data.history?.length > 0 ? (
            <Box px={1}>
              <Typography variant="h6" my={1}>
                Recent Interviews
              </Typography>
              <Box
                maxHeight={320}
                overflow={"auto"}
                className="commonShadow"
                my={1.2}
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "3px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "teal",
                  },
                }}
              >
                {data.onboardstatushistory &&
                  data.onboardstatushistory.length > 0 && (
                    <Card
                      className="commonShadow"
                      my={1}
                      sx={{ marginBottom: "6px" }}
                    >
                      <CardContent>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Button
                            sx={{
                              color: getColorForStatus(
                                data.onboardstatushistory[0].status
                              ),
                            }}
                          >
                            {data.onboardstatushistory[0].status}
                          </Button>
                          <Typography gutterBottom variant="h6" component="div">
                            {formatDateTime(data.onboardstatushistory[0].date)}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                {reversedHistory?.map((interview, ind) => (
                  <Card
                    key={ind}
                    className="commonShadow"
                    sx={{ marginBottom: "6px" }}
                  >
                    <CardContent>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography gutterBottom variant="h6" component="div">
                          {interview.interviewname} ({interview.interviewtype})
                        </Typography>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          gap={3}
                        >
                          <Typography gutterBottom variant="h6" component="div">
                            {formatDate(interview.interviewdate)}
                          </Typography>
                          <Typography gutterBottom variant="h6" component="div">
                            {formatTime(interview.interviewtime)}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1">
                        Interviewer :- {interview.interviewername}
                      </Typography>
                      <Typography variant="body2">
                        Remarks:-{" "}
                        {interview.remarks ? interview.remarks : "N/A"}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            color: getColorForStatus(interview.interviewstatus),
                          }}
                        >
                          {interview.interviewstatus}
                        </Button>
                        {interview.offerletterstatus ? (
                          <Button
                            sx={{
                              color: getColorForStatus(
                                interview.offerletterstatus
                              ),
                            }}
                          >
                            {interview.offerletterstatus}
                          </Button>
                        ) : null}

                        {interview.interviewstatus == "Scheduled" && (
                          <Tooltip title="Edit Interview" placement="top-end">
                            <IconButton>
                              <LiaEdit
                                style={{ color: "teal" }}
                                onClick={() =>
                                  handleDialogEditClick(ind, interview)
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                      <Box>
                        {interview.interviewstatus == "Passed" ||
                        interview.interviewstatus == "Failed" ||
                        interview.interviewstatus == "On Hold" ? null : (
                          <Tooltip title="Add Feedback" placement="top-end">
                            <IconButton>
                              <VscFeedback
                                style={{ color: "teal" }}
                                onClick={() => handleEditClick(ind, interview)}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </CardActions>
                  </Card>
                ))}
              </Box>
            </Box>
          ) : null}

          <Box>
            {reversedHistory.length === 0 ||
            reversedHistory[0]?.interviewstatus === "On Hold" ||
            (reversedHistory[0]?.interviewstatus === "Passed" &&
              !data.onboardstatushistory[0]?.status) ? (
              <Box my={0.5} px={1}>
                <Button
                  variant="outlined"
                  startIcon={<GroupAddIcon />}
                  onClick={() => setIsScheduled(true)}
                >
                  Schedule Interview
                </Button>
              </Box>
            ) : null}
          </Box>

          {isScheduled ? (
            <Box px={1} width="100%">
              <h3 className="fw-500" style={{ margin: "10px 0px" }}>
                Interview Information
              </h3>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Assign</InputLabel>
                    <Select
                      label="Assign"
                      value={interviewData.interviewername}
                      name="interviewername"
                      onChange={handleChange}
                      error={!interviewData.interviewername && showEmptyError}
                    >
                      {allEmp.map((emp) => (
                        <MenuItem value={emp.name} key={emp.id}>
                          {emp.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Date"
                      value={
                        interviewData.interviewdate
                          ? dayjs(interviewData.interviewdate)
                          : null
                      }
                      name="interviewdate"
                      onChange={(date) =>
                        handleChange({
                          target: {
                            name: "interviewdate",
                            value: date ? date.format("YYYY-MM-DD") : "",
                          },
                        })
                      }
                      error={!interviewData.interviewdate && showEmptyError}
                      minDate={dayjs()}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Select Time"
                      value={
                        interviewData.interviewtime
                          ? dayjs(interviewData.interviewtime, "HH:mm")
                          : null
                      }
                      name="interviewtime"
                      onChange={(time) => {
                        const currentTime = dayjs(); // Current time
                        if (time && time.isBefore(currentTime, "minute")) {
                          // Prevent selecting past times
                          setOpen(true); // Show an alert or any error message
                          setAlertType("error");
                          setMsg("Cannot select a past time.");
                          handleChange({
                            target: {
                              name: "interviewtime",
                              value: "",
                            },
                          });
                        } else {
                          handleChange({
                            target: {
                              name: "interviewtime",
                              value: time ? time.format("HH:mm") : "",
                            },
                          });
                        }
                      }}
                      error={!interviewData.interviewtime && showEmptyError}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Interview Name"
                    fullWidth
                    value={interviewData.interviewname}
                    name="interviewname"
                    onChange={handleChange}
                    error={!interviewData.interviewname && showEmptyError}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Interview Type</InputLabel>
                    <Select
                      label="Interview Type"
                      value={interviewData.interviewtype}
                      name="interviewtype"
                      onChange={handleChange}
                      error={!interviewData.interviewtype && showEmptyError}
                    >
                      <MenuItem value={"Online"}>Online</MenuItem>
                      <MenuItem value={"Offline"}>Offline</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label={
                      interviewData.interviewtype === "Online"
                        ? "Interview Link"
                        : "Interview Address"
                    }
                    fullWidth
                    value={
                      interviewData.interviewtype === "Online"
                        ? interviewData.interviewlink
                        : interviewData.interviewaddress
                    }
                    name={
                      interviewData.interviewtype === "Online"
                        ? "interviewlink"
                        : "interviewaddress"
                    }
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Box my={2}>
                <Button className="saveBtn" onClick={handleSave}>
                  Save
                </Button>
                &nbsp;
                <Button
                  className="cancelBtn"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Drawer>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            Add Feedback
          </Typography>

          <IconButton onClick={handleDialogClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent
          px={2}
          sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}
        >
          <FormControl fullWidth margin="dense">
            <InputLabel>Interview Status</InputLabel>
            <Select
              label="Interview Status"
              value={selectedObj.interviewstatus}
              name="interviewstatus"
              onChange={(e) =>
                setSelectedObj({
                  ...selectedObj,
                  offerletterstatus: "",
                  finalctc: "",
                  joiningdate: "",
                  interviewstatus: e.target.value,
                })
              }
            >
              {/* <MenuItem value={"Scheduled"}>Scheduled</MenuItem> */}
              <MenuItem value={"On Hold"}>On Hold</MenuItem>
              <MenuItem value={"Failed"}>Failed</MenuItem>
              <MenuItem value={"Passed"}>Passed</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Remarks"
            fullWidth
            value={selectedObj.remarks}
            name="remarks"
            onChange={(e) =>
              setSelectedObj({ ...selectedObj, remarks: e.target.value })
            }
          />
          {selectedObj.offerletterstatus == "Offer Letter Initiated" && (
            <FormControl fullWidth margin="dense">
              <TextField
                label="Final CTC in LPA"
                fullWidth
                type="number"
                value={selectedObj.finalctc}
                name="finalctc"
                onChange={(e) =>
                  setSelectedObj({ ...selectedObj, finalctc: e.target.value })
                }
              />
            </FormControl>
          )}
          {selectedObj.offerletterstatus == "Offer Letter Initiated" && (
            <FormControl fullWidth margin="dense">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Joining"
                  value={
                    selectedObj.joiningdate
                      ? dayjs(selectedObj.joiningdate)
                      : null
                  }
                  name="joiningdate"
                  onChange={(date) =>
                    setSelectedObj({
                      ...selectedObj,
                      joiningdate: date ? date.format("YYYY-MM-DD") : "",
                    })
                  }
                  // error={!offerLetterData.dateofjoining && showEmptyError}
                  minDate={dayjs()}
                />
              </LocalizationProvider>
            </FormControl>
          )}
          {selectedObj.interviewstatus == "Passed" ? (
            <FormControlLabel
              control={
                <Checkbox
                  color="success"
                  checked={
                    selectedObj.offerletterstatus == "Offer Letter Initiated"
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedObj({
                        ...selectedObj,
                        offerletterstatus: "Offer Letter Initiated",
                      });
                    } else {
                      setSelectedObj({
                        ...selectedObj,
                        offerletterstatus: "",
                        finalctc: "",
                        joiningdate: "",
                      });
                    }
                  }}
                />
              }
              label="Generate Offer Letter"
              labelPlacement="End"
            />
          ) : null}
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
            mt={2}
          >
            <Button
              onClick={handleDialogClose}
              className="cancelBtn"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleEdit} className="saveBtn">
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
          <Typography
            color={"#000A14"}
            fontSize={"1.3rem"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            Edit Interview
          </Typography>

          <IconButton onClick={handleEditDialogClose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <DialogContent
          px={2}
          sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}
        >
          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <InputLabel>Assign</InputLabel>
            <Select
              label="Assign"
              value={selectedObj.interviewername}
              name="interviewername"
              onChange={handleEditChange}
              error={!selectedObj.interviewername && showEmptyError}
            >
              {allEmp.map((emp) => (
                <MenuItem value={emp.name} key={emp.id}>
                  {emp.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={
                  selectedObj.interviewdate
                    ? dayjs(selectedObj.interviewdate)
                    : null
                }
                name="interviewdate"
                onChange={(date) =>
                  handleEditChange({
                    target: {
                      name: "interviewdate",
                      value: date ? date.format("YYYY-MM-DD") : "",
                    },
                  })
                }
                error={!selectedObj.interviewdate && showEmptyError}
                minDate={dayjs()}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Select Time"
                value={
                  selectedObj.interviewtime
                    ? dayjs(selectedObj.interviewtime, "HH:mm")
                    : null
                }
                name="interviewtime"
                onChange={(time) =>
                  handleEditChange({
                    target: {
                      name: "interviewtime",
                      value: time ? time.format("HH:mm") : "",
                    },
                  })
                }
                error={!selectedObj.interviewtime && showEmptyError}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <TextField
              label="Interview Name"
              fullWidth
              value={selectedObj.interviewname}
              name="interviewname"
              onChange={handleEditChange}
              error={!selectedObj.interviewname && showEmptyError}
            />
          </FormControl>
          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <InputLabel>Interview Type</InputLabel>
            <Select
              label="Interview Type"
              value={selectedObj.interviewtype}
              name="interviewtype"
              onChange={handleEditChange}
              error={!selectedObj.interviewtype && showEmptyError}
            >
              <MenuItem value={"Online"}>Online</MenuItem>
              <MenuItem value={"Offline"}>Offline</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ margin: "10px 0" }}>
            <TextField
              label={
                selectedObj.interviewtype === "Online"
                  ? "Interview Link"
                  : "Interview Address"
              }
              fullWidth
              value={
                selectedObj.interviewtype === "Online"
                  ? selectedObj.interviewlink
                  : selectedObj.interviewaddress
              }
              name={
                selectedObj.interviewtype === "Online"
                  ? "interviewlink"
                  : "interviewaddress"
              }
              onChange={handleEditChange}
            />
          </FormControl>

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
            mt={2}
          >
            <Button
              onClick={handleEditDialogClose}
              className="cancelBtn"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleEditSave} className="saveBtn">
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AddInterview;
