import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,

  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  addLeaveType,
  addType,
  deleteType,
} from "../../../../redux/actions/actions.master/leaveType.actions";
import Loader from "../../../../Constants/Loader";
import { isLeaveTypeExists } from "../../../../Constants/constants";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const AddLeaveType = ({ openDrawer, setOpenDrawer, leaveTypeData }) => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { typeMaster, error, loading } = useSelector(
    (state) => state.typeMasterReducer
  );

  const [isAddingType, setIsAddingType] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    leavetype: "",
    code: "",
    description: "",
    count: "",
    isallowed: false,
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //for success alert

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name == "isallowed") {
      setData({ ...data, [name]: e.target.checked });
    }
  };

  const handleSave = () => {
    // console.log("data to be save", data);
    if (isLeaveTypeExists(leaveTypeData, data.leavetype)) {
      setAlertType("error");
      setOpen(true);
      setMsg("This Leave Type is already exists");
    } else {
      dispatch(addLeaveType(data));
      setData({
        leavetype: "",
        code: "",
        description: "",
        count: "",
        isallowed: false,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      });
      // setRefresh(!refresh)
      setOpenDrawer(false);
    }
  };

  const handleAddingLeaveType = async () => {
    setIsAddingType(!isAddingType);
    if (!data.leavetype) {
      return;
    }
    dispatch(
      addType({
        leavetype: data.leavetype,
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  };

  const handleDeleteLeaveType = (id) => {
    dispatch(
      deleteType({
        idarray: [id],
      })
    );
  };

  // console.log(leaveTypeData, "master leaev type inside add drawer");

  return (
    <>
      <Button
        className="saveBtn"
        variant="contained"
        onClick={() => setOpenDrawer(true)}
      >
        Add
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box width={700} display="flex" flexDirection="column" height="100%">
          <Box p={2} flexGrow={1} overflow="auto">
            <Typography
              variant="h6"
              color={"#062C41"}
              display={"flex"}
              fontWeight={600}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
            >
              Add Leave Type
              <span>
                <IconButton
                  onClick={() => {
                    setData({
                      leavetype: "",
                      code: "",
                      description: "",
                      count: "",
                      isallowed: false,
                      companyid: companyInfo.companyid,
                      companyemail: companyInfo.companyemail,
                    });
                    setOpenDrawer(false);
                  }}
                >
                  <CancelIcon sx={{ color: "black" }} />
                </IconButton>
              </span>
            </Typography>
            <Divider />
            <Grid
              container
              justifyContent={"space-between"}
              marginTop="20px"
              marginBottom="20px"
            >
              <Grid item md={5.9}>
                <h4 style={{ marginBottom: "5px" }}>
                  Leave Type <span style={{ color: "red" }}>*</span>
                </h4>

                <TextField
                  fullWidth
                  className="formInputs"
                  value={data.leavetype}
                  onChange={handleChange}
                  name="leavetype"
                  error={!data.leavetype && showEmptyError}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    typeMaster.length > 0 &&
                    typeMaster.map((elem, index) => (
                      <MenuItem key={index} value={elem.leavetype}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          {elem.leavetype}
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>
              <Grid item md={3}>
                <h4 style={{ marginBottom: "5px" }}>Code</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter Code"
                  value={data.code}
                  onChange={handleChange}
                  name="code"
                />
              </Grid>
              <Grid item md={3}>
                <h4 style={{ marginBottom: "5px" }}>No. of Leaves</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="Enter No. of Leaves"
                  value={data.count}
                  onChange={handleChange}
                  name="count"
                  error={!data.count && showEmptyError}
                />
              </Grid>
              <Grid item md={12} mt={1}>
                <h4 style={{ marginBottom: "5px" }}>Description</h4>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  className="formInputs"
                  placeholder="-"
                  value={data.description}
                  onChange={handleChange}
                  name="description"
                />
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                marginTop="20px"
                marginBottom="20px"
              >
                <Grid item md={5.9}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isallowed"
                        checked={data.isallowed}
                        onChange={handleChange}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    }
                    label="Allow employee to apply for the leave"
                  />
                </Grid>
                <Grid item md={5.9}></Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt="auto"
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            bgcolor={"background.paper"}
            p={2}
            display={"flex"}
            flexDirection={"row-reverse"}
          >
            <Button
              className="saveBtn"
              variant="contained"
              onClick={() => {
                if (!data.leavetype || !data.count) {
                  setShowEmptyError(true);
                  return;
                } else {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
            <Button
              className="cancelBtn"
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setData({
                  leavetype: "",
                  code: "",
                  description: "",
                  count: "",
                  isallowed: false,
                  companyid: companyInfo.companyid,
                  companyemail: companyInfo.companyemail,
                });
                setOpenDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AddLeaveType;
