import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import { getSummaryAnnouncement } from "../../../../../../redux/actions/actions.hrms/summaryAnnouncement.actions";
import { Avatar, Box, Chip, Divider, Typography } from "@mui/material";
import Loader from "../../../../../../Constants/Loader";
import { stringAvatar } from "../../../../../../Constants/StringAvatar";
import ReactQuill from "react-quill";
import { formatDate } from "../../../../../../Constants/dateFormat";

const Announcements = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};


  const dispatch = useDispatch();
  const { announcementData, announcement_error, announcement_loading } =
    useSelector((state) => state.summaryAnnouncementReducer);

  useEffect(() => {
    dispatch(
      getSummaryAnnouncement({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  return (
    <Box>
      {announcement_loading ? (
        <Loader />
      ) : announcementData?.length === 0 ? (
        <h3 style={{ textAlign: "center", marginTop: "10px" }}>
          No Post Found
        </h3>
      ) : (
        announcementData.map((ele, i) => (
          <Box
            className="bg-white common-hover-box"
            padding={"10px"}
            marginTop={"5px"}
            borderRadius={"15px"}
            key={i}
            
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"5px"}
            >
              <Box display={"flex"} alignItems={"center"} gap={2}>
                {ele.image ? (
                  <img
                    src={ele.image}
                    alt={ele.empname}
                    loading="lazy"
                    width={"50px"}
                    height={"50px"}
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <Avatar {...stringAvatar(ele.empname)} />
                )}
                <Typography>{ele.empname}</Typography>
              </Box>
              <Box>
                {i === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Chip
                      label="New"
                      sx={{
                        bgcolor: "var(--common-color)",
                        color: "white",
                      }}
                      size="small"
                    />
                  </div>
                )}
                <Typography fontSize={"12px"}>
                  <span>{formatDate(ele.day)}</span> <span>{ele.time}</span>
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Typography variant="h6" gutterBottom>
              {ele.subject}
            </Typography>
            <ReactQuill
              value={ele.description}
              readOnly={true}
              theme="bubble"
            />
          </Box>
        ))
      )}
    </Box>
  );
};

export default Announcements;
