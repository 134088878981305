//action types

import axios from "axios";
import {
  addRecruitmentApi,
  editRecruitmentApi,
  getRecruitmentApi,
  multiDeleteRecruitmentApi,
  upsdateStatusRecruitmentApi,
} from "../../Constants/api";

export const recruitmentActionTypes = {
  GET_RECRUITMENT_LOADING: "GET_RECRUITMENT_LOADING",
  GET_RECRUITMENT_SUCCESS: "GET_RECRUITMENT_SUCCESS",
  GET_RECRUITMENT_ERROR: "GET_RECRUITMENT_ERROR",

  ADD_RECRUITMENT_LOADING: "ADD_RECRUITMENT_LOADING",
  ADD_RECRUITMENT_SUCCESS: "ADD_RECRUITMENT_SUCCESS",
  ADD_RECRUITMENT_ERROR: "ADD_RECRUITMENT_ERROR",

  EDIT_RECRUITMENT_LOADING: "EDIT_RECRUITMENT_LOADING",
  EDIT_RECRUITMENT_SUCCESS: "EDIT_RECRUITMENT_SUCCESS",
  EDIT_RECRUITMENT_ERROR: "EDIT_RECRUITMENT_ERROR",

  DELETE_RECRUITMENT_LOADING: "DELETE_RECRUITMENT_LOADING",
  DELETE_RECRUITMENT_SUCCESS: "DELETE_RECRUITMENT_SUCCESS",
  DELETE_RECRUITMENT_ERROR: "DELETE_RECRUITMENT_ERROR",

  STATUSCHANGE_RECRUITMENT_LOADING: "STATUSCHANGE_RECRUITMENT_LOADING",
  STATUSCHANGE_RECRUITMENT_SUCCESS: "STATUSCHANGE_RECRUITMENT_SUCCESS",
  STATUSCHANGE_RECRUITMENT_ERROR: "STATUSCHANGE_RECRUITMENT_ERROR",
};

// action for get
export const getRecruitment = (obj) => async (dispatch) => {
  try {
    dispatch({ type: recruitmentActionTypes.GET_RECRUITMENT_LOADING });
    let res = await axios.post(getRecruitmentApi, obj, {
      withCredentials: true,
    });
    // console.log(res,"response")
    if (res.data.data) {
      dispatch({
        type: recruitmentActionTypes.GET_RECRUITMENT_SUCCESS,
        payload: res.data.data,
      });
    } else {
      console.log(res, "eroor res");
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: recruitmentActionTypes.GET_RECRUITMENT_ERROR,
      payload: error.message,
    });
  }
};

//action for add
export const addRecruitment =
  (obj, setOpen, setMsg, setAlertType, handleDrawerClose, clearFormData) =>
  async (dispatch) => {
    try {
      dispatch({ type: recruitmentActionTypes.ADD_RECRUITMENT_LOADING });
      let res = await axios.post(addRecruitmentApi, obj, {
        withCredentials: true,
      });
      if (res.data.message == "Added successfully") {
        dispatch({
          type: recruitmentActionTypes.ADD_RECRUITMENT_SUCCESS,
          payload: res.data.data,
        });

        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        setTimeout(() => {
          handleDrawerClose();
          clearFormData();  
        }, 1000);
      } else {
        console.log(res, "error response");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: recruitmentActionTypes.ADD_RECRUITMENT_ERROR,
        payload: error.message,
      });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

//action for edit
export const editRecruitment =
  (obj, setOpen, setMsg, setAlertType, handleCloseDrawer) =>
  async (dispatch) => {
    try {
      dispatch({ type: recruitmentActionTypes.EDIT_RECRUITMENT_LOADING });
      let res = await axios.post(editRecruitmentApi, obj, {
        withCredentials: true,
      });
      if (res.data.message == "Updated successfully") {
        dispatch({
          type: recruitmentActionTypes.EDIT_RECRUITMENT_SUCCESS,
          payload: res.data.data,
        });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        setTimeout(() => {
          handleCloseDrawer();
        }, 1000);
      } else {
        console.log(res, "error response");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: recruitmentActionTypes.EDIT_RECRUITMENT_ERROR,
        payload: error.message,
      });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

//action for multi-delete

export const multiDeleteRecruitment =
  (idarray, setOpen, setMsg, setAlertType, navigate) => async (dispatch) => {
    try {
      dispatch({ type: recruitmentActionTypes.DELETE_RECRUITMENT_LOADING });
      let res = await axios.post(
        multiDeleteRecruitmentApi,
        { idarray },
        { withCredentials: true }
      );
      // console.log(res, "res");
      if (res.data.message == "Deleted successfully") {
        dispatch({
          type: recruitmentActionTypes.DELETE_RECRUITMENT_SUCCESS,
          payload: idarray,
        });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        setTimeout(() => {
          navigate("/hrm/recruitmanagement/recruitprocess");
        }, 1000);
      } else {
        console.log(res, "error check");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: recruitmentActionTypes.DELETE_RECRUITMENT_ERROR,
        payload: error.message,
      });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const multiStatusChangeRecruitment =
  (idarray, setOpen, setMsg, setAlertType, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: recruitmentActionTypes.STATUSCHANGE_RECRUITMENT_LOADING,
      });
      let res = await axios.post(
        upsdateStatusRecruitmentApi,
        { idarray },
        { withCredentials: true }
      );
      // console.log(res, "res");
      if (res.data.message == "Status updated successfully") {
        dispatch({
          type: recruitmentActionTypes.STATUSCHANGE_RECRUITMENT_SUCCESS,
          payload: res.data.data,
        });
        setAlertType("success");
        setOpen(true);
        setMsg("Recruitment Approved Successfully");
        setTimeout(() => {
          navigate("/hrm/recruitmanagement/recruitprocess");
        }, 1000);
      } else {
        console.log(res, "error check");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: recruitmentActionTypes.STATUSCHANGE_RECRUITMENT_ERROR,
        payload: error.message,
      });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };
