import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import Cookies from "js-cookie";
import axios from "axios";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import Loader from "../../../../Constants/Loader";
import {  vlivApi, vlivDomain } from "../../../../Constants/api";

const PayslipLogo = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [position, setPosition] = useState("");
  const [imageURL, setImageURL] = useState("");
  // const [imageName, setImageName] = useState("");

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      // Process the files here
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const res = await axios.post(
        "https://hrms.vliv.app/general/logo",

        formData,{withCredentials:true},
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // console.log("res", res);
      if (res.data.file) {
        const fileurl = res.data.file;
        setImage(res.data.file);
        setAlertType("success");
        setOpen(true);
        setMsg("File uploaded..");
      }
    } else {
      setAlertType("error");
      setOpen(true);
      setMsg("Upload a file");
    }
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleSubmit = async () => {
    // Handle the submit logic here
    try {
      let res = await axios.post(`${process.env.NODE_ENV == "production" ? vlivDomain : vlivApi}/company/updateimage`, {
        companyid,
        position,
        companyimage: image,
      },{withCredentials:true});
      // console.log(res,"response logo");
      if (res.data && res.data.message && res.data.message == "Logo updated") {
        setAlertType("success");
        setOpen(true);
        setMsg("Logo & Position updated");
        getCompanyDetails();
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error While updating image");
    }
  };

  const getImageAlignment = () => {
    switch (position) {
      case "top-left":
        return { justifyContent: "flex-start", alignItems: "flex-start" };
      case "top-right":
        return { justifyContent: "flex-end", alignItems: "flex-start" };
      case "bottom-left":
        return { justifyContent: "flex-start", alignItems: "flex-end" };
      case "bottom-right":
        return { justifyContent: "flex-end", alignItems: "flex-end" };
      case "top-center":
        return { justifyContent: "center", alignItems: "flex-start" };
      case "bottom-center":
        return { justifyContent: "center", alignItems: "flex-end" };
      default:
        return { justifyContent: "center", alignItems: "center" };
    }
  };

  const getCompanyDetails = async () => {
    try {
      setLoading(true);
      let res = await axios.post(`${process.env.NODE_ENV == "production" ? vlivDomain : vlivApi}/company/getcompanybyid`, {
        companyid,
      },{withCredentials:true});
      // console.log(res, "company logo res");
      res.data && res.data.companyimage
        ? setImage(res.data.companyimage)
        : setImage("");
      res.data && res.data.position
        ? setPosition(res.data.position)
        : setPosition("");
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting logo");
      console.log(error, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <>
      <Box p={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            padding: 3,
            border: "1px solid #ccc",
            borderRadius: "8px",
            width: "400px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TextField
            // label="Upload Image"
            value={image}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <input
                    accept="image/jpeg, image/png, image/gif"
                    id="upload-image"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="upload-image">
                    <IconButton aria-label="upload image" component="span">
                      <AttachFile />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel id="position-select-label">Position</InputLabel>
            <Select
              labelId="position-select-label"
              value={position}
              label="Position"
              onChange={handlePositionChange}
            >
              <MenuItem value="top-left">Top Left</MenuItem>
              <MenuItem value="top-right">Top Right</MenuItem>
              <MenuItem value="bottom-left">Bottom Left</MenuItem>
              <MenuItem value="bottom-right">Bottom Right</MenuItem>
              <MenuItem value="top-center">Top Center</MenuItem>
              <MenuItem value="bottom-center">Bottom Center</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ textAlign: "right" }} width={1}>
            <Button className="saveBtn" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Box>

        {loading ? (
          <Loader />
        ) : (
          image && (
            <Box
              sx={{
                marginTop: 3,
                width: "400px",
                height: "300px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: getImageAlignment().justifyContent,
                alignItems: getImageAlignment().alignItems,
                overflow: "hidden",
                padding: 2,
              }}
            >
              <img
                src={image}
                alt="company_logo"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            </Box>
          )
        )}
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default PayslipLogo;
