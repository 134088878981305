export const getChipColor = (tagName) => {
  // Simple hash function to generate a number from a string
  const hashCode = (str) => {
    return str.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
  };

  // Convert the hash code to a hex color
  const intToRGB = (hash) => {
    const r = (hash >> 16) & 0xff;
    const g = (hash >> 8) & 0xff;
    const b = hash & 0xff;
    return `rgb(${r},${g},${b})`;
  };

  // Generate the color
  const hash = hashCode(tagName);
  const color = intToRGB(hash);

  return color;
};

export const getprioritycolor = (value) => {
  switch (value) {
  }
};

export const getPayrollStatusColor = (value) => {
  switch (value) {
    case "hold":
    case "Applied":
    case "Requested":
      return "primary";
    case "Pending":
    case "Reject":
      return "warning";
    case "Process":
      return "info";
    case "Paid":
    case "Shortlist":
    case "Approve":
    case "Approved":
      return "success";
    case "Rejected":
      return "error";
    default:
      return "primary";
  }
};
