import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import Person2Icon from "@mui/icons-material/Person2";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import Person4Icon from "@mui/icons-material/Person4";

import EastIcon from "@mui/icons-material/East";
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import WestIcon from "@mui/icons-material/West";
import MuiPhoneNumber from "mui-phone-number";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MailIcon from "@mui/icons-material/Mail";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

import { useDispatch, useSelector } from "react-redux";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import ImagePlaceHolder from "../JobApplications/ImagePlaceHolder";
import Loader from "../../../../Constants/Loader";

const OnBoardDocumentUpload = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [saveLoading,setSaveLoading]=useState(false);
  const [jaData, setJaData] = useState({
    onboardstatushistory: [],
  });
  const companyid = queryParams.get("key");
  const recruitmentid = queryParams.get("recid");
  const candidateid = queryParams.get("cid");
  const jobapplicationid = queryParams.get("jaid");

  const [companyDetails, setCompanyDetails] = useState({});
  const [allRecruitment, setAllRecruitment] = useState([]);
  const [progressValue, setProgressValue] = useState(10);
  const [tabvalue, setTabValue] = React.useState("P1");
  const [showEmptyError, setShowEmptyError] = useState(false);

  //for alert msg
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //for alert msg end

  const [fileNames, setFileNames] = useState({
    photo: "",
    tenmarksheet: "",
    degreemarksheet: "",
    twelvemarksheet: "",
    mastermarksheet: "",
    bankstatement: "",
    salaryslip: "",
    aadhar: "",
    pan: "",
    expletter: "",
  });

  const [data, setData] = useState({
    name: "",
    dob: "",
    emailid: "",
    primarycontact: "",
    altcontact: "",
    fathername: "",
    mothername: "",
    bloodgroup: "",
    maritalstatus: "",
    currentaddress: "",
    permanentaddress: "",
    ecname: "",
    ecprelation: "",
    ecpcontactno: "",
    ecpaddress: "",
    hobbies: "",
    tenyear: "",
    tenpercent: "",
    tenboard: "",
    tenmarksheet: "",
    twelvestream: "",
    twelveyear: "",
    twelvepercent: "",
    twelvemarksheet: "",
    degreename: "",
    degreeyear: "",
    degreepercent: "",
    degreemarksheet: "",
    mastername: "",
    masteryear: "",
    masterpercent: "",
    mastermarksheet: "",
    aadharno: "",
    aadhar: "",
    panno: "",
    pan: "",
    photo: "",
    lastorg: "",
    jobroll: "",
    from: "",
    to: "",
    previousctc: "",
    fixedpay: "",
    variablepay: "",
    salaryslip: "",
    uan: "",
    esic: "",
    expletter: "",
    bankstatement: "",
    companyemail: "",
    candidateid: candidateid,
    companyid: companyid,
    jobapplicationid: jobapplicationid,
    bank: "",
    accountno: "",
    accountholder: "",
    ifsc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    // for validation start
    const validateCurrentTab = () => {
      const missingFields = [];
      const invalidFields = [];

      // Sensitive validation for all fields
      if (data.accountholder && !/^[a-zA-Z\s]+$/.test(data.accountholder))
        invalidFields.push("Name (only letters allowed)");
      if (data.uan && (data.uan.length > 12 || data.uan.length < 12))
        invalidFields.push("UAN (must be a valid 12 digits)");
      if (data.esic && (data.esic.length > 17 || data.esic.length < 17))
        invalidFields.push("ESIC  (must be a valid 17 digits)");
      if (data.bank && !/^[a-zA-Z\s]+$/.test(data.bank))
        invalidFields.push("Bank Name (only letters allowed)");

      // Missing fields validation based on tab value
      if (tabvalue === "EM2") {
        if (!data.accountno) missingFields.push("Account Number");
        if (!data.accountholder) missingFields.push("Account Holder Name");
        if (!data.ifsc) missingFields.push("IFSC Code");
      }

      if (missingFields.length > 0) {
        setAlertType("error");
        setOpen(true);
        setMsg(`Please fill all required fields: ${missingFields.join(", ")}`);
        return false;
      }

      if (invalidFields.length > 0) {
        setAlertType("error");
        setOpen(true);
        setMsg(`Invalid input in fields: ${invalidFields.join(", ")}`);
        return false;
      }

      return true;
    };
    if (validateCurrentTab()) {
      // console.log(data,"before form submit...");
      try {
        setSaveLoading(true);
        let res = await axios.post("https://hrms.vliv.app/candidate/add", {
          ...data,
          educationarray: [
            {
              education: "10th",
              from: "",
              to: data.tenyear,
              institutename: "",
              certificate: data.tenmarksheet,
              boardname: data.tenboard,
              percentage: data.tenpercent,
            },
            {
              education: "12th",
              from: "",
              to: data.twelveyear,
              institutename: "",
              certificate: data.twelvemarksheet,
              boardname: "",
              percentage: data.twelvepercent,
            },
            {
              education: "Degree",
              from: "",
              to: data.degreeyear,
              institutename: "",
              certificate: data.degreemarksheet,
              boardname: "",
              percentage: data.degreepercent,
            },
            {
              education: "Master Degree",
              from: "",
              to: data.masteryear,
              institutename: "",
              certificate: data.mastermarksheet,
              boardname: "",
              percentage: data.masterpercent,
            },
          ],
        });
        if (res.data.message == "Candidate added successfully") {
          setAlertType("success");
          setOpen(true);
          setMsg("Candidate Information Saved..");
        }
        setSaveLoading(false)
        fetchJAData(jobapplicationid);
      } catch (error) {
        console.log(error, "error");
        setAlertType("error");
        setOpen(true);
        setMsg(error.message);
        setSaveLoading(false)
      }
    }

    // For validation end
  };

  const handleFileUpload = async (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFileNames((prev) => ({ ...prev, [field]: file.name }));
      const url = `https://hrms.vliv.app/candidate/${field}`;
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await axios.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        // console.log(response, "responseshdghjdig");
        if (response.data.file) {
          // setFileNames((prev) => ({ ...prev, [field]: response.data.file }));
          setData((prev) => ({ ...prev, [field]: response.data.file }));
          setAlertType("success");
          setOpen(true);
          setMsg(`${field} uploaded`);
        } else {
          setFileNames((prev) => ({ ...prev, [field]: "" }));
          setAlertType("error");
          setOpen(true);
          setMsg("Error in uploading file..");
        }
      } catch (error) {
        setAlertType("error");
        setOpen(true);
        setMsg("Error in uploading file..");
        console.error("Error uploading file:", error.message);
      }
    } else {
      setFileNames((prev) => ({ ...prev, [field]: "" }));
      setAlertType("error");
      setOpen(true);
      setMsg("Choose File to upload");
    }
  };

  useEffect(() => {
    axios
      .post("https://hrms.vliv.app/recruitment/getdata", {
        companyid,
        recruitmentid,
      })
      .then((res) => {
        res.data.data
          ? setAllRecruitment(res.data.data)
          : setAllRecruitment([]);
        res.data.company && res.data.company.length > 0
          ? setCompanyDetails(res.data.company[0])
          : setCompanyDetails({});
      })
      .catch((err) => {
        console.log(err, "err");
        setOpen(true);
        setAlertType("error");
        setMsg(err.message);
      });
  }, [companyid]);

  // Use media query hook to check screen size
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");
  const isMediumScreen = useMediaQuery("(min-width: 768px)");

  // Determine width of TabPanel based on screen size
  const tabPanelWidth = isLargeScreen ? "60%" : isMediumScreen ? "80%" : "100%";


  const fetchJAData = async (jobapplicationid) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://hrms.vliv.app/jobapplication/getbyid",
        { id: jobapplicationid }
      );
      if (res.data?.data.length) {
        setJaData((prev) => ({
          ...prev,
          ...res.data.data[0],
        }));
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setAlertType("error");
      setOpen(true);
      setMsg(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (jobapplicationid) {
      fetchJAData(jobapplicationid);
    }
  }, [jobapplicationid]);

  const handleNext = (value) => {
    const validateCurrentTab = () => {
      const missingFields = [];
      const invalidFields = [];

      // Helper functions for validation
      const isValidDate = (date) => {
        const today = new Date();
        const inputDate = new Date(date);
        return inputDate < today;
      };

      const isValidPercentage = (percentage) => {
        return percentage >= 0 && percentage <= 100;
      };

      const isValidPan = (pan) => {
        const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return panPattern.test(pan);
      };

      const isValidPhoneNumber = (number) => {
        return /^\d{10}$/.test(number);
      };

      const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
      };

      // Sensitive validation for all fields
      if (data.name && !/^[a-zA-Z\s]+$/.test(data.name))
        invalidFields.push("Name (only letters allowed)");
      if (data.dob && !isValidDate(data.dob))
        invalidFields.push("Date of Birth (must be a past date)");
      if (data.emailid && !isValidEmail(data.emailid))
        invalidFields.push("Email (must be a valid email address)");
      if (
        data.primarycontact &&
        (data.primarycontact.length > 13 || data.primarycontact.length < 13)
      )
        invalidFields.push("Primary Contact Number (must be 10 digits)");
      if (data.altcontact &&
        (data.altcontact.length > 13 || data.altcontact.length < 13))
        invalidFields.push("Alternate Contact Number (must be 10 digits)");
      if (data.fathername && !/^[a-zA-Z\s]+$/.test(data.fathername))
        invalidFields.push("Father Name (only letters allowed)");
      if (data.mothername && !/^[a-zA-Z\s]+$/.test(data.mothername))
        invalidFields.push("Mother Name (only letters allowed)");
      if (data.bloodgroup && !/^(A|B|AB|O)[+-]$/.test(data.bloodgroup))
        invalidFields.push("Blood Group (must be a valid blood group)");
      if (data.ecpcontactno && !isValidPhoneNumber(data.ecpcontactno))
        invalidFields.push(
          "Emergency Contact Person Phone Number (must be 10 digits)"
        );
      if (data.tenpercent && !isValidPercentage(data.tenpercent))
        invalidFields.push("10th Percentage (must be between 0 and 100)");
      if (data.twelvepercent && !isValidPercentage(data.twelvepercent))
        invalidFields.push("12th Percentage (must be between 0 and 100)");
      if (data.degreepercent && !isValidPercentage(data.degreepercent))
        invalidFields.push("Degree Percentage (must be between 0 and 100)");
      if (data.masterpercent && !isValidPercentage(data.masterpercent))
        invalidFields.push(
          "Master Degree Percentage (must be between 0 and 100)"
        );
      if (data.panno && !isValidPan(data.panno))
        invalidFields.push("PAN Number (must be a valid PAN format)");
      if (
        data.aadharno &&
        (data.aadharno.length > 12 || data.aadharno.length < 12)
      )
        invalidFields.push("Aadhar Number (must be a valid 12 digits)");

      // Missing fields validation based on tab value
      if (tabvalue === "P1") {
        if (!data.name) missingFields.push("Name");
        if (!data.dob) missingFields.push("Date of Birth");
        if (!data.emailid) missingFields.push("Email");
        if (!data.primarycontact) missingFields.push("Primary Contact Number");
      } else if (tabvalue === "P2") {
        if (!data.currentaddress) missingFields.push("Current Address");
        if (!data.permanentaddress) missingFields.push("Permanent Address");
      } else if (tabvalue === "E1") {
        if (!data.tenyear) missingFields.push("10th Year");
        if (!data.tenpercent) missingFields.push("10th Percentage");
        if (!data.tenboard) missingFields.push("10th Board");
        if (!data.tenmarksheet) missingFields.push("10th Marksheet");
        if (!data.twelvestream) missingFields.push("12th Stream");
        if (!data.twelveyear) missingFields.push("12th Year");
        if (!data.twelvepercent) missingFields.push("12th Percentage");
        if (!data.twelvemarksheet) missingFields.push("12th Marksheet");
      } else if (tabvalue === "DD") {
        if (!data.aadharno) missingFields.push("Aadhar Number");
        if (!data.aadhar) missingFields.push("Aadhar");
        if (!data.panno) missingFields.push("PAN Number");
        if (!data.pan) missingFields.push("PAN");
        if (!data.photo) missingFields.push("Photo");
      } else if (tabvalue === "EM2") {
        if (!data.accountno) missingFields.push("Account Number");
        if (!data.accountholder) missingFields.push("Account Holder Name");
        if (!data.ifsc) missingFields.push("IFSC Code");
      }

      if (missingFields.length > 0) {
        setAlertType("error");
        setOpen(true);
        setMsg(`Please fill all required fields: ${missingFields.join(", ")}`);
        return false;
      }

      if (invalidFields.length > 0) {
        setAlertType("error");
        setOpen(true);
        setMsg(`Invalid input in fields: ${invalidFields.join(", ")}`);
        return false;
      }

      return true;
    };

    if (validateCurrentTab()) {
      setProgressValue((prev) => prev + 15);
      setTabValue(value);
    }
  };

  if (loading) {
    return <Loader />;
  }

  // console.log("data",data);

  return (
    <>
      {jaData.onboardstatushistory &&
      jaData.onboardstatushistory.length > 0 &&
      jaData.onboardstatushistory[jaData.onboardstatushistory.length - 1]
        .status == "Offerletter sent" ? (
        <Box>
          <Box sx={{ padding: "0.8rem" }}>
            {companyDetails.companyimage ? (
              <img
                src={companyDetails.companyimage}
                alt="logo"
                width={180}
                height={50}
              ></img>
            ) : (
              <ImagePlaceHolder
                name={companyDetails.company || ""}
                size={200}
              />
            )}
          </Box>
          <TabContext value={tabvalue}>
            <TabPanel value="P1" sx={{ padding: "0" }}>
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Personal Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <PersonOutlineIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Full Name"
                          variant="outlined"
                          value={data.name}
                          name="name"
                          type="text"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <InsertInvitationIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Date Of Birth</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            fullWidth
                            sx={{ width: "100%" }}
                            value={data.dob ? dayjs(data.dob) : null}
                            name="dob"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <MailIcon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Email</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Email Id"
                          variant="outlined"
                          type="email"
                          value={data.emailid}
                          name="emailid"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <PhoneOutlined sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Primary Contact Number</p>
                        <MuiPhoneNumber
                          placeholder="Enter Primary Contact Number"
                          fullWidth
                          autoFormat={false}
                          defaultCountry={"in"}
                          disableDropdown={false}
                          className="formInputs"
                          variant="outlined"
                          value={data.primarycontact}
                          error={!data.primarycontact && showEmptyError}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "primarycontact", value },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <PhoneOutlined sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label"></p>
                        <MuiPhoneNumber
                          placeholder="Enter Alternate Contact Number"
                          fullWidth
                          autoFormat={false}
                          defaultCountry={"in"}
                          disableDropdown={false}
                          className="formInputs"
                          variant="outlined"
                          value={data.altcontact}
                          error={!data.altcontact && showEmptyError}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "altcontact", value },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <Person4Icon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Father Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Father Name"
                          variant="outlined"
                          value={data.fathername}
                          name="fathername"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0.5}>
                      <Grid item>
                        <Person2Icon sx={{ mt: 3 }} />
                      </Grid>
                      <Grid item xs>
                        <p className="form-label">Mother Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Mother Name"
                          variant="outlined"
                          value={data.mothername}
                          name="mothername"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} textAlign={"right"}>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => handleNext("P2")}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="P2" sx={{ padding: "0" }}>
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Personal Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Marital Status</p>
                        <TextField
                          fullWidth
                          select
                          placeholder="Select Marital Status"
                          variant="outlined"
                          size="small"
                          value={data.maritalstatus}
                          name="maritalstatus"
                          onChange={handleChange}
                        >
                          <MenuItem value="Single">Single</MenuItem>
                          <MenuItem value="Married">Married</MenuItem>
                          <MenuItem value="Divorced">Divorced</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Current Adress</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Current Address"
                          variant="outlined"
                          size="small"
                          value={data.currentaddress}
                          name="currentaddress"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Permanent Adress</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Permanent Address"
                          variant="outlined"
                          size="small"
                          value={data.permanentaddress}
                          name="permanentaddress"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">
                          Emergency Contact Person Name
                        </p>
                        <TextField
                          fullWidth
                          placeholder="Enter Emergency Contact Person Name"
                          variant="outlined"
                          size="small"
                          value={data.ecname}
                          name="ecname"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">
                          Relation With Emergency Person
                        </p>
                        <TextField
                          fullWidth
                          placeholder="Enter Relation With Emergency Person"
                          variant="outlined"
                          size="small"
                          value={data.ecprelation}
                          name="ecprelation"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Contact Person Address</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Contact Person Address"
                          variant="outlined"
                          size="small"
                          value={data.ecpaddress}
                          name="ecpaddress"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">
                          Contact Person Phone Number
                        </p>
                        <TextField
                          fullWidth
                          placeholder="Enter Contact Person Phone Number"
                          variant="outlined"
                          type="number"
                          size="small"
                          value={data.ecpcontactno}
                          name="ecpcontactno"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Hobbies</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Hobbies"
                          variant="outlined"
                          size="small"
                          value={data.hobbies}
                          name="hobbies"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setProgressValue((prev) => prev - 15);
                        setTabValue("P1");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => handleNext("E1")}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="E1">
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Education Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">10th Passing Year</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Select Date"
                            value={data.tenyear ? dayjs(data.tenyear) : null}
                            name="tenyear"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "tenyear",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">10th Marks in %</p>
                        <TextField
                          fullWidth
                          placeholder="Enter 10th Marks in %"
                          variant="outlined"
                          type="number"
                          value={data.tenpercent}
                          name="tenpercent"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">10th Education Board</p>
                        <TextField
                          fullWidth
                          placeholder="Enter 10th Education Board Name"
                          variant="outlined"
                          value={data.tenboard}
                          name="tenboard"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">10th Marksheet</p>
                        <TextField
                          fullWidth
                          placeholder="Upload your 10th Marksheet"
                          variant="outlined"
                          value={fileNames.tenmarksheet}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "tenmarksheet")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">12th Stream</p>
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          value={data.twelvestream}
                          name="twelvestream"
                          onChange={handleChange}
                        >
                          <MenuItem value="Arts">Arts</MenuItem>
                          <MenuItem value="Commerce">Commerce</MenuItem>
                          <MenuItem value="Science">Science</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">12th Passing Year</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Select Date"
                            value={
                              data.twelveyear ? dayjs(data.twelveyear) : null
                            }
                            name="twelveyear"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "twelveyear",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">12th Marks in %</p>
                        <TextField
                          fullWidth
                          placeholder="Enter 12th Marks in %"
                          variant="outlined"
                          type="number"
                          value={data.twelvepercent}
                          name="twelvepercent"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">12th Marksheet</p>
                        <TextField
                          fullWidth
                          placeholder="Upload your 12th Marksheet"
                          variant="outlined"
                          value={fileNames.twelvemarksheet}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "twelvemarksheet")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setProgressValue((prev) => prev - 15);
                        setTabValue("P2");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => handleNext("E2")}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="E2">
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Education Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Degree Passing Year</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Select Date"
                            value={
                              data.degreeyear ? dayjs(data.degreeyear) : null
                            }
                            name="degreeyear"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "degreeyear",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Degree Marks in %</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Degree Marks in %"
                          variant="outlined"
                          type="number"
                          value={data.degreepercent}
                          name="degreepercent"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Degree Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Degree Name"
                          variant="outlined"
                          value={data.degreename}
                          name="degreename"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Degree Marksheet</p>
                        <TextField
                          fullWidth
                          placeholder="Upload your Degree Marksheet"
                          variant="outlined"
                          value={fileNames.degreemarksheet}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "degreemarksheet")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">
                          {" "}
                          Master Degree Passing Year
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Select Date"
                            value={
                              data.masteryear ? dayjs(data.masteryear) : null
                            }
                            name="masteryear"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "masteryear",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Master Degree Marks in %</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Master Degree Marks in %"
                          variant="outlined"
                          type="number"
                          value={data.masterpercent}
                          name="masterpercent"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Master Degree Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Master Degree Name"
                          variant="outlined"
                          value={data.mastername}
                          name="mastername"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Master Degree Marksheet</p>
                        <TextField
                          fullWidth
                          placeholder="Upload your Master Degree Marksheet"
                          variant="outlined"
                          value={fileNames.mastermarksheet}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "mastermarksheet")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setProgressValue((prev) => prev - 15);
                        setTabValue("E1");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => handleNext("DD")}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="DD">
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Document Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Aadhar Number</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Aadhar Number"
                          variant="outlined"
                          type="number"
                          value={data.aadharno}
                          name="aadharno"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Upload Aadhar</p>
                        <TextField
                          fullWidth
                          placeholder="Upload Aadhar File"
                          variant="outlined"
                          value={fileNames.aadhar}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "aadhar")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">PAN Number</p>
                        <TextField
                          fullWidth
                          placeholder="Enter PAN Number"
                          variant="outlined"
                          value={data.panno}
                          name="panno"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Upload Pan</p>
                        <TextField
                          fullWidth
                          placeholder="Upload PAN File"
                          variant="outlined"
                          value={fileNames.pan}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) => handleFileUpload(e, "pan")}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Upload Photo</p>
                        <TextField
                          fullWidth
                          placeholder="Upload Your Photograph"
                          variant="outlined"
                          value={fileNames.photo}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "photo")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setProgressValue((prev) => prev - 15);
                        setTabValue("E2");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => handleNext("EM1")}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="EM1">
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Employment Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Last Organisation Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Last Company Name"
                          variant="outlined"
                          value={data.lastorg}
                          name="lastorg"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Job Role</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Job Role"
                          variant="outlined"
                          value={data.jobroll}
                          name="jobroll"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs sm md lg>
                        <p className="form-label"> From </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Select Date"
                            value={data.from ? dayjs(data.from) : null}
                            name="from"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "from",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs sm md lg>
                        <p className="form-label"> To </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            label="Select Date"
                            value={data.to ? dayjs(data.to) : null}
                            name="to"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "to",
                                  value: date ? date.format("YYYY-MM-DD") : "",
                                },
                              })
                            }
                            maxDate={dayjs()} // Restrict to past dates only
                            // error={!interviewData.interviewdate && showEmptyError}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Previous CTC in LPA</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Previous CTC"
                          variant="outlined"
                          type="number"
                          value={data.previousctc}
                          name="previousctc"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Fixed Pay</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Previous Fixed Pay"
                          variant="outlined"
                          type="number"
                          value={data.fixedpay}
                          name="fixedpay"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Variable Pay</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Previous Variable"
                          variant="outlined"
                          type="number"
                          value={data.variablepay}
                          name="variablepay"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Salary Slip</p>
                        <TextField
                          fullWidth
                          placeholder="Upload Last 3 months salary slip"
                          variant="outlined"
                          value={fileNames.salaryslip}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "salaryslip")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setProgressValue((prev) => prev - 15);
                        setTabValue("DD");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button
                      className="saveBtn"
                      endIcon={<EastIcon />}
                      onClick={() => handleNext("EM2")}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="EM2">
              <Box
                sx={{
                  width: tabPanelWidth, // Set width based on screen size
                  margin: "auto",
                  padding: "0.4rem",
                }}
              >
                <h1
                  style={{ textAlign: "center" }}
                  className="heading primarycolor"
                >
                  Employment Details
                </h1>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{ marginY: 1 }}
                />

                <Grid container spacing={1} padding={"0"}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">UAN Number</p>
                        <TextField
                          fullWidth
                          placeholder="Enter UAN Number"
                          variant="outlined"
                          type="number"
                          value={data.uan}
                          name="uan"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">ESIC Number</p>
                        <TextField
                          fullWidth
                          placeholder="Enter ESIC Number"
                          variant="outlined"
                          type="number"
                          value={data.esic}
                          name="esic"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Experience Letter</p>
                        <TextField
                          fullWidth
                          placeholder="Upload Experience Letter"
                          variant="outlined"
                          value={fileNames.expletter}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "expletter")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Bank Statement</p>
                        <TextField
                          fullWidth
                          placeholder="Upload Last 3 months statement"
                          variant="outlined"
                          value={fileNames.bankstatement}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton component="label">
                                  <AttachFileIcon />
                                  <input
                                    type="file"
                                    hidden
                                    onChange={(e) =>
                                      handleFileUpload(e, "bankstatement")
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mt: 1, color: "cyan" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Bank Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Bank Name"
                          variant="outlined"
                          type="text"
                          value={data.bank}
                          name="bank"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Bank account number</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Bank account number"
                          variant="outlined"
                          type="number"
                          value={data.accountno}
                          name="accountno"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">IFSC Number</p>
                        <TextField
                          fullWidth
                          placeholder="Enter IFSC Number"
                          variant="outlined"
                          type="text"
                          value={data.ifsc}
                          name="ifsc"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <p className="form-label">Account Holder Name</p>
                        <TextField
                          fullWidth
                          placeholder="Enter Account Holder Name"
                          variant="outlined"
                          type="text"
                          value={data.accountholder}
                          name="accountholder"
                          onChange={handleChange}
                          // error={!data.fullname && showEmptyError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setProgressValue((prev) => prev - 15);
                        setTabValue("EM1");
                      }}
                      startIcon={<WestIcon />}
                      className="cancelBtn"
                      variant="outlined"
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"right"}>
                    <Button className="saveBtn" onClick={handleSave} disabled={saveLoading}>
                      {saveLoading?"Submitting":"Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <h1
          className="primarycolor"
          style={{ textAlign: "center", marginTop: "3rem" }}
        >
          Status:{" "}
          {
            jaData.onboardstatushistory[jaData.onboardstatushistory.length - 1]
              ?.status
          }
        </h1>
      )}
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default OnBoardDocumentUpload;
