import axios from "axios";
import {
  deleteEmployeeExit,
  employeeExitRequestApi,
  getAllExitRequestApi,
  updateEmployeeExit,
} from "../../../Constants/api";

export const getEmpExit = (obj) => async (dispatch) => {
  try {
    dispatch({ type: "GET_EMP_EXIT_LOADING" });
    let res = await axios.post(getAllExitRequestApi, obj,{withCredentials:true});
    // console.log(res, "sbhsbhh");
    if (res.data) {
      dispatch({ type: "GET_EMP_EXIT_SUCCESS", payload: Array.isArray(res.data)?res.data:[] });
    } else {
      // console.log(res, "error res");
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_EMP_EXIT_FAILED", payload: error.message });
  }
};

export const addEmpExit =
  (obj, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "ADD_EMP_EXIT_LOADING" });
      let res = await axios.post(employeeExitRequestApi, obj,{withCredentials:true});
      // console.log("add res of emp", res);
      if (res.data.message == "Employee exit added successfully") {
        dispatch({ type: "ADD_EMP_EXIT_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
      } else {
        // console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "ADD_EMP_EXIT_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const updateEmpExit =
  (obj, setOpen, setMsg, setAlertType,navigate) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_EMP_EXIT_LOADING" });
      let res = await axios.post(updateEmployeeExit, obj,{withCredentials:true});
      if (res.data.message == "Employee exit updated successfully") {
        dispatch({ type: "UPDATE_EMP_EXIT_SUCCESS", payload: res.data.data });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        setTimeout(()=>{
          navigate(`/hrm/employeemanagement/employeeexit`)
        },1000);
      } else {
        // console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "UPDATE_EMP_EXIT_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };

export const deleteEmpExit =
  (idArray, handleClose, setOpen, setMsg, setAlertType) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_EMP_EXIT_LOADING" });
      let res = await axios.post(deleteEmployeeExit, idArray,{withCredentials:true});
      if (res.data.message == "Employee exit deleted successfully") {
        dispatch({ type: "DELETE_EMP_EXIT_SUCCESS", payload: idArray });
        setAlertType("success");
        setOpen(true);
        setMsg(res.data.message);
        handleClose();
      } else {
        // console.log(res, "error res");
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_EMP_EXIT_FAILED", payload: error.message });
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
    }
  };
