import { Box, Modal, Typography, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Cookies from "js-cookie";
import axios from "axios";
import { vlivApi, vlivDomain } from "../../../../../Constants/api";
import { formatDate } from "../../../../../Constants/dateFormat";
import AlertPopUp from "../../../../../Constants/AlertPopUp";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  width: "65%",
  maxWidth: "900px",
  height: "auto",
  maxHeight: "90vh",
  bgcolor: "#f5f5f5",
  boxShadow: 24,
  overflow: "hidden",
  padding: "2rem",
};

const contentStyle = {
  backgroundColor: "#ffffff",
  padding: "2rem",
  border: "1px solid #d3d3d3",
  maxHeight: "calc(80vh - 60px)",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "5px",
  },
};

const headerStyle = {
  textAlign: "center",
  marginBottom: "1rem",
};

const dateSectionStyle = {
  textAlign: "right",
  marginBottom: "1rem",
  fontSize: "14px",
};

const addressStyle = {
  marginBottom: "2rem",
  fontSize: "14px",
  lineHeight: "1.5",
};

const sectionStyle = {
  marginBottom: "1.5rem",
  lineHeight: "1.8",
  fontSize: "14px",
};

const footerStyle = {
  marginTop: "2rem",
  paddingTop: "1rem",
  borderTop: "1px solid #d3d3d3",
  textAlign: "left",
};

const signatureStyle = {
  marginTop: "3rem",
  display: "flex",
  justifyContent: "space-between",
};

const ViewOfferLetter = ({
  offerModalOpen,
  setOfferModalOpen,
  offerSelectRow,
  setofferSelectRow,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let companyname = userData.companyname ? userData.companyname : "";
  let address = userData.address ? userData.address : "";
  let pincode = userData.pincode ? userData.pincode : "";

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const contentRef = useRef(null);
  const { fullname, position, company, joiningdate, data } =
    offerSelectRow || {};

  const [companyDetails, setCompanyDetails] = useState({});

  const handleDownload = () => {
    const content = contentRef.current;
    if (!content) return;

    const imgElement = content.querySelector("img");

    const generatePDF = () => {
      const originalStyle = content.style.cssText;
      content.style.maxHeight = "none";
      content.style.overflowY = "visible";

      html2canvas(content, { scale: 2, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${fullname}_Offer_Letter.pdf`);

        content.style.cssText = originalStyle;
      });
    };

    if (imgElement) {
      imgElement.crossOrigin = "anonymous"; // Handle CORS issues

      imgElement.onload = () => generatePDF();
      imgElement.onerror = () => {
        console.warn("Image failed to load, proceeding without it.");
        generatePDF();
      };

      if (imgElement.complete) {
        generatePDF();
      }
    } else {
      generatePDF();
    }
  };

  const getCompanyDetails = async () => {
    try {
      let res = await axios.post(
        `${process.env.NODE_ENV == "production" ? vlivDomain : vlivApi}/company/getcompanybyid`,
        {
          companyid,
        },
        { withCredentials: true }
      );

      res.data && res.data
        ? setCompanyDetails(res.data)
        : setCompanyDetails({});
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleSendEmail = async (offerletter) => {
    // console.log(paramsRow,"paramsrow")

    try {
      let res = await axios.post(
        "https://hrms.vliv.app/jobapplication/confirmmail",
        {
          ...offerSelectRow.data,
          companyname: companyname,
          date: new Date(),
          finalctc:
            offerSelectRow.data.history[offerSelectRow.data.history.length - 1]
              .finalctc,
          joiningdate:
            offerSelectRow.data.history[offerSelectRow.data.history.length - 1]
              .joiningdate,
          offerletter,
        },
        { withCredentials: true }
      );
      // console.log(res, "res");
      if (res.data.message == "Job application updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Offer Letter has been sent");
        setOfferModalOpen(false);
        setofferSelectRow(null);
      } else {
        setAlertType("error");
        setOpen(true);
        setMsg("Something went wrong");
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.message);
      setOfferModalOpen(false);
      setofferSelectRow(null);
    }
  };

  const handleSendOfferLetter = async () => {
    const content = contentRef.current;
    if (!content) return;

    const originalStyle = content.style.cssText;

    try {
      content.style.maxHeight = "none";
      content.style.overflowY = "visible";

      // Generate the canvas
      const canvas = await html2canvas(content, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Convert the PDF to a blob
      const pdfBlob = pdf.output("blob");

      // console.log(pdfBlob, "pdf blob");

      // Prepare the form data
      const formData = new FormData();
      formData.append("file", pdfBlob, `${fullname}_Offer_Letter.pdf`);

      // Send the PDF to the backend
      const response = await axios.post(
        `https://hrms.vliv.app/candidate/offerletter`,
        formData,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle success
      if (response.status === 200) {
        // alert("Offer letter sent successfully!");
        await handleSendEmail(response.data.file);
      } else {
        setAlertType("error");
        setOpen(true);
        setMsg("Failed to send the offer letter. Please try again.");
      }
    } catch (error) {
      console.error("Error sending offer letter:", error);
      setAlertType("error");
      setOpen(true);
      setMsg("Failed to send the offer letter. Please try again.");
      setOfferModalOpen(false);
      setofferSelectRow(null);
    } finally {
      content.style.cssText = originalStyle;
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <>
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
          setofferSelectRow(null);
        }}
      >
        <Box sx={modalStyle}>
          <Box sx={contentStyle} id="offer-letter-content" ref={contentRef}>
            {/* Header */}
            <Box sx={headerStyle}>
              <img
                src={companyDetails.companyimage || ""}
                alt="Company_Logo"
                width={"170px"}
                height={"80px"}
                crossOrigin="anonymous" // Ensure CORS is handled
              />
              <Typography variant="h5" fontWeight="bold">
                Offer of Intent
              </Typography>
            </Box>

            {/* Date Section */}
            <Box sx={dateSectionStyle}>
              <Typography variant="body2">
                Date:{" "}
                {new Date().toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </Typography>
            </Box>

            {/* Company Address Section */}
            <Box sx={addressStyle}>
              <Typography variant="body2">
                {companyname} <br />
                {address} {pincode} <br />
                {companyemail}
              </Typography>
            </Box>

            {/* Candidate Address Section */}
            <Box sx={addressStyle}>
              <Typography variant="body2">
                {fullname} <br />
                {data && data.email ? data.email : ""} <br />
              </Typography>
            </Box>

            {/* Offer Letter Body */}
            <Box sx={sectionStyle}>
              <Typography variant="body1" gutterBottom>
                Dear {fullname},
              </Typography>
              <Typography variant="body1" gutterBottom>
                We are pleased to offer you employment at {companyname} as{" "}
                <strong>{position}</strong>. This letter outlines the terms and
                conditions of your employment with us.
              </Typography>
            </Box>

            {/* Compensation Section */}
            <Box sx={sectionStyle}>
              <Typography variant="body1" gutterBottom>
                Your initial salary will be discussed at the time of joining and
                subject to the terms and conditions of the company. In addition
                to your salary, you will be eligible for our standard benefits
                package.
              </Typography>
            </Box>

            {/* Joining Date Section */}
            <Box sx={sectionStyle}>
              <Typography variant="body1" gutterBottom>
                We would like you to start on{" "}
                <strong>
                  {data && data.joiningdate ? formatDate(data.joiningdate) : ""}
                </strong>
                . Please report to the HR department at 9:00 AM on your first
                day.
              </Typography>
            </Box>

            {/* Closing Remarks */}
            <Box sx={sectionStyle}>
              <Typography variant="body1" gutterBottom>
                We are excited to have you join our team and contribute to the
                growth of <strong>{companyname}</strong>
              </Typography>
              <Typography variant="body1" mt={2} gutterBottom>
                Please sign below to accept this offer and return this letter us
                by end of this week.
              </Typography>
            </Box>

            {/* Footer */}
            <Box sx={footerStyle}>
              <Typography variant="body1">
                Sincerely, <br />
                <strong>
                  {companyDetails && companyDetails.clientname
                    ? companyDetails.clientname
                    : ""}
                </strong>{" "}
                <br />
                Admin <br />
                {companyname}
              </Typography>

              {/* Signature Section */}
              <Box sx={signatureStyle}>
                <Typography variant="body1">
                  __________________________ <br />
                  Signature
                </Typography>
                <Typography variant="body1">
                  __________________________ <br />
                  Date
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Action Buttons */}
          <Box my={2} display={"flex"} justifyContent={"center"} gap={2} px={2}>
            <Button className="saveBtn" onClick={handleDownload}>
              Download
            </Button>
            {offerSelectRow &&
            offerSelectRow.onboardstatushistory &&
            offerSelectRow.onboardstatushistory.length &&
            offerSelectRow.onboardstatushistory[0].status ==
              "Offerletter sent" ? null : (
              <Button className="saveBtn" onClick={handleSendOfferLetter}>
                Send Offer Letter
              </Button>
            )}

            <Button
              variant="outlined"
              className="cancelBtn"
              onClick={() => {
                setOfferModalOpen(false);
                setofferSelectRow(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default ViewOfferLetter;
