import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../../Constants/Loader";
import { getPerformanceApi } from "../../../../Constants/api";

const SummaryKPI = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let username = userData.username ? userData.username : "";
  let userRole = userData.userrole ? userData.userrole : "";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getPerformanceApi, {
        companyemail,
        companyid,
      },{withCredentials:true});
      // console.log(res,"summary response");
      if (res.data && res.data.length > 0) {
        if(userRole=="HR" || userRole=="masteradmin"){
          setData(res.data);
        }
        else{
          setData(res.data.filter((obj) => obj.reportmanager === username));
        }
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { field: "empid", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "orgrating", headerName: "Organization Goal KPI", flex: 1 },
    { field: "emprating", headerName: "Employee Developement KPI", flex: 1 },
    { field: "teamrating", headerName: "Team Building KPI", flex: 1 },
    { field: "totalrating", headerName: "Overall Ratings", flex: 1 },  
  ];

  const rows =
    data.length > 0
      ? data.map((orgkpi) => ({
          id: orgkpi.id,
          empid: orgkpi.empid,
          name: orgkpi.name,
          department: orgkpi.department,
          position: orgkpi.position,
          orgrating: orgkpi.orgrating ? orgkpi.orgrating : "-",
          teamrating: orgkpi.teamrating ? orgkpi.teamrating : "-",
          emprating: orgkpi.emprating ? orgkpi.emprating : "-",
          totalrating: orgkpi.totalrating ? orgkpi.totalrating : "-",
          data: orgkpi,
        }))
      : [];

  const theme = useTheme();


  const calculatePerformanceSummary = (data) => {
    let highPerformers = 0;
    let mediumPerformers = 0;
    let lowPerformers = 0;

    data.forEach((item) => {
      if (item.totalrating >= 4) {
        highPerformers++;
      } else if (item.totalrating >= 3) {
        mediumPerformers++;
      } else {
        lowPerformers++;
      }
    });

    return { highPerformers, mediumPerformers, lowPerformers };
  };

  const { highPerformers, mediumPerformers, lowPerformers } = calculatePerformanceSummary(data);

  return (
    <>
      <Box display="flex" justifyContent="space-around" my={2} width={"50%"}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="#e0f7e9"
          border="1px solid #43a047"
          borderRadius="8px"
          p={2}
          width="30%"
        >
          <Typography variant="subtitle1" color="#43a047">
            High Performers
          </Typography>
          <Typography variant="h4" color="#43a047">
            {highPerformers}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="#fff3e0"
          border="1px solid #fb8c00"
          borderRadius="8px"
          p={2}
          width="30%"
        >
          <Typography variant="subtitle1" color="#fb8c00">
            Medium Performers
          </Typography>
          <Typography variant="h4" color="#fb8c00">
            {mediumPerformers}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="#ffebee"
          border="1px solid #e53935"
          borderRadius="8px"
          p={2}
          width="30%"
        >
          <Typography variant="subtitle1" color="#e53935">
            Low Performers
          </Typography>
          <Typography variant="h4" color="#e53935">
            {lowPerformers}
          </Typography>
        </Box>
      </Box>
      <Box my={2}>
        <Paper style={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20, rows.length]}
            getRowId={(row) => row.id}
            autoHeight
            components={{
              LoadingOverlay: Loader,
            }}
            loading={loading}
          />
        </Paper>
      </Box>
    </>
  );
};

export default SummaryKPI;
