import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import Cookies from "js-cookie";
import { Button } from "@mui/material";
import { AuthContext } from "../Context/AuthContextProvider";

function ProtectRoute() {
  const { authContext, isMobileView } = useContext(AuthContext)
  const isLoggedIn = authContext.token;
  // console.log("authContext", authContext)
  // const location = useLocation();

  // const handleGoBack = () => {
  //   window.history.back();
  // };

  if (isMobileView && !isLoggedIn) {
    return <Navigate to="/mobile/signin" />;
  } else if (isMobileView && isLoggedIn) {
    // return <Navigate to="/mobile/home" />;
    return <Navigate to="/mobile/attendance" />;
  }

  if (!isLoggedIn && !isMobileView) {
    return <Navigate to="/signin" />;
  }

  return (
    <>
      {/* <Button onClick={handleGoBack} startIcon={<WestIcon/>} sx={{mx:2,my:1,color:"#98A2B3;",textTransform:"capitalize",fontSize:"14px",fontWeight:"300"}}>Back</Button> */}
      <Outlet />
    </>
  );
}

export default ProtectRoute;
