import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import React, { useContext, useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { CgMicrosoft } from "react-icons/cg";
import { FaApple } from "react-icons/fa6";
// import { Country, State, City } from "country-state-city";
import vliv_logo from "../../assets/venturesathi-logo.png";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
// import { adminLogin, companySignup } from "../../../src/constants/api";
// import moment from "moment-timezone";
import Cookies from "js-cookie";
import { LoginApi } from "../../Constants/api";
import AlertPopUp from "../../Constants/AlertPopUp";
import { AuthContext } from "../../Context/AuthContextProvider";
// import { GoogleLogin } from "@react-oauth/google";
// import { notifyError, notifySuccess } from "../../Constrant/toastAlert";
// import { notifyError, notifySuccess } from "../../../src/constants/toastAlerts";
// import { useDispatch } from "react-redux";
// import { userLogin } from "../../redux/actions/login.action";
// import { LOGIN_SUCCCESS } from "../../redux/actionTypes";
// const allCountries = Country.getAllCountries();

const SignIn = () => {
  const { authContext, setAuthContext } = useContext(AuthContext);
  //   // const login = useSelector((state) => state.loginReducer);
  const isProduction = process.env.NODE_ENV === "production";
  const navigate = useNavigate();
  //   // const dispatch = useDispatch();
  const [cred, setCred] = useState({
    email: "",
    password: "",
    // module: "HRMS",
    // location: "",
    // ip: "",
    // city: "",
    // country: "",
    // state: "",
  });
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);

  const handleClickShowLoginPassword = () =>
    setShowLoginPassword((show) => !show);

  const handleMouseDownLoginPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setCred({ ...cred, [name]: value });
  };

  //for alert msg
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //for alert msg end

  // useEffect(() => {
  //   const checkLocationPermissionAndFetch = async () => {
  //     if (navigator.geolocation && navigator.permissions) {
  //       try {
  //         const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
  //         if (permissionStatus.state === 'granted') {
  //           getLocation(); // Fetch location if permission was previously granted
  //         }
  //       } catch (error) {
  //         console.log("Error checking geolocation permission:", error);
  //       }
  //     }
  //   };

  //   checkLocationPermissionAndFetch();
  // }, []);

  // Function to get the user's location
  // const getLocation = async () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         console.log("Location permission granted");
  //         const { latitude, longitude } = position.coords;
  //         getAddressFromCoordinates(latitude, longitude);
  //       },
  //       (error) => {
  //         console.log("Location permission denied or error occurred", error);
  //         if (error.message == "User denied Geolocation") {
  //           getIPDetails();
  //         }
  //         // console.error("Error fetching geolocation:", error);
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // };

  // const getIPDetails = async () => {
  //   try {
  //     const response = await axios.get("https://api.ipify.org?format=json")
  //     const ipAddress = response.data.ip;
  //     // Set the IP in the credentials
  //     setCred((prevCred) => ({
  //       ...prevCred,
  //       ip: ipAddress
  //     }));
  //     const getAddressFromIpDetails = await getLocationFromIpDetails(ipAddress);
  //     const { latitude, longitude } = getAddressFromIpDetails;
  //     getAddressFromCoordinates(latitude, longitude);
  //   }
  //   catch (error) {
  //     console.log('Error while fetching Ip details', error);
  //   }
  // }


  // const getLocationFromIpDetails = async (ip) => {
  //   try {
  //     // const response = await axios.get(`http://ip-api.com/json/${ip}`);
  //     const response = await axios.get(`https://ipapi.co/${ip}/json/`);
  //     // console.log(" ip res", response)
  //     const { city, region, country, org, latitude, longitude } = response.data;
  //     const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
  //     return {
  //       city,
  //       regionName: region,
  //       country,
  //       device,
  //       isp: org,
  //       latitude,
  //       longitude,
  //     };
  //   }
  //   catch (err) {
  //     console.log("Error while fetching location details", err)
  //   }
  // }

  // // Function to reverse geocode and get the location name
  // const getAddressFromCoordinates = async (lat, lon) => {
  //   const API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54"; // Add your Google Maps API key here
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

  //   try {
  //     const response = await axios.get(url);
  //     const result = response.data.results[0];
  //     if (result) {
  //       const addressComponents = result.address_components;

  //       let city = "";
  //       let state = "";
  //       let country = "";

  //       addressComponents.forEach((component) => {
  //         if (component.types.includes("locality")) {
  //           city = component.long_name;
  //         }
  //         if (component.types.includes("administrative_area_level_1")) {
  //           state = component.long_name;
  //         }
  //         if (component.types.includes("country")) {
  //           country = component.long_name;
  //         }
  //       });

  //       // Update the cred object with the location details
  //       setCred((prevCred) => ({
  //         ...prevCred,
  //         location: result.formatted_address, // Full address
  //         city: city,
  //         state: state,
  //         country: country
  //       }));
  //     } else {
  //       console.error("No results found for the given coordinates");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching location name:", error);
  //   }
  // };

  const handleSubmit = async () => {
    //  dispatch(userLogin(data, navigate));
    if (!cred.email || !cred.password) {
      setShowEmptyError(true);
      setAlertType("error");
      setOpen(true);
      setMsg("You have empty fields");
    } else {
      // await getLocation();
      // if (!cred.location) {
      //   setShowEmptyError(true);
      //   setAlertType("error");
      //   setOpen(true);
      //   setMsg("For better experience!! Please allow your location.");
      // } else {
        try {
          // console.log("cred", cred);
          let res = await axios.post(`${LoginApi}`, cred);
          // console.log(res, "login response");
          if (res.data.message == "Login Successful") {
            Cookies.set("companytoken", res.data.token, {
              domain: isProduction ? ".vliv.app" : "localhost",
              path: "/",
              secure: true,
              sameSite: isProduction ? "None" : "Lax",
              expires: 1,
            });

            setAuthContext({ token: res.data.token, userData: res.data.data });

            const {
              password,
              confirmpassword,
              yearlypremium,
              yearlystandard,
              yearlybasic,
              premium,
              standard,
              basic,
              addonpack,
              featureslist,
              purchaseaddonpack,
              ...userinfo
            } = res.data.data;
            Cookies.set("userinfo", JSON.stringify(userinfo), {
              domain: isProduction ? ".vliv.app" : "localhost",
              path: "/",
              secure: true,
              sameSite: isProduction ? "None" : "Lax",
              expires: 1
            });

            let role = res.data.data.userrole ? res.data.data.userrole : "";
            if (role == "User") {
              setAlertType("success");
              setOpen(true);
              setMsg(res.data.message);
              setTimeout(() => {
                navigate("/hrm/employeeselfservice/summary");
                window.location.reload();
              }, 500);

              // Will think about it later
              // setTimeout(() => {

              // }, 0);
            } else {
              setAlertType("success");
              setOpen(true);
              setMsg(res.data.message);
              setTimeout(() => {
                navigate("/hrm/summary/welcome");
                window.location.reload();
              }, 500);
            }
          } else {
            setAlertType("error");
            setOpen(true);
            setMsg(res.data.message);
          }
        } catch (error) {
          console.log(error);
          setAlertType("error");
          setOpen(true);
          setMsg(error.response.data.message || "Something went wrong");
        }
      // }
    }
  };

  const handleGoogle = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "http://206.189.128.35:6001/auth";

    document.body.appendChild(form);
    form.submit();
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box className="signin-main">
        {isSmallScreen ? null : (
          <Box
            className="signin-left"
            width={"35%"}
            height={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box textAlign="center">
              {/* <img src={vliv_logo} alt="logo" className="logo_img" />
            <p style={{ fontSize: "1.3rem" }}>Sign in an Account</p> */}
            </Box>
          </Box>
        )}
        <Box
          className="signin-right"
          width={isSmallScreen ? "90%" : "60%"}
          height={"100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"

        >
          <Box
            width="100%"
            maxWidth="430px"
            p={3}
            boxShadow={3}
            borderRadius={4}
            bgcolor="white"
          >
            <Box mb={2} display={"flex"} alignItems={"center"} justifyContent={"center"} >
              <img
                src={vliv_logo}
                alt="logo"
                className="logo_img"
                style={{ marginRight: "10px" }}
              />
            </Box>
            {/* {!isSmallScreen && <Typography
              component="h1"
              variant="h3"
              fontWeight={"700"}
              sx={{ fontSize: "1.5rem" }}
              textAlign={"center"}
            >
              (HRMS)
            </Typography>} */}
            <Box>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ my: "0.6rem" }}
                size="small"
              >
                <FormLabel sx={{ fontSize: "1.2rem", my: 0.4 }}>
                  Email Address
                </FormLabel>
                <OutlinedInput
                  name="email"
                  value={cred.email}
                  onChange={handleLoginChange}
                  placeholder="Enter Your Mail Address"
                  sx={{
                    borderRadius: "25px",
                    fontSize: "1rem",
                    padding: 0.5,
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "1rem", // Set the placeholder font size
                    },
                  }}
                  error={!cred.email && showEmptyError}
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ my: "0.6rem", mb: 1 }}
              >
                <FormLabel sx={{ fontSize: "1.2rem", my: 0.4 }}>
                  Password
                </FormLabel>
                <OutlinedInput
                  size="small"
                  name="password"
                  type={showLoginPassword ? "text" : "password"}
                  value={cred.password}
                  onChange={handleLoginChange}
                  placeholder="Enter Your Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowLoginPassword}
                        onMouseDown={handleMouseDownLoginPassword}
                      >
                        {showLoginPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: "25px",
                    fontSize: "1rem",
                    padding: 0.5,
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "1rem", // Set the placeholder font size
                    },
                  }}
                  error={!cred.password && showEmptyError}
                />
              </FormControl>
              <div
                className="button-container"
                style={{
                  margin: "2rem 0rem 1rem 0rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  sx={{
                    width: "100%",
                    mb: 1,
                    borderRadius: "25px",
                    border: "0.1px solid #98A2B3",
                    p: 1,
                    fontSize: "1rem",
                    textTransform: "none",
                    color: "#ffff",
                    backgroundColor: "#175376",
                    "&:hover": {
                      backgroundColor: "#175376", // Prevent background color change on hover
                    },
                  }}
                >
                  Sign In
                </Button>
              </div>
              {/* <Box
                variant="body2"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1.3}
                my={2}
              >
                <Box flex={2}>
                  <hr />
                </Box>
                <Box fontSize={"1rem"}>Or</Box>
                <Box flex={2}>
                  <hr />
                </Box>
              </Box>
              <Button
                fullWidth
                // variant="outlined"

                onClick={handleGoogle}
                startIcon={<FcGoogle />}
                sx={{
                  mb: 1,
                  borderRadius: "25px",
                  border: "0.1px solid #98A2B3",
                  p: 1,
                  fontSize: "1.2rem",
                  textTransform: "none",
                  color: "black",
                }}
              >
                Continue with Google
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default SignIn;
