import React, { useState } from 'react'
import AlertPopUp from '../../../../Constants/AlertPopUp';
import { Box, Button, Tab, Tooltip } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { DataGrid } from '@mui/x-data-grid';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Constants/Loader';
import LoginHistory from './LoginHistory';
import CrudHistory from './CrudHistory';

function AuditLog() {

    

    

    //Redux
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(
        (state) => state.recruitmentReducer
    );

    //state
    const [tabvalue, setTabValue] = useState("crudhistory");

    //useeffect

    //Function
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Box className="custom-mui">
                <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={tabvalue}>
                        <Box sx={{ borderColor: "divider" }}>
                            <TabList
                                onChange={handleTabChange}
                            // aria-label="lab API tabs example"
                            >
                                <Tab
                                    label="Crud History"
                                    value="crudhistory"
                                    sx={{ textTransform: "none" }}
                                />
                                <Tab
                                    label="Login History"
                                    value="loginhistory"
                                    sx={{ textTransform: "none" }}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="crudhistory">
                            <Box
                                sx={{
                                    height: "auto",
                                    width: "100%",
                                    // display: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "flex-end",
                                    // gap: 2,
                                }}
                            >
                                <CrudHistory />

                            </Box>

                        </TabPanel>
                        <TabPanel value="loginhistory">
                            <Box
                                sx={{
                                    height: "auto",
                                    width: "100%",
                                    // display: "flex",
                                    // alignItems: "center",
                                    // justifyContent: "flex-end",
                                    // gap: 2,
                                }}
                            >
                                <LoginHistory />

                            </Box>

                        </TabPanel>
                    </TabContext>
                </Box>

            </Box>
            
        </>
    )
}

export default AuditLog