import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Fade from '@mui/material/Fade';
import { Search as SearchIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../../../../../Constants/Loader";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import ConfirmRejectModal from "./ConfirmRejectModal";
import { formatDate } from "../../../../../Constants/dateFormat";

const LeaveApprove = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const userrole = companyInfo.userrole;
  const email = companyInfo.email;
  const username = companyInfo.username;
  // console.log(userrole, "user role");

  let role = "";
  if (userrole == "HR") {
    role = "hr";
  } else if (userrole == "Manager") {
    role = "manager";
  }

  const navigate = useNavigate();

  const [empLeaveApplyData, setEmpLeaveApplyData] = useState([]);
  const [empLeaveApplyLoading, setEmpLeaveApplyLoading] = useState(true);
  const [empLeaveApplyError, setEmpLeaveApplyError] = useState(null);

  const [leaveApprovers, setLeaveApprovers] = useState([]);

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [searchValue, setSearchValue] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRejectConfirm = async (reason) => {
    if (selectedRow) {
      const updatedData = {
        ...selectedRow,
        [`${role.toLowerCase()}status`]: "Rejected",
        rejectionreason: reason,
      };
      try {
        await axios.post(
          "https://hrms.vliv.app/attedance/leave/update",
          updatedData,
          { withCredentials: true }
        );
        setOpen(true);
        setMsg("Leave status updated successfully");
        setAlertType("success");
        fetchData();
      } catch (error) {
        setOpen(true);
        setMsg("Failed to update leave status");
        setAlertType("error");
      }
    }
    setRejectModalOpen(false);
  };

  const HRStatusCellRenderer = (params) => {
    const [status, setStatus] = useState(params.value);

    const handleStatusChange = async (event) => {
      if (status == "Approved" || status == "Rejected") {
        setAlertType("error");
        setOpen(true);
        setMsg("Can't Modify to Approved/Reject Leave");
        return;
      }
      const newStatus = event.target.value;
      const updatedData = { ...params.row };

      if (newStatus === "Rejected") {
        setSelectedRow({
          ...params.row,
          [`hrstatus`]: newStatus,
        });
        setRejectModalOpen(true);
      } else {
        updatedData[`hrstatus`] = newStatus;
        try {
          await axios.post(
            "https://hrms.vliv.app/attedance/leave/update",
            updatedData,
            { withCredentials: true }
          );
          setOpen(true);
          setMsg("Leave status updated successfully");
          setAlertType("success");
          fetchData();
        } catch (error) {
          setOpen(true);
          setMsg("Failed to update leave status");
          setAlertType("error");
        }
      }
      setStatus(newStatus);
    };

    const getStatusBgColor = (status) => {
      switch (status) {
        case "Pending":
          return "#FEC84B";
        case "Approved":
          return "#6CE9A6";
        case "Rejected":
          return "#FDA29B";
        default:
          return "transparent";
      }
    };

    return (
      <Select
        value={status}
        onChange={handleStatusChange}
        displayEmpty
        fullWidth
        size="small"
        disabled={
          !params.row.managerstatus || params.row.managerstatus != "Approved" || userrole.toLowerCase()!="hr"
        }
        sx={{
          border: "none",
          bgcolor: getStatusBgColor(status),
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Approved">Approved</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
      </Select>
    );
  };

  const ManagerStatusCellRenderer = (params) => {
    const [status, setStatus] = useState(params.value);

    const handleStatusChange = async (event) => {
      if (status == "Approved" || status == "Rejected") {
        setAlertType("error");
        setOpen(true);
        setMsg("Can't Modify to Approved/Reject Leave");
        return;
      }

      const newStatus = event.target.value;
      const updatedData = { ...params.row };

      if (newStatus === "Rejected") {
        setSelectedRow({
          ...params.row,
          [`managerstatus`]: newStatus,
        });
        setRejectModalOpen(true);
      } else {
        updatedData[`managerstatus`] = newStatus;
        try {
          await axios.post(
            "https://hrms.vliv.app/attedance/leave/update",
            { ...updatedData, approver: username },
            { withCredentials: true }
          );
          setOpen(true);
          setMsg("Leave status updated successfully");
          setAlertType("success");
          fetchData();
        } catch (error) {
          setOpen(true);
          setMsg("Failed to update leave status");
          setAlertType("error");
        }
      }
      setStatus(newStatus);
    };

    const getStatusBgColor = (status) => {
      switch (status) {
        case "Pending":
          return "#FEC84B";
        case "Approved":
          return "#6CE9A6";
        case "Rejected":
          return "#FDA29B";
        default:
          return "transparent";
      }
    };

    return (
      <Tooltip
        title={
          params.row.approver
            ? `Approved by ${params.row.approver}`
            : "Not Available"
        }
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 1000 }}
      >
        <Select
          value={status}
          onChange={handleStatusChange}
          displayEmpty
          fullWidth
          size="small"
          disabled={
            !params.row.managers ||
            (params.row.managers && !params.row.managers.includes(email))
          }
          sx={{
            border: "none",
            bgcolor: getStatusBgColor(status),
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="Rejected">Rejected</MenuItem>
        </Select>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => <>{formatDate(params.row.date)}</>,
    },
    {
      field: "empid",
      headerName: "ID",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "empname",
      headerName: "Name",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "leavetype",
      headerName: "Leave Type",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "fromdate",
      headerName: "Leave From",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => <>{formatDate(params.row.fromdate)}</>,
    },
    {
      field: "todate",
      headerName: "Leave To",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => <>{formatDate(params.row.todate)}</>,
    },
    {
      field: "attachfile",
      headerName: "Attachment",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "reason",
      headerName: "Reason",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "hrstatus",
      headerName: "HR Status",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => HRStatusCellRenderer(params),
    },
    {
      field: "managerstatus",
      headerName: "Manager Status",
      headerClassName: "datagrid-header",
      flex: 1,
      renderCell: (params) => ManagerStatusCellRenderer(params),
    },
  ];

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    return (
      empLeaveApplyData &&
      empLeaveApplyData.length > 0 &&
      empLeaveApplyData?.filter((item) =>
        searchValue
          ? Object.values(item).some((value) =>
              value
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase().trim())
            )
          : true
      )
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  const handleRowClick = (params) => {
    //navigate(`/hrm/employeemanagement/registration/update/${params.row.id}`);
  };

  const fetchData = async () => {
    setEmpLeaveApplyLoading(true);
    try {
      const response = await axios.post(
        "https://hrms.vliv.app/attedance/leave/get",
        {
          companyid: companyInfo.companyid,
          companyemail: companyInfo.companyemail,
        },
        { withCredentials: true }
      );
      setEmpLeaveApplyData(response.data);
      setEmpLeaveApplyLoading(false);
    } catch (error) {
      setEmpLeaveApplyError(error);
      setEmpLeaveApplyLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [rejectModalOpen]);

  // console.log(empLeaveApplyData, "empLeaveApplyData");

  return (
    <>
      <Box>
        <Box className="searchbar-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={empLeaveApplyLoading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[5, 10, 15, 20]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={empLeaveApplyLoading}
            //onRowClick={handleRowClick}
            // disableRowSelectionOnClick
            // onRowSelectionModelChange={(newSelection) => {
            //   handleSelectionModelChange(newSelection);
            // }}
          />
        </Box>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
      <ConfirmRejectModal
        selectedRow={selectedRow}
        open={rejectModalOpen}
        handleClose={() => setRejectModalOpen(false)}
        handleConfirm={handleRejectConfirm}
      />
    </>
  );
};

export default LeaveApprove;
