import React, { useState, useEffect } from "react";
import {
  Box,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updatedocumentgenerationapi } from "../../../../../Constants/api";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { recommendcontext } from "../../../../../Constants/editorContext";

const EditRecommendationLetter = ({
  openEditDialog,
  handleCloseEditDialog,
  selectedRow,
}) => {
  //for alert
  const [data, setData] = useState({});

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e, ind) => {
    const updatedArr = data.recommendarray.map((recommend, index) => {
      if (index == ind) {
        return { ...recommend, format: e };
      } else {
        return recommend;
      }
    });

    setData((prev) => ({ ...prev, recommendarray: updatedArr }));
  };

  const handleSave = async () => {
    try {
      let res = await axios.post(updatedocumentgenerationapi, data,{withCredentials:true});
      if (res.data.message == "Document updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Recommendation Letter Modified");
        setTimeout(() => {
          handleCloseEditDialog();
        }, 1000);
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      setTimeout(() => {
        handleCloseEditDialog();
      }, 1000);
    }
  };

  useEffect(() => {
    if (selectedRow && selectedRow.data) {
      setData({ ...selectedRow.data });
    } else {
      setData({});
    }
  }, [selectedRow]);

  // console.log(data,"aeiou")

  return (
    <>
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit Recommendation Letter</DialogTitle>
        <DialogContent>
          {data &&
            data.recommendarray &&
            data.recommendarray.length > 0 &&
            data.recommendarray.map((recommend, ind) => (
              <Box key={ind} my={1}>
                <ReactQuill
                  value={recommend.format}
                  onChange={(e) => handleChange(e, ind)}
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      [{ color: [] }, { background: [] }], // Text color and background color
                      ["link", "image", "video"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "video",
                    "color",
                    "background",
                  ]}
                />
              </Box>
            ))}
          <Box>
            <Button
              variant="outlined"
              endIcon={<AddBoxIcon />}
              className="saveBtn"
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  recommendarray: [
                    ...data.recommendarray,
                    { format: recommendcontext, date: new Date() },
                  ],
                }))
              }
            >
              Add New
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditDialog}
            variant="outlined"
            className="cancelBtn"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} className="saveBtn">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleCloseAlert}
        type={alertType}
      />
    </>
  );
};

export default EditRecommendationLetter;
