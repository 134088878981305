import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const AttendanceAppDownload = () => {
  const handleDownload = () => {
    const downloadLink =
      "https://vs1-storage.blr1.digitaloceanspaces.com/HRMS/ATTENDANCE_APK/Vlivattendance.apk";
    window.open(downloadLink, "_blank");
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", marginY: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant={"h5"} className="primarycolor" mb={3}>
          Download Attendance App (v1.0.2)
        </Typography>
        <Typography className="view-page-title" mb={2}>
          Click the button below to download the Attendance App and start
          tracking your attendance seamlessly!
        </Typography>
        <Typography className="view-page-title" mb={2}>
          Last updated on - 14.10.2024
        </Typography>
        <Button
          className="saveBtn"
          startIcon={<CloudDownloadIcon />}
          onClick={handleDownload}
          sx={{ padding: "10px 20px" }}
        >
          Download App
        </Button>
      </Box>
    </Container>
  );
};

export default AttendanceAppDownload;
