import {
  Box,
  Checkbox,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AddSeries from "./AddSeries";
import Loader from "../../../../Constants/Loader";
import {
  deleteEmpSeries,
  getEmpSeries,
} from "../../../../redux/actions/actions.master/empSeries.action";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import DeleteModal from "../../../../Constants/DeleteModal";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const ListSeries = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { seriesData, error, loading } = useSelector(
    (state) => state.empSeriesReducer
  );

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [itemId, setItemid] = useState("");
  const [show, setShow] = useState(false);

  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    let updatedSelectedRowIds = [...selectedRowIds];
    if (checked) {
      updatedSelectedRowIds.push(id);
    } else {
      updatedSelectedRowIds = updatedSelectedRowIds.filter(
        (rowId) => rowId !== id
      );
    }
    setSelectedRowIds(updatedSelectedRowIds);
  };
  const handleReset = () => {
    setSelectedRowIds([]);
  };
  const handleShow = (selectedRowIds) => {
    setShow(true);
    setItemid(selectedRowIds);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedRowIds([]);
  };

  const handleDelete = () => {
    dispatch(
      deleteEmpSeries(
        {
          idarray: itemId,
        },
        handleClose
      )
    );
  };

  const columns = [
    {
      field: "checkbox",
      headerName: (
        <Checkbox
          indeterminate={
            selectedRowIds.length > 0 &&
            selectedRowIds.length < seriesData.length
          }
          checked={selectedRowIds.length === seriesData.length}
          onChange={(event) => {
            const checked = event.target.checked;
            const ids = checked ? seriesData.map((row) => row.id) : [];
            setSelectedRowIds(ids);
          }}
        />
      ),
      headerClassName: "datagrid-header",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRowIds.includes(params.row.id)}
          onChange={(event) => handleCheckboxChange(event, params.row.id)}
        />
      ),
    },
    {
      field: "seriesname",
      headerName: "Series Name",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "seriesno",
      headerName: "Current Serial",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "format",
      headerName: "Format",
      headerClassName: "datagrid-header",
      flex: 1,
    },
  ];

  useEffect(() => {
    dispatch(
      getEmpSeries({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);
  return (
    <>
      <Container maxWidth="xl">
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px"}
          marginTop={"20px"}
          borderRadius={"15px"}
        >
          <Typography className="colored-heading">Employee Series</Typography>
          <Divider />

          {selectedRowIds.length > 0 ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant="h6"
                color={"#062C41"}
                fontWeight={"bold"}
                fontSize={"16px"}
                gutterBottom
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                textAlign={"center"}
                gap={"10px"}
                boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                borderRadius={"10px"}
                padding={"5px"}
              >
                {selectedRowIds.length === seriesData.length
                  ? `All ${selectedRowIds.length} Records Selected`
                  : selectedRowIds.length == 1
                  ? `${selectedRowIds.length} Record Selected`
                  : `${selectedRowIds.length} Records Selected`}
                <span>
                  <Tooltip title="Reset Selected" placement="top">
                    <IconButton onClick={handleReset}>
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </span>
                <DeleteModal
                  show={show}
                  handleShow={() => handleShow(selectedRowIds)}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  name="Employee Series"
                />
              </Typography>
            </Box>
          ) : (
            <Box display={"flex"} flexDirection={"row-reverse"} p={1} >
              <AddSeries />
            </Box>
          )}

          <Box
            sx={{
              maxHeight: 350,
              overflow: "auto",
              position: "relative",
              px: 1,
            }}
          >
          <DataGrid
            rows={loading ? [] : seriesData}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={loading}
            columns={columns}
            hideFooterPagination={true}
          />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ListSeries;
