import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Welcome from "./Welcome/Welcome";
import axios from "axios";
import { vlivApi, vlivDomain } from "../../../../../Constants/api";

const Summary = () => {
  const isProduction = process.env.NODE_ENV === "production";
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let userrole = companyInfo.userrole ? companyInfo.userrole : "";
  let userid = companyInfo.userid ? companyInfo.userid : "";
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";

  const [tabvalue, setTabValue] = useState("welcome");
  const [locationData, setLocationData] = useState({
    city: "",
    location: "",
    country: "",
    state: "",
    ip: "",
  });

  // Function to get the user's location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          if (error.message == "User denied Geolocation") {
            getIPDetails();
          }
          // console.error("Error fetching geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  //if user has not allowed location get ip
  const getIPDetails = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json")
      const ipAddress = response.data.ip;
      // Set the IP in the credentials
      setLocationData((prevCred) => ({
        ...prevCred,
        ip: ipAddress
      }));
      const getAddressFromIpDetails = await getLocationFromIpDetails(ipAddress);
      const { latitude, longitude } = getAddressFromIpDetails;
      getAddressFromCoordinates(latitude, longitude);
    }
    catch (error) {
      console.log('Error while fetching Ip details', error);
    }
  }

  //get location from ip
  const getLocationFromIpDetails = async (ip) => {
    try {
      // const response = await axios.get(`http://ip-api.com/json/${ip}`);
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      const { city, region, country, org, latitude, longitude } = response.data;
      const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
      return {
        city,
        regionName: region,
        country,
        device,
        isp: org,
        latitude,
        longitude,
      };
    }
    catch (err) {
      console.log("Error while fetching location details", err)
    }
  }

  // Function to get address from logitude and latitude
  const getAddressFromCoordinates = async (lat, lon) => {
    const API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54"; // Add your Google Maps API key here
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`;

    try {
      const response = await axios.get(url);
      const result = response.data.results[0];
      if (result) {
        const addressComponents = result.address_components;

        let city = "";
        let state = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        // Update the cred object with the location details
        setLocationData((prevCred) => ({
          ...prevCred,
          location: result.formatted_address, // Full address
          city: city,
          state: state,
          country: country
        }));
      } else {
        console.error("No results found for the given coordinates");
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  const loginHistory = async () => {
    try {
      const backendData = {
        email: companyInfo.email ? companyInfo.email : "",
        name: companyInfo.username ? companyInfo.username : "",
        userid: userid,
        companyid: companyid,
        companyemail: companyemail,
        module: "HRMS",
        ...locationData,
      };
      const res = await axios.post(`${isProduction ? vlivDomain : vlivApi}/user/addloginhistory`, backendData);
      // console.log("login history res", res)
      if (res.data.message == "Login History created successfully") {
        companyInfo.loginhistoryid = res.data?.data?.id;
        Cookies.set("userinfo", JSON.stringify(companyInfo),
          {
            domain: isProduction ? '.vliv.app' : 'localhost',
            path: '/',
            secure: isProduction,
            sameSite: isProduction ? 'None' : 'Lax',
            expires: 1
          });
        setLocationData({
          city: "",
          location: "",
          country: "",
          state: "",
          ip: "",
        })
        return;
      } else {
        console.log("Error while creating login history record.")
      }
    }
    catch (error) {
      console.log(`Error while creating login history record ${error}`)
    }
  }

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (locationData.location && !companyInfo.loginhistoryid) {
      loginHistory();
    }
  }, [locationData.location]);

  return (
    <>
      <Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            <TabPanel value="welcome" sx={{ padding: 1 }} >
              <Box sx={{ height: "auto", width: "100%" }}>
                <Welcome />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
