import {
  Box,
  Checkbox,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGeneralValue,
  getGeneralValue,
} from "../../../../redux/actions/actions.master/value.actions";
import Cookies from "js-cookie";
import { DataGrid } from "@mui/x-data-grid";
import AddValues from "./AddValues";
import Loader from "../../../../Constants/Loader";
import DeleteModal from "../../../../Constants/DeleteModal";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const Values = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { valueData, error, loading } = useSelector(
    (state) => state.valueReducer
  );

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [itemId, setItemid] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    id: "",
    category: "",
    subcategory: "",
    value: "",
    code: "",
    companyid: companyInfo.companyid,
    companyemail: companyInfo.companyemail,
  });

  const columns = [
    {
      field: "checkbox",
      headerName: (
        <Checkbox
          indeterminate={
            selectedRowIds.length > 0 &&
            selectedRowIds.length < valueData?.length
          }
          checked={selectedRowIds.length === valueData?.length}
          onChange={(event) => {
            const checked = event.target.checked;
            const ids = checked ? valueData?.map((row) => row.id) : [];
            setSelectedRowIds(ids);
          }}
        />
      ),
      headerClassName: "datagrid-header",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRowIds.includes(params.row.id)}
          onChange={(event) => handleCheckboxChange(event, params.row.id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "subcategory",
      headerName: "Sub-Category",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      headerClassName: "datagrid-header",
      flex: 1,
    },
  ];

  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    let updatedSelectedRowIds = [...selectedRowIds];
    if (checked) {
      updatedSelectedRowIds.push(id);
    } else {
      updatedSelectedRowIds = updatedSelectedRowIds.filter(
        (rowId) => rowId !== id
      );
    }
    setSelectedRowIds(updatedSelectedRowIds);
  };
  const handleReset = () => {
    setSelectedRowIds([]);
  };
  const handleShow = (selectedRowIds) => {
    setShow(true);
    setItemid(selectedRowIds);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedRowIds([]);
  };
  const handleDelete = () => {
    dispatch(
      deleteGeneralValue(
        {
          idarray: itemId,
        },
        handleClose
      )
    );
  };

  useEffect(() => {
    dispatch(
      getGeneralValue({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  // console.log("data", data);
  return (
    <>
      <Container maxWidth="xl">
        <Box
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          padding={"10px"}
          marginTop={"20px"}
          borderRadius={"15px"}
          //width={"50%"}
          height={550}
        >
          <Typography className="colored-heading">Values</Typography>
          <Divider />
          {selectedRowIds.length > 0 ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant="h6"
                color={"#062C41"}
                fontWeight={"bold"}
                fontSize={"16px"}
                gutterBottom
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                textAlign={"center"}
                gap={"10px"}
                boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                borderRadius={"10px"}
                padding={"5px"}
              >
                {selectedRowIds.length === valueData.length
                  ? `All ${selectedRowIds.length} Records Selected`
                  : selectedRowIds.length == 1
                  ? `${selectedRowIds.length} Record Selected`
                  : `${selectedRowIds.length} Records Selected`}
                <span>
                  <Tooltip title="Reset Selected" placement="top">
                    <IconButton onClick={handleReset}>
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </span>
                <DeleteModal
                  show={show}
                  handleShow={() => handleShow(selectedRowIds)}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  name="Values"
                />
              </Typography>
            </Box>
          ) : (
            <Box display={"flex"} justifyContent={"flex-end"} pt={1} px={1}>
              <AddValues />
            </Box>
          )}
          <Box
            sx={{
              maxHeight: 350,
              overflow: "auto",
              position: "relative",
              p: 1,
            }}
          >
            <DataGrid
              rows={loading ? [] : valueData}
              columns={columns}
              hideFooterPagination={true}
              autoHeight
              components={{
                LoadingOverlay: <Loader />,
              }}
              loading={loading}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Values;
