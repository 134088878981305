

import { Avatar, Box, Container, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getEmpReg } from "../../../../redux/actions/actions.hrms/empRegistration.actions";

const boxStyle = {
  width: "350px",
  backgroundColor: "#092C4C",
  padding: "10px 10px",
  margin: "auto",
  color: "white",
  borderRadius: "5px",
  textAlign: "center",
};
const treeContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  overflowX: "auto",
  padding: "20px 0",
  height: "auto",
};

const ORGChart = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const { empData, error, loading } = useSelector(
    (state) => state.empRegistrationReducer
  );
  const containerRef = useRef(null);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.deltaY < 0) {
        setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
      } else {
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
      }
    };

    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel);

    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);

  const ceoData =
    empData.length > 0 && empData.find((emp) => emp.name === emp.reportmanager);

  function stringAvatar2(name) {
    return {
      sx: {
        bgcolor: "grey",
        width: "50px",
        height: "50px",
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Recursively build tree nodes based on the manager-reporting relationship
  const renderTreeNode = (managerName) => {
    const subordinates = empData.filter(
      (emp) => emp.reportmanager === managerName && emp.name !== managerName
    );

    return subordinates.map((employee) => (
      <TreeNode
        key={employee.id}
        label={
          <Box display={"flex"} gap={2} sx={boxStyle}>
            <Avatar {...stringAvatar2(employee.name)} />
            <Box flex={1}>
              <Tooltip title={employee.email} arrow>
                <Typography fontSize={"16px"}>
                {/* {truncateText(employee.name, 11)} */}
                {employee.name}
                </Typography>
              </Tooltip>
              <Tooltip title={employee.role} arrow>
                <Typography fontSize={"12px"}>
                  {/* {truncateText(employee.role, 18)} */}
                  {employee.position}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        }
      >
        {/* Recursively render the subordinates */}
        {renderTreeNode(employee.name)}
      </TreeNode>
    ));
  };

  return (
    <Container maxWidth="xl">
      <Box
        ref={containerRef}
        sx={{
          ...treeContainerStyle,
        }}
      >
        <Box sx={{ transform: `scale(${zoom})`, transformOrigin: "center" }}>
          {ceoData && (
            <Tree
              lineHeight={"30px"}
              lineWidth={"2px"}
              lineColor={"#36bffa"}
              lineStyle={"dashed"}
              lineBorderRadius={"10px"}
              nodePadding={"10px"}
              label={
                <Box sx={boxStyle} display={"flex"} gap={2}>
                  <Avatar {...stringAvatar2(ceoData.name)} />
                  <Box>
                    <Tooltip title={ceoData.email} arrow>
                      <Typography fontSize={"16px"}>
                        {/* {truncateText(ceoData.name, 12)} */}
                        {ceoData.name}
                      </Typography>
                    </Tooltip>
                    <Tooltip title={ceoData.role} arrow>
                      <Typography fontSize={"12px"}>
                        {/* {truncateText(ceoData.role, 12)} */}
                        {ceoData.position}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              }
            >
              {/* Render subordinates of the top manager */}
              {renderTreeNode(ceoData.name)}
            </Tree>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ORGChart;


