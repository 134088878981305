import {
    Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import Loader from "../../../../../../Constants/Loader";
import Cookies from "js-cookie";
import { getSummaryHoliday } from "../../../../../../redux/actions/actions.hrms/summaryHoliday.actions";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../../../Constants/dateFormat";

const Holidays = () => {

    const cookieData = Cookies.get("userinfo");
    let companyInfo = cookieData ? JSON.parse(cookieData) : {};

  
    const dispatch = useDispatch();

    const { holidayData, holiday_error, holiday_loading } = useSelector(
        (state) => state.summaryHolidayReducer
      );

      useEffect(()=>{
        dispatch(
            getSummaryHoliday({
              companyid: companyInfo.companyid,
              companyemail: companyInfo.companyemail,
            })
          );
      },[dispatch])

  return (
    <>
      <Box>
        <TableContainer  sx={{ marginTop: "10px" }}>
          <Table sx={{ minWidth: 100 }} size="small" aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F5F5F5" }}>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holiday_loading ? (
                <Loader />
              ) : (
                holidayData.length > 0 &&
                holidayData
                  .filter((holiday) => holiday.reason == "")
                  .map((holiday) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        backgroundColor:
                          holiday.type == "National"
                            ? "#AFE1AF"
                            : holiday.type == "Festival"
                            ? "lightblue"
                            : "#D3D3D3",
                      }}
                      key={holiday.id}
                    >
                      <TableCell align="center">{holiday.date?formatDate(holiday.date):"-"}</TableCell>
                      <TableCell align="center">{holiday.name}</TableCell>
                      <TableCell align="center">{holiday.type}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Holidays;
