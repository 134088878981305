import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Switch,
  MenuItem,
  Select,
  IconButton,

} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import axios from "axios";

const ApplyLeaveForm = ({
  drawerOpen,
  toggleDrawer,
  isSmallScreen,
  empInfo,
  remainingLeaves,
  setDrawerOpen,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  const [emptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    empname: "",
    empid: "",
    tableemployeeid: "",
    leavetype: "",
    leavebalance: "",
    firsthalf: false,
    secondhalf: false,
    appliedto: "",
    attachfile: "",
    fromdate: "",
    todate: "",
    noofdays: "",
    reason: "",
    ccto: "",
    companyemail: companyemail,
    companyid: companyid,
    date: "",
  });
  const [loading,setLoading]=useState(false);

  const [allEmp, setAllEmp] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleLeaveTypeChange = (e) => {
    const selectedLeaveType = e.target.value;
    const leave = remainingLeaves.find(
      (leave) => leave.leavetype === selectedLeaveType
    );

    if (!leave.remaining || leave.remaining == "0") {
      setAlertType("error");
      setOpen(true);
      setMsg("Can't apply since You have 0 leaves remaining");
      setData((prev) => ({ ...prev, leavetype: "", leavebalance: "" }));
      return;
    }

    setData((prevData) => ({
      ...prevData,
      leavetype: selectedLeaveType,
      leavebalance: leave ? leave.remaining : "",
    }));
  };

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!data.fromdate || !data.todate) {
      setShowEmptyError(true);
      setAlertType("error");
      setOpen(true);
      setMsg("You have empty fields");
      return;
    }

    //   console.log(data, "asgffdgfsgtsgj");

    try {
      setLoading(true);
      const applyingDate = dayjs().format("YYYY-MM-DD");

      let res = await axios.post(
        "https://hrms.vliv.app/attedance/leave/add",
        {
          ...data,
          date: applyingDate,
          hrstatus: "Pending",
          managerstatus: "Pending",
        },
        { withCredentials: true }
      );

      //   console.log(res,"response");
      if (res.data.message == "Leave added successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Leave Request Raised");
        setTimeout(() => {
          setDrawerOpen(false);
          setData((prev) => ({
            ...prev,
            leavetype: "",
            leavebalance: "",
            firsthalf: false,
            secondhalf: false,
            appliedto: "",
            attachfile: "",
            fromdate: "",
            todate: "",
            noofdays: "",
            reason: "",
          }));
        }, 1000);
      }
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      // setTimeout(() => {
      //   setDrawerOpen(false);
      // }, 1000);
      // toggleDrawer(false);
    }finally{
      setLoading(false);
    }
  };

  const getAllEmp = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/get",
        {
          companyid,
          companyemail,
        },
        { withCredentials: true }
      );
      res.data && res.data.length ? setAllEmp(res.data) : setAllEmp([]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      empname: empInfo.name,
      empid: empInfo.empid,
      tableemployeeid: empInfo.id,
      appliedto: empInfo.reportmanager,
    }));
  }, [empInfo]);

  useEffect(() => {
    getAllEmp();
  }, []);

  useEffect(() => {
    calculateNoOfDays();
  }, [data.fromdate, data.todate, data.firsthalf, data.secondhalf]);

  const calculateNoOfDays = () => {
    if (!data.fromdate || !data.todate) {
      setData((prevData) => ({ ...prevData, noofdays: 0 }));
      return;
    }

    const fromDate = dayjs(data.fromdate);
    const toDate = dayjs(data.todate);
    let daysDiff = toDate.diff(fromDate, "day") + 1; // Adding 1 to include the end date

    if (data.firsthalf && data.secondhalf) {
      // Do nothing, daysDiff remains the same
    } else if (data.firsthalf || data.secondhalf) {
      daysDiff = daysDiff / 2;
    }

    setData((prevData) => ({
      ...prevData,
      noofdays: daysDiff,
      firsthalf:daysDiff>1?false:prevData.firsthalf,
      secondhalf:daysDiff>1?false:prevData.secondhalf,
    }));
  };

  // Upload document
  const handleUplaodFile = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    // setData({ ...data, attachfile: e.target.files[0] })

    axios
      .post(
        "https://hrms.vliv.app/attedance/fileattach",
        formData,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // console.log(res.data, "file upload leave");
        setData({ ...data, attachfile: res.data.file });
      })
      .catch((err) => console.log(err));
  };

 

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{
            width: isSmallScreen ? 300 : 500,
            // p: 2,
          }}
          role="presentation"
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={1}
          >
            <Typography variant="h6">Apply Leave</Typography>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box
            px={2}
            py={1}
            className="drawer-form-container"
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="auto"
          >
            <TextField
              label="Employee Name"
              fullWidth
              margin="normal"
              value={data.empname}
              InputProps={{
                readOnly: true,
              }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Leave Type</InputLabel>
              <Select
                name="leavetype"
                value={data.leavetype}
                onChange={handleLeaveTypeChange}
              >
                {remainingLeaves?.map((leave, ind) => (
                  <MenuItem key={ind} value={leave.leavetype}>
                    {leave.leavetype}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Leave Balance"
              fullWidth
              margin="normal"
              value={data.leavebalance}
              InputProps={{
                readOnly: true,
              }}
            />
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="From Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="fromdate"
                  value={data.fromdate}
                  onChange={handleChange}
                  error={emptyError && !data.fromdate}
                  inputProps={{ min: new Date().toISOString().split("T")[0] }} // Set minimum date to today
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="To Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="todate"
                  value={data.todate}
                  onChange={handleChange}
                  error={emptyError && !data.todate}
                  inputProps={{ min: new Date().toISOString().split("T")[0] }} // Set minimum date to today
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
              mt={1}
            >
              <Box
                margin="normal"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Typography>First Half</Typography>
                <Switch
                  disabled={data.noofdays > 1}
                  checked={data.firsthalf}
                  onChange={(e) =>
                    setData({
                      ...data,
                      firsthalf: e.target.checked,
                      secondhalf: e.target.checked ? false : data.secondhalf,
                    })
                  }
                />
              </Box>
              <Box
                margin="normal"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Typography>Second Half</Typography>
                <Switch
                  disabled={data.noofdays > 1}
                  checked={data.secondhalf}
                  onChange={(e) =>
                    setData({
                      ...data,
                      secondhalf: e.target.checked,
                      firsthalf: e.target.checked ? false : data.firsthalf,
                    })
                  }
                />
              </Box>
            </Box>
            <TextField
              label="No of Days"
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              name="noofdays"
              value={data.noofdays}
            />
            <TextField
              label="Attach File"
              fullWidth
              margin="normal"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              name="attachfile"
              onChange={(event) => handleUplaodFile(event)}
            />
            <TextField
              label="Applied To"
              fullWidth
              margin="normal"
              value={data.appliedto}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="Reason"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              name="reason"
              value={data.reason}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>CC To</InputLabel>
              <Select name="ccto" value={data.ccto} onChange={handleChange}>
                {allEmp
                  ?.filter(
                    (emp) =>
                      (emp.role.toLowerCase() === "masteradmin" &&
                        emp.name != data.appliedto) ||
                      (emp.role.toLowerCase() === "manager" &&
                        emp.name != data.appliedto)
                  )
                  .map((em) => (
                    <MenuItem value={em.name} key={em.id}>
                      {em.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                className="cancelBtn"
                variant="outlined"
                onClick={() => setDrawerOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="outlined"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading?"Submitting...":"Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default ApplyLeaveForm;
