import React from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import {
  boxStyle,
  empInfoBox,
  stringAvatar,
  typoHeading,
  typoValue,
} from "../../../../Constants/profileConstants";

const ViewSalary = ({ viewdialogOpen, handleViewClose, selectedRow }) => {
  return (
    <>
      <Dialog
        open={viewdialogOpen}
        onClose={handleViewClose}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent>
          {selectedRow && (
            <Box sx={boxStyle}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={2}
                mb={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" gap={2} alignItems="center">
                  <Avatar {...stringAvatar(selectedRow.name)} />
                  <Box>
                    <Typography sx={typoHeading}>{selectedRow.name}</Typography>
                    <Typography sx={typoValue}>{selectedRow.role}</Typography>
                    <Typography sx={typoValue}>{selectedRow.email}</Typography>
                  </Box>
                </Box>
                <Box sx={empInfoBox}>
                  <Typography sx={typoValue}>Employee ID</Typography>
                  <Typography sx={typoHeading}>
                    {selectedRow.employeeid}
                  </Typography>
                </Box>
                <Box sx={empInfoBox}>
                  <Typography sx={typoValue}>Contact Number</Typography>
                  <Typography sx={typoHeading}>
                    {selectedRow.contactno ? selectedRow.contactno : "--"}
                  </Typography>
                </Box>
                <Box sx={empInfoBox}>
                  <Typography sx={typoValue}>Department</Typography>
                  <Typography sx={typoHeading}>
                    {selectedRow.department}
                  </Typography>
                </Box>
                <Box sx={empInfoBox}>
                  <Typography sx={typoValue}>Employee Type</Typography>
                  <Typography sx={typoHeading}>
                    {selectedRow.emptype}
                  </Typography>
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                  <Avatar {...stringAvatar(selectedRow.reportmanager)} />
                  <Box>
                    <Typography sx={typoValue}>Reporting Manager</Typography>
                    <Typography sx={typoHeading}>
                      {selectedRow.reportmanager}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          )}

          {selectedRow && (
            <Box px={5} my={2}>
              <Grid container display="flex" gap={1} my={1} flexWrap="wrap">
                <Grid item flex={1}>
                  <Typography className="view-page-title" mb={0.4}>
                    CTC in LPA
                  </Typography>
                  <Typography className="view-page-value" mb={0.4}>
                    {selectedRow.ctc || "-"}
                  </Typography>
                </Grid>
                <Grid flex={1}>
                  <Typography className="view-page-title" mb={0.4}>
                    Total Loan
                  </Typography>
                  <Typography className="view-page-value" mb={0.4}>
                    {selectedRow.totalloan || "-"}
                  </Typography>
                </Grid>
                <Grid flex={1}>
                  <Typography className="view-page-title" mb={0.4}>
                    Balance Loan
                  </Typography>
                  <Typography className="view-page-value" mb={0.4}>
                    {selectedRow.balanceloan || "-"}
                  </Typography>
                </Grid>
                <Grid flex={1}></Grid>
                <Grid flex={1}></Grid>
              </Grid>
              <Typography sx={typoHeading}>Earnings</Typography>
              <Grid container spacing={2} my={1}>
                <Grid item xs={2.4}>
                  <Typography className="view-page-title" mb={0.4}>
                    Basic Allowances
                  </Typography>
                  <Typography className="view-page-value" mb={0.4}>
                    {selectedRow.basicpay || "-"}
                  </Typography>
                </Grid>

                {selectedRow.hasOwnProperty("houserentpay") &&
                  selectedRow.houserentpay !== 0 &&
                  selectedRow.houserentpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        House Rent Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.houserentpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("specialpay") &&
                  selectedRow.specialpay !== 0 &&
                  selectedRow.specialpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Special Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.specialpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("conveyancepay") &&
                  selectedRow.conveyancepay !== 0 &&
                  selectedRow.conveyancepay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Conveyance
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.conveyancepay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("otherallowancepay") &&
                  selectedRow.otherallowancepay !== 0 &&
                  selectedRow.otherallowancepay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Other Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.otherallowancepay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("educationpay") &&
                  selectedRow.educationpay !== 0 &&
                  selectedRow.educationpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Education Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.educationpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("travelpay") &&
                  selectedRow.travelpay !== 0 &&
                  selectedRow.travelpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Travel Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.travelpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("internetpay") &&
                  selectedRow.internetpay !== 0 &&
                  selectedRow.internetpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Internet Reimburse
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.internetpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("medicalpay") &&
                  selectedRow.medicalpay !== 0 &&
                  selectedRow.medicalpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Medical Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.medicalpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("vehiclepay") &&
                  selectedRow.vehiclepay !== 0 &&
                  selectedRow.vehiclepay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Vehicle Allowances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.vehiclepay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("insurancepay") &&
                  selectedRow.insurancepay !== 0 &&
                  selectedRow.insurancepay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Insurances
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.insurancepay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("incentive") &&
                  selectedRow.incentive !== 0 &&
                  selectedRow.incentive !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Incentives
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.incentive || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("earning") &&
                  selectedRow.earning !== 0 &&
                  selectedRow.earning !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                         Total Earning
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.earning || "-"}
                      </Typography>
                    </Grid>
                  )}
              </Grid>

              <Typography sx={typoHeading}>Deductions</Typography>
              <Grid container spacing={2} my={1}>
                {selectedRow.hasOwnProperty("pfemployeepay") &&
                  selectedRow.pfemployeepay !== 0 &&
                  selectedRow.pfemployeepay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Employee PF
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.pfemployeepay || "-"}
                      </Typography>
                    </Grid>
                  )}
                {selectedRow.hasOwnProperty("pfemployerpay") &&
                  selectedRow.pfemployerpay !== 0 &&
                  selectedRow.pfemployerpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        PF Employer
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.pfemployerpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("esicemployeepay") &&
                  selectedRow.esicemployeepay !== 0 &&
                  selectedRow.esicemployeepay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        ESIC Employee
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.esicemployeepay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("esicemployerpay") &&
                  selectedRow.esicemployerpay !== 0 &&
                  selectedRow.esicemployerpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        ESIC Employer
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.esicemployerpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("ptaxpay") &&
                  selectedRow.ptaxpay !== 0 &&
                  selectedRow.ptaxpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Professional Tax
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.ptaxpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("tdspay") &&
                  selectedRow.tdspay !== 0 &&
                  selectedRow.tdspay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        TDS
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.tdspay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("lop") &&
                  selectedRow.lop !== 0 &&
                  selectedRow.lop !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        LOP
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.lop || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("empdeduction") &&
                  selectedRow.empdeduction !== 0 &&
                  selectedRow.empdeduction !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Employee Deductions
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.empdeduction || "-"}
                      </Typography>
                    </Grid>
                  )}

                {selectedRow.hasOwnProperty("otherdeductionpay") &&
                  selectedRow.otherdeductionpay !== 0 &&
                  selectedRow.otherdeductionpay !== "0" && (
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Other Deductions
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.otherdeductionpay || "-"}
                      </Typography>
                    </Grid>
                  )}

                {/* {selectedRow.hasOwnProperty("deduction") &&
                  selectedRow.deduction !== 0 &&
                  selectedRow.deduction !== "0" && ( */}
                    <Grid item xs={2.4}>
                      <Typography className="view-page-title" mb={0.4}>
                        Total Deductions
                      </Typography>
                      <Typography className="view-page-value" mb={0.4}>
                        {selectedRow.deduction || "-"}
                      </Typography>
                    </Grid>
                  {/* )} */}
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleViewClose}
            className="cancelBtn"
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewSalary;
