import { CHECKIN_TIME, CHECKOUT_TIME, EMPLOYEE_INFO, TOTAL_HOURS } from "../../actionTypes";

// Initial state
const initialState = {
    employeeData: {},
    checkInTime: "",
    checkOutTime: "",
    totalHours: ""
};

// Reducer function
const employeeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EMPLOYEE_INFO:
            return {
                ...state,
                employeeData: payload
            };

        case CHECKIN_TIME:
            return {
                ...state,
                checkInTime: payload
            };

        case CHECKOUT_TIME:
            return {
                ...state,
                checkOutTime: payload
            };

        case TOTAL_HOURS:
            return {
                ...state,
                totalHours: payload
            };

        default:
            return state;
    }
};

export default employeeReducer;