import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import Script from "react-load-script";
import { googleMapSearchApiKey } from "../../../../Constants/api";
import Loader from "../../../../Constants/Loader";
 // Adjust the path to where your config.js is located

const LocationSearch = ({selectedLocation,setSelectedLocation,setReference,setPlaceId}) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const fetchSuggestions = useCallback(
    debounce((input) => {
      if (!input || !scriptLoaded) return;

      setLoading(true);
      setError(null);

      const service = new window.google.maps.places.AutocompleteService();

      service.getPlacePredictions({ input }, (predictions, status) => {
        setLoading(false);
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setSuggestions(predictions);
        } else {
          setError("Failed to fetch suggestions");
        }
      });
    }, 500),
    [scriptLoaded]
  );

  useEffect(() => {
    if (query && scriptLoaded && query !== selectedLocation) {
      fetchSuggestions(query);
    } else {
      setSuggestions([]);
    }
  }, [query, fetchSuggestions, scriptLoaded, selectedLocation]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (place) => {
    setQuery(place.description);
    setSelectedLocation(place.description);
    setReference(place.reference);
    setPlaceId(place.place_id);
    setSuggestions([]);
  };

  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };

  const handleScriptError = () => {
    setError("Failed to load Google Maps script");
  };

  

  return (
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${googleMapSearchApiKey}&libraries=places`}
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      />

      <TextField
        label="Search Location"
        variant="outlined"
        fullWidth
        value={query}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: loading ? <Loader/> : null,
        }}
      />

      {error && <Typography color="error">{error}</Typography>}

      <List>
        {suggestions.map((place) => (
          <ListItem
            button
            key={place.place_id}
            onClick={() => handleSelect(place)}
          >
            <ListItemText primary={place.description} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default LocationSearch;
