import { Box, Container, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react'
import { BsFillInboxFill } from 'react-icons/bs';
import Loader from '../../Constants/Loader';
import io from 'socket.io-client';

const socket = io(`${process.env.NODE_ENV == "production" ? "https://hrms.vliv.app" : 'https://hrms.vliv.app'}`);

function Notification() {
    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Title', headerName: 'Title', width: 900 },
        { field: 'Date', headerName: 'Date', width: 130 },
    ];


    const rows = [
        // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    ];

    useEffect(() => {
        // Listen for the 'notification' event from the server
        socket.on('notification', (data) => {
            // setNotifications((prevNotifications) => [
            //     ...prevNotifications,
            //     `Notification: ${data.message} for ${data.formData.branch} - ${data.formData.department}`
            // ]);
        });

        return () => {
            socket.off('notification');
        };
    }, []);


    return (
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" className='commonheading'>
                        Notification
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box className="main-parent--container">
                        <div className='notification-empty--container'>
                            <span className='message-avatar'><BsFillInboxFill /></span>
                            <h5 className='common-para'>Looks like you don’t have any notifications yet!</h5>
                            <p className='common-desc'>We’ll share important platform updates and information about your account with you here.</p>
                        </div>
                    </Box>

                    <Box>
                        {/* <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20, rows.length]}
                            autoHeight
                            components={{
                                LoadingOverlay: <Loader />,
                            }}
                            // loading={loading}
                            // onRowClick={handleRowClick}
                            checkboxSelection
                        // disableRowSelectionOnClick
                        // onRowSelectionModelChange={(newSelection) => {
                        //     handleSelectionModelChange(newSelection);
                        // }}
                        /> */}
                    </Box>
                </Grid>

            </Grid>
        </Container>

        
    )
}

export default Notification;