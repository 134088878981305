import { Box, Grid, Stack, TextField, Typography, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react';
import vliv_logo from "../../../assets/vsathiWhitelogo.png";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import axios from 'axios';
import { hrmsDomain, LoginApi } from '../../../Constants/api';
import { useNotification } from '../../../Constants/NotificationAlert';
import { AuthContext } from '../../../Context/AuthContextProvider';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

function Signin() {
    const { notifySuccess, notifyError, notifyWarning, notifyInfo } = useNotification();
    const { authContext, setAuthContext } = useContext(AuthContext);
    const isProduction = process.env.NODE_ENV === "production";
    const navigate = useNavigate();
    // console.log("authContext", authContext)
    //State
    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const [error, setError] = useState({});
    const [showpassword, setShowPassword] = useState(false);
    
    //function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevdata) => ({
            ...prevdata,
            [name]: value
        }))

        setError((prevError) => ({
            ...prevError,
            [name]: ""
        }))
    }

    const validate = () => {
        const newErrors = {};

        // Email validation
        if (!data.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            newErrors.email = "Please enter a valid email";
        }

        // Password validation
        if (!data.password) {
            newErrors.password = "Password is required";
        } else if (data.password.length < 6) {
            newErrors.password = "Password should be at least 6 characters";
        }

        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                // console.log("data", data)
                const res = await axios.post(`${isProduction ? `${hrmsDomain}/user/loginhrms` : LoginApi}`, data);
                // console.log("res", res)
                if (res.data.message == "Login Successful") {
                    notifySuccess("Login Successfull");
                    Cookies.set("companytoken", res.data.token, {
                        domain: isProduction ? ".vliv.app" : "localhost",
                        path: "/",
                        secure: isProduction,
                        sameSite: isProduction ? "None" : "Lax",
                        expires: 1,
                    });
                    setAuthContext({ token: res.data.token, userData: res.data.data });

                    const {
                        password,
                        confirmpassword,
                        yearlypremium,
                        yearlystandard,
                        yearlybasic,
                        premium,
                        standard,
                        basic,
                        addonpack,
                        featureslist,
                        purchaseaddonpack,
                        ...userinfo
                    } = res.data.data;

                    Cookies.set("userinfo", JSON.stringify(userinfo), {
                        domain: isProduction ? ".vliv.app" : "localhost",
                        path: "/",
                        secure: true,
                        sameSite: isProduction ? "None" : "Lax",
                        expires: 1
                    });
                    // let role = res.data.data.userrole ? res.data.data.userrole : "";
                    setTimeout(() => {
                        navigate("/mobile/attendance");
                        // navigate("/mobile/home");
                    }, 500);
                } else {
                    notifyError(res.data.message)
                }
            }
            catch (err) {
                console.log("Error while login", err.response.data.message)
                notifyError(err.response.data.message)
            }


        } else {
            console.log("Validation failed", error);
        }

    }

    return (
        <>
            <Grid container className="signin-container">
                <Grid item xs={12}>
                    <Box mb={2} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} rowGap={2}>
                        <img
                            src={vliv_logo}
                            alt="logo"
                            className="logo_img mobile"
                        />
                        <Typography variant="h5" className="signin-title">HRMS</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4" className="signin-title" mb={2}>Sign in</Typography>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={1}>
                                <Box>
                                    <label htmlFor="email" className='mobileform-label'>Email</label>
                                    <TextField
                                        type="email"
                                        variant="outlined"
                                        className='form-feild'
                                        name="email"
                                        value={data.email}
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: "0.8rem",
                                            }
                                        }}
                                        required
                                        error={!!error.email}
                                        helperText={error.email}
                                    />
                                </Box>
                                <Box>
                                    <Stack position={'relative'}>
                                        <label htmlFor="password" className='mobileform-label'>Password</label>
                                        <TextField
                                            type={showpassword ? 'text' : 'password'}
                                            variant="outlined"
                                            className='form-feild'
                                            name="password"
                                            value={data.password}
                                            onChange={handleChange}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: "0.8rem",
                                                }
                                            }}
                                            required
                                            error={!!error.password}
                                            helperText={error.password}
                                        />
                                        <span className='eyeicon'>{showpassword ? <FaRegEyeSlash onClick={() => setShowPassword(!showpassword)} /> : <IoEyeOutline onClick={() => setShowPassword(!showpassword)} />}</span>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Button type="submit" variant="outlined" fullWidth className='mobile-commonbutton' sx={{ marginTop: "1rem" }}>
                                        Log In
                                    </Button>
                                </Box>
                            </Stack>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Signin