import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { HiDocumentCheck } from "react-icons/hi2";
import { IoDocumentAttachOutline } from "react-icons/io5";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Cookies from "js-cookie";
import {
  formatDate,
  formatDateTime,
  formatTime,
  getColorForStatus,
} from "../../../../../Constants/dateFormat";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  width: "75%",
  height: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "teal",
  },
};

const contentStyle = {
  height: "100%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "teal",
  },
};

const boxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
};

const ViewDetails = ({ open, handleClose, rowId }) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    id: rowId,
    fullname: "",
    position: "",
    phoneno: "",
    contactno: "",
    email: "",
    currentlocation: "",
    company: "",
    workexperience: "",
    currentctc: "",
    expectedctc: "",
    linkedin: "",
    additionalinfo: "",
    cv: "",
    jd: "",
    companyemail: "",
    candidateid: "",
    status: "",
    history: [],
    statushistory: [],
    remarkshistory: [],
    remarks: "",
    companyid: "",
    recruitmentid: "",
    createdAt: "",
    updatedAt: "",
  });

  const fetchData = async (rowId) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "https://hrms.vliv.app/jobapplication/getbyid",
        { id: rowId }
      );
      if (res.data?.data.length) {
        setData((prev) => ({
          ...prev,
          ...res.data.data[0],
        }));
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(rowId);
  }, [rowId]);

  const reversedHistory = data.history ? [...data.history].reverse() : [];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box sx={contentStyle}>
          <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
            <Typography
              color={"#000A14"}
              fontSize={"1.3rem"}
              fontWeight={"500"}
              textAlign={"center"}
            >
              {data.position}
            </Typography>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2 }}>
            <Grid container display={"flex"} gap={3} flexWrap={"wrap"}>
              <Grid className="view-left" item sm={10} xs={10} md={5} lg={5}>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Candidate ID{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.candidateid || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Name</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.fullname || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Email</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.email || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Applied For
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.position || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.phoneno || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Emeregency Number
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.contactno || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Current Location
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.currentlocation || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">UG</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">{"-"}</Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">PG</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">{"-"}</Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">PAN</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">{"-"}</Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">UAN</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">{"-"}</Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Aadhar</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">{"-"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="view-right" item sm={10} xs={10} md={5} lg={5}>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Experience
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.workexperience || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Last Company
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.company || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Current CTC
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.currentctc || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      Expected CTC
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.expectedctc || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">
                      LinkedIn
                    </Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.linkedin ? (
                        <a
                          href={data.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">Resume</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.cv ? (
                        <a
                          href={data.cv}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IoDocumentAttachOutline
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1.5}>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-title">JD</Typography>
                  </Grid>
                  <Grid item md={6} sx={boxStyle}>
                    <Typography className="view-page-value">
                      {data.jd ? (
                        <a
                          href={data.jd}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiDocumentCheck
                            className="primarycolor"
                            style={{ fontSize: "20px" }}
                          />
                        </a>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {data.score && Object.keys(data.score).length > 0 && (
              <Box sx={{ backgroundColor: "#F4FAFF" }} py={2}>
                <Typography style={{ fontSize: "large", margin: "5px 0px" }}>
                  Resume Feedback
                </Typography>
                <Grid container sm={12} md={12} xs={12} lg={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#E4E7EC" }}>
                          <TableCell width={"30%"}>Category</TableCell>
                          <TableCell>Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.score.matchpercentage && (
                          <TableRow>
                            <TableCell width={"30%"}>
                              Match Percentage
                            </TableCell>
                            <TableCell>{data.score.matchpercentage}</TableCell>
                          </TableRow>
                        )}
                        {data.score.keywordmissing && (
                          <TableRow>
                            <TableCell width={"30%"}>Keyword Missing</TableCell>
                            <TableCell>{data.score.keywordmissing}</TableCell>
                          </TableRow>
                        )}
                        {data.score.finalthoughts && (
                          <TableRow>
                            <TableCell width={"30%"}>Final Thoughts</TableCell>
                            <TableCell>{data.score.finalthoughts}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            )}

            <Box>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                {reversedHistory.length > 0 && (
                  <Typography
                    // className="primarycolor"
                    style={{ fontSize: "large", margin: "5px 0px" }}
                  >
                    Interview Details
                  </Typography>
                )}

                {/*  */}
              </Box>
              <Grid container gap={1}>
                {reversedHistory?.map((interview, ind) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3.9}
                    key={ind}
                    px={2}
                    className="application-status-box"
                  >
                    <Box
                      display={"flex"}
                      // alignItems={"center"}
                      // justifyContent={"space-between"}
                      flexDirection={"column"}
                      my={1}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography className="view-page-value">
                          {interview.interviewname}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {interview.offerletterstatus ? (
                            <Button
                              sx={{
                                color: getColorForStatus(
                                  interview.offerletterstatus
                                ),
                                textTransform: "capitalize",
                              }}
                            >
                              {interview.offerletterstatus}
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                color: getColorForStatus(
                                  interview.interviewstatus
                                ),
                                textTransform: "capitalize",
                              }}
                            >
                              {interview.interviewstatus}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Type
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.interviewtype}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Interviewer
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.interviewername || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Date & Time
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.scheduleddate
                            ? formatDateTime(interview.scheduleddate)
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" mb={0.5}>
                      <Grid item md={6}>
                        <Typography className="view-page-title">
                          Remarks
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography className="view-page-value">
                          {interview.remarks || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewDetails;
