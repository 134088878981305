
import axios from "axios";
import { CHECKIN_TIME, CHECKOUT_TIME, EMPLOYEE_INFO, TOTAL_HOURS } from "../../actionTypes";

export const employeeinfo = (backendData) => async (dispatch) => {
    try {
        const res = await axios.post(`https://hrms.vliv.app/employee/get`,
            {
                companyid: backendData.companyid,
                companyemail: backendData.companyemail
            }, { withCredentials: true });
    
        if (res.data && res.data.length > 0) {
            // console.log(backendData)
            let data = res.data.find((el) => el.email == backendData.email);
            dispatch({ type: EMPLOYEE_INFO, payload: data })
        }
        else {
            dispatch({ type: EMPLOYEE_INFO, payload: {} })
        }
    }
    catch (error) {
        console.log(`Error while fetching employee info ${error}`)
    }
}

export const getTodaysAttendance = (backendData) => async (dispatch) => {
    try {
        let res = await axios.post(
            "https://hrms.vliv.app/atte/getbyemployee",
            {
                companyid: backendData.companyid,
                companyemail: backendData.companyemail,
                employeeid: backendData.employeeid,
                date: backendData.date,
            },
            { withCredentials: true }
        );
        // console.log("res", res)
        if (res.data && res.data.data && res.data.data.length) {
            dispatch({ type: CHECKIN_TIME, payload: res.data.data[0].checkin });
            dispatch({ type: CHECKOUT_TIME, payload: res.data.data[0].checkout })
            dispatch({ type: TOTAL_HOURS, payload: res.data.data[0].totalhours });
            // setCheckInTime(res.data.data[0].checkin);
            // setCheckOutTime(res.data.data[0].checkout);
            // setTotalHr(res.data.data[0].totalhours);
        } else {
            // setCheckInTime("");
            // setCheckOutTime("");
            // setTotalHr("");
            dispatch({ type: CHECKIN_TIME, payload: "" });
            dispatch({ type: CHECKOUT_TIME, payload: "" });
            dispatch({ type: TOTAL_HOURS, payload: "" });
        }
    } catch (error) {
        console.log(error, "error");
    }
};