import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Chip,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import { getChipColor } from "../../../../Constants/Colors";
import {
  addTaskApi,
  getAllTagsApi,
  hrmsEmployeeRegistrationGet,
} from "../../../../Constants/api";

const AddTask = ({ drawerOpen, handleDrawerClose, isSmallScreen }) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let useremail = userData.email ? userData.email : "";

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [allEmp, setAllEmp] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [data, setData] = useState({
    taskname: "",
    assigneeidarray: [],
    useremail: useremail,
    tag: [],
    status: "Pending",
    priority: "Low",
    duedate: "",
    description: "",
    attach: "",
    companyemail,
    companyid,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleTagChange = (event, newValue) => {
    setData({ ...data, tag: newValue });
  };

  const handleAssigneeChange = (event, newValue) => {
    setData({
      ...data,
      assigneeidarray: newValue.map((emp) => ({
        ...{ id: emp.empid, name: emp.name, empemail: emp.email },
        display: `${emp.empid}-${emp.name}`,
      })),
    });
  };

  const handleSave = async () => {
    // console.log(data, "data");

    try {
      setLoading(true);
      let res = await axios.post(addTaskApi, data, { withCredentials: true });
      // console.log(res, "response");
      if (res.data.message) {
        setAlertType("success");
        setOpen(true);
        setMsg("Task Created Successfully");
        setTimeout(() => {
          handleDrawerClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
      setTimeout(() => {
        handleDrawerClose();
      }, 1000);
    } finally {
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getAllEmp = async () => {
    try {
      let res = await axios.post(
        hrmsEmployeeRegistrationGet,
        {
          companyemail,
          companyid,
        },
        { withCredentials: true }
      );
      res.data
        ? setAllEmp(res.data.filter((obj) => obj.position != "Owner"))
        : setAllEmp([]);
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error in fetching Employee");
    }
  };

  const getAllTags = async () => {
    try {
      let res = await axios.post(
        getAllTagsApi,
        { companyemail, companyid },
        { withCredentials: true }
      );
      res.data.data ? setAllTags(res.data.data) : setAllTags([]);
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error in fetching Tags");
    }
  };

  useEffect(() => {
    getAllEmp();
    getAllTags();
  }, []);

  return (
    <>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          width={isSmallScreen ? "90vw" : 600}
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={1}
          >
            <Typography variant="h6">Add Task</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box
            px={2}
            py={1}
            className="drawer-form-container"
            flex={1}
            display="flex"
            flexDirection="column"
            overflow="auto"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Task Name"
                  name="taskname"
                  value={data.taskname}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  error={showEmptyError && !data.taskname}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={allEmp}
                  getOptionLabel={(option) => `${option.empid}-${option.name}`}
                  value={data.assignee}
                  onChange={handleAssigneeChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={`${option.empid}-${option.name}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Assignee"
                      placeholder="Select Assignee"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={allTags.filter((tag) => !data.tag.includes(tag))}
                  value={data.tag}
                  onChange={handleTagChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        style={{
                          backgroundColor: getChipColor(option),
                          color: "#fff",
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Tag"
                      placeholder="Select or add a tag"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Priority</InputLabel>
                  <Select
                    name="priority"
                    value={data.priority}
                    onChange={handleChange}
                    label="Priority"
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Due Date"
                  name="duedate"
                  type="date"
                  value={data.duedate}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: new Date().toISOString().split("T")[0] }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={2}
                flexWrap="wrap"
              >
                <Button
                  onClick={handleDrawerClose}
                  className="cancelBtn"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button className="saveBtn" onClick={handleSave} disabled={loading}>
                  {
                    loading?"Submitting...":"Submit"
                  }
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AddTask;
