import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Drawer,
  styled,
  InputAdornment,
  Avatar,
} from "@mui/material";

import Cookies from "js-cookie";
import { BsFillSendFill } from "react-icons/bs";
import vlivLogo from "../../../../../../assets/venturesathi-logo.png";
import { stringAvatar } from "../../../../../../Constants/StringAvatar";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { deepPurple } from "@mui/material/colors";

const Chatbot = ({ drawerOpen, setDrawerOpen, isSmallScreen }) => {

  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let username = userData.username ? userData.username : "";
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedMessages =
      JSON.parse(localStorage.getItem("chatMessages")) || [];
    const now = new Date().getTime();
    const validMessages = savedMessages.filter(
      (msg) => now - msg.timestamp < 6 * 60 * 60 * 1000
    );
    setMessages(validMessages);
  }, []);

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  const handleSend = async () => {
    if (!userInput.trim()) return;

    const newMessage = {
      text: userInput,
      sender: "user",
      timestamp: new Date().getTime(),
    };
    setMessages([...messages, newMessage]);
    setUserInput("");
    setLoading(true);

    try {
      const response = await fetch("https://helpdesk-lh06.onrender.com/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_question: userInput }),
      });
      const data = await response.json();
      const botMessage = {
        text: data.response,
        sender: "bot",
        timestamp: new Date().getTime(),
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Define the dot component with animation
  const Dot = styled(Box)(({ theme }) => ({
    width: "8px",
    height: "8px",
    margin: "0 2px",
    backgroundColor: "#3f51b5",
    borderRadius: "50%",
    display: "inline-block",
    animation: "dotFlashing 1s infinite linear alternate",
    "&:nth-of-type(1)": {
      animationDelay: "0s",
    },
    "&:nth-of-type(2)": {
      animationDelay: "0.2s",
    },
    "&:nth-of-type(3)": {
      animationDelay: "0.4s",
    },
  }));

  // Define the keyframes for the animation
  const globalStyles = `
@keyframes dotFlashing {
  0% {
    opacity: 0.3;
  }
  50%,
  100% {
    opacity: 1;
  }
}
`;

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={isSmallScreen ? "90vw" : 600}
          height="100vh"
        >
          <Box
            p={0.7}
            flexShrink={0}
            borderBottom={"1px solid #E0E0E0"}
            display={"flex"}
            gap={7}
            alignItems={"center"}
            mb={1}
          >
            <img src={vlivLogo} alt="vliv_logo" height={"70%"} width={"15%"} />
            <Typography variant="h6" color={"#37668F"}>
              Help Desk
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              padding: 2,
              marginBottom: 2,
            }}
          >
            {messages.map((msg, index) => (
              <Box

                key={index}
                display={"flex"}
                gap={0.5}
                sx={{
                  marginBottom: 2,
                  textAlign: msg.sender === "user" ? "right" : "left",
                  justifyContent:msg.sender=="user"?"flex-end":"flex-start",
                  flexDirection:msg.sender=="user"?"row":"row-reverse",
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    display: "inline-block",
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor:
                      msg.sender === "user" ? "#cfe8fc" : "#e0e0e0",
                  }}
                >
                  {msg.text}
                </Typography>
                {msg.sender == "user" ? (
                  <Avatar {...stringAvatar(username)} />
                ) : (
                  <Avatar sx={{ bgcolor: deepPurple[500], width: 21, height: 21,fontSize:11 }}><TfiHeadphoneAlt/></Avatar>
                )}
              </Box>
            ))}
            {loading && (
              <Box

              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <style>{globalStyles}</style>
                  <Dot />
                  <Dot />
                  <Dot />
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", flexShrink: 0, p: 0.7 }}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSend();
              }}
              placeholder="Type your message"
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="adornment">
                    <IconButton color="primary" onClick={handleSend}>
                      <BsFillSendFill />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Chatbot;
