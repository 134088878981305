import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  IconButton
} from "@mui/material";
import { FaRegFilePowerpoint } from "react-icons/fa6";
import Cookies from "js-cookie";
import axios from "axios";
import Loader from "../../../../Constants/Loader";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import {
  getAttendanceRequestApi,
  updateAttendanceReqStatusApi,
} from "../../../../Constants/api";
import { DataGrid } from "@mui/x-data-grid";
import { getPayrollStatusColor } from "../../../../Constants/Colors";

// Custom cell renderer for the Status field
const StatusCellRenderer = ({ value, row, onStatusChange }) => {
  const handleChange = async (event) => {
    const newValue = event.target.value;
    onStatusChange({ ...row, status: newValue });
  };

  const isDisabled = value === "Approved" || value === "Reject";
  const statusValue = value || "Requested";

  return (
    <Select
      value={statusValue}
      onChange={handleChange}
      disabled={isDisabled}
      displayEmpty
      fullWidth
      sx={{
        border: "none",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        color: `${getPayrollStatusColor(statusValue)}.main`,
      }}
    >
      <MenuItem value="Requested" sx={{ color: "info.main" }}>
        Requested
      </MenuItem>
      <MenuItem value="Approved" sx={{ color: "success.main" }}>
        Approved
      </MenuItem>
      <MenuItem value="Rejected" sx={{ color: "warning.main" }}>
        Rejected
      </MenuItem>
    </Select>
  );
};

const ApproveAttendances = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //for notify alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await axios.post(getAttendanceRequestApi, {
        companyid,
        companyemail,
      },{withCredentials:true});
      // console.log(res, "res");
      if (res.data && res.data.data && res.data.data.length > 0) {
        setData(res.data.data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error while fetching tasks..");
      setLoading(false);
    }
  };

  const handleJDClick = (row) => {
    // console.log(row, "clicked row");
  
    // Ensure the URL is properly formatted
    let payslipURL = row.attachment;
    if (!payslipURL.startsWith("http://") && !payslipURL.startsWith("https://")) {
      // Assuming the relative URL should be prefixed with "https://"
      payslipURL = "https://" + payslipURL;
    }
  
    // Create a temporary link element for downloading
    const link = document.createElement("a");
    link.href = payslipURL;
    link.download = payslipURL.split("/").pop() || "Attachment.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateStatus = async (row) => {
    try {
      const res = await axios.post(updateAttendanceReqStatusApi, row,{withCredentials:true});
      if (res.status === 200) {
        setAlertType("success");
        setOpen(true);
        setMsg("Status Changed..");
        getData();
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { field: "employeeid", headerName: "Employee ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "reportingto", headerName: "Report Manager", flex: 1 },
    { field: "day", headerName: "Day", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "requesttype", headerName: "Request Type", flex: 1 },
    { field: "checkin", headerName: "Checkin Time", flex: 1 },
    { field: "checkout", headerName: "Checkout Time", flex: 1 },
    { field: "totalhours", headerName: "Total Hours", flex: 1 },
    { field: "reason", headerName: "Reason", flex: 1.5 },
    {
      field: 'attachment',
      headerName: 'Attachment',
      flex: 1,
      renderCell: (params) =>
        params.row.attachment ? (
          <IconButton
            onClick={(event) => {
              event.stopPropagation(); // Stop event propagation to prevent row click event
              handleJDClick(params.row);
            }}
          >
            <FaRegFilePowerpoint />
          </IconButton>
        ) : null,
      cellClassName: 'MuiDataGrid-cell--withRenderer',
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      renderCell: (params) => (
        <StatusCellRenderer
          value={params.value}
          row={params.row}
          onStatusChange={updateStatus}
        />
      ),
    },
  ];

  const theme = useTheme();


  return (
    <>
      <Box px={1} py={2}>
        <Box my={1}>
          <Paper style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={loading ? [] : data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 10, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20, 30, 40, data.length]}
              autoHeight
              components={{
                LoadingOverlay: <Loader />,
              }}
              loading={loading}
            />
          </Paper>
        </Box>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default ApproveAttendances;
