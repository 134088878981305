import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";

import {  Box,  Stack, TextField, Typography,IconButton, InputAdornment, AccordionSummary, Accordion, AccordionDetails, Tooltip } from "@mui/material";
import Loader from "../../../../../../Constants/Loader";

import AlertPopUp from "../../../../../../Constants/AlertPopUp";
import { addSummaryBirthday, deleteSummaryBirthday, getSummaryBirthday, updateSummaryBirthday } from "../../../../../../redux/actions/actions.hrms/summaryBirthday.actions";
import { getEmpReg } from "../../../../../../redux/actions/actions.hrms/empRegistration.actions";


import EditIcon from "@mui/icons-material/Edit";
import { IoSaveOutline } from "react-icons/io5";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { truncateText } from "../../../../../../Constants/constants";


const BirthDays = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let userrole = companyInfo.userrole ? companyInfo.userrole : "";
  let userid = companyInfo.userid ? companyInfo.userid : "";
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";

  const dispatch = useDispatch();
  const { empData } = useSelector((state) => state.empRegistrationReducer);

  const { birthdayData, birthday_error, birthday_loading } = useSelector(
    (state) => state.summaryBirthdayReducer
  );

  const [itemID, setItemID] = useState("");
  const [commentID, setCommentID] = useState("");
  const [comments, setComments] = useState({});
  const [cmnt, setCmnt] = useState("");
  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCommentChange = (e, empId) => {
    const { value } = e.target;
    setComments({ ...comments, [empId]: value });
  };

  const handleAddComments = (id, name) => {
    dispatch(
      addSummaryBirthday(
        {
          empid: id,
          empname: name,
          message: comments[id],
          userid: companyInfo.userid,
          username: companyInfo.username,
          companyid: companyInfo.companyid,
          companyemail: companyInfo.companyemail,
        },
        setOpen,
        setMsg,
        setAlertType
      )
    );
    setComments({ ...comments, [id]: "" });
  };
  const handleOpenComment = (id) => {
    // console.log(id, "comment");
    setCommentID(id);
    if (commentID === id) {
      // If the same comment is clicked again, close the editor
      setCommentID("");
    } else {
      setCommentID(id);
    }
  };
  const handleCommentEdit = () => {
    const selectedData = birthdayData.find(
      (birthday) => birthday.id == commentID
    );
    dispatch(updateSummaryBirthday({ ...selectedData, message: cmnt }));
    setCmnt("");
    setCommentID("");
  };
  const handleDeleteComment = (id) => {
    dispatch(deleteSummaryBirthday({ idarray: [id] }));
  };

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
    dispatch(
      getSummaryBirthday({
        companyid: companyid,
        companyemail: companyemail,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Box>
        <Stack>
          {empData.length > 0 &&
            empData
              .filter((emp) => {
                const dob = new Date(emp.dob);
                const today = new Date();
                return (
                  dob.getMonth() === today.getMonth() &&
                  dob.getDate() >= today.getDate()
                );
              })
              .sort((a, b) => {
                const dayA = new Date(a.dob).getDate();
                const dayB = new Date(b.dob).getDate();
                return dayA - dayB;
              })
              .map((ele, i) => {
                const dob = new Date(ele.dob);
                const today = new Date();
                const isToday =
                  dob.getDate() === today.getDate() &&
                  dob.getMonth() === today.getMonth();
                return (
                  <Box
                    boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                    padding={"10px"}
                    key={i}
                    marginBottom={"10px"}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: isToday ? "24px" : "inherit",
                      }}
                    >
                      {isToday
                        ? `Happy Birthday ${ele.name}`
                        : `Upcoming Birthday of ${ele.name}`}
                    </Typography>
                    <br />
                    <p>{!isToday && ele.dob}</p>
                    {isToday && (
                      <Box>
                        <Typography variant="h6">Send your wishes:</Typography>
                        <TextField
                          className="formInputs"
                          fullWidth
                          variant="outlined"
                          placeholder="Write your message"
                          margin="normal"
                          name="message"
                          value={comments[ele.id] || ""}
                          onChange={(e) => handleCommentChange(e, ele.id)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{
                                    color: "var(--common-color)",
                                  }}
                                  onClick={() =>
                                    handleAddComments(ele.id, ele.name)
                                  }
                                >
                                  <SendIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            sx={{
                              backgroundColor: "#b2d8d8",
                              border: "none",
                              borderRadius: "6px",
                            }}
                          >
                            <Typography>Wishes</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {birthday_loading ? (
                              <Loader />
                            ) : (
                              birthdayData.length > 0 &&
                              birthdayData
                                .filter((bth) => bth.empid === ele.id)
                                .map((comnt) => (
                                  <Typography
                                    key={comnt.id}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                  >
                                    <Tooltip title={comnt.role} arrow>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {truncateText(comnt.username, 18)}
                                      </span>
                                    </Tooltip>

                                    {commentID === comnt.id ? (
                                      <TextField
                                        fullWidth
                                        className="formInputs"
                                        value={cmnt}
                                        onChange={(e) =>
                                          setCmnt(e.target.value)
                                        }
                                      />
                                    ) : (
                                      <Tooltip title={comnt.message} arrow>
                                        <span>
                                          {truncateText(comnt.message, 18)}
                                        </span>
                                      </Tooltip>
                                    )}

                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "5px",
                                      }}
                                    >
                                      {commentID === comnt.id &&
                                      comnt.userid === userid ? (
                                        <IconButton
                                          onClick={handleCommentEdit}
                                          color="primary"
                                        >
                                          <IoSaveOutline />
                                        </IconButton>
                                      ) : (
                                        comnt.userid === userid && (
                                          <IconButton
                                            onClick={() =>
                                              handleOpenComment(comnt.id)
                                            }
                                            color="primary"
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        )
                                      )}
                                      {comnt.userid === userid && (
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteComment(comnt.id)
                                          }
                                          color="error"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </span>
                                  </Typography>
                                ))
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                  </Box>
                );
              })}
        </Stack>
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default BirthDays;
