import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { mobileadminroutes, mobileroutes } from '../../../Constants/routes';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../../Context/AuthContextProvider';
import { useContext } from 'react';
import { useNotification } from '../../../Constants/NotificationAlert';
import { Avatar, Stack, Tab, Tabs } from '@mui/material';
import noprofileimage from "../../../assets/No-image.webp";
import { RxCrossCircled } from "react-icons/rx";
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel/CustomTabPanel';
import UserRoute from './UserRoute';
import { useDispatch } from 'react-redux';
import { reloadPage } from '../../../redux/actions/action.mobile/load.action';
import { IoReloadOutline } from "react-icons/io5";

const drawerWidth = 240;
const headerHeight = 60;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    height: `calc(100vh - ${headerHeight}px)`,
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  height: headerHeight,
  padding: theme.spacing(1),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function MobileLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authContext, setAuthContext } = useContext(AuthContext);
  const { userrole, username, clientimage } = authContext.userData;
  const dispatch = useDispatch();
  // console.log("authContext", authContext)
  //state
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [currentpage, setCurrentPage] = useState('Home');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (name) => {
    setOpen(false);
    setCurrentPage(name)
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProfile = () => {
    navigate("/mobile/profile");
    handleDrawerClose("Profile");
  }

  const handleload = () => {
    dispatch(reloadPage(true));
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <AppBar position="sticky" open={open} sx={{ backgroundColor: 'var(--brand-color)', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {currentpage}
          </Typography>
        </Toolbar>

        <Box>
          <IconButton
            color="inherit"
            onClick={handleload}
            edge="start"
            sx={[
              { fontSize: "1.3rem" },
              open && { display: 'none' },
            ]}
            padding={0}
          >
            <IoReloadOutline />
          </IconButton>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'var(--common-bg-color)'
          },
          borderTopRightRadius: "1rem"
        }}
        // variant="persistent"
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => handleDrawerClose(currentpage)}
      >
        <DrawerHeader sx={{ justifyContent: "space-between" }} >
          <Stack direction="row" alignItems={"center"} spacing={1} onClick={handleProfile}>
            <Avatar alt="Remy Sharp" src={!clientimage ? noprofileimage : clientimage} />
            <Typography variant="subtitle2" color="GrayText">{!username ? "N/A" : username}</Typography>
          </Stack>
          <Stack>
            <IconButton onClick={() => handleDrawerClose(currentpage)}>
              {theme.direction === 'ltr' ? <RxCrossCircled /> : <ChevronRightIcon />}
            </IconButton>
          </Stack>
        </DrawerHeader>
        <Divider />
        {userrole?.toLowerCase() != "user" && <div className='tabs-section'>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs-section'>
            <Tab label="User" {...a11yProps(1)} className='tab-button' />
            <Tab label={!userrole ? "N/A" : userrole} {...a11yProps(0)} className='tab-button' />
          </Tabs>
          <CustomTabPanel value={value} index={0}  >
            <UserRoute routeToRender={mobileroutes} handleDrawerClose={handleDrawerClose} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UserRoute routeToRender={mobileadminroutes} handleDrawerClose={handleDrawerClose} />
          </CustomTabPanel>
        </div>}
        {userrole?.toLowerCase() === "user" &&
          <UserRoute routeToRender={mobileroutes} handleDrawerClose={handleDrawerClose} />
        }
      </Drawer>
      <Main open={open}>
        <Box>
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
}