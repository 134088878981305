import logo from "./logo.svg";
import {
  Box,
  Button,
  Container,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { routes1, otherroutes, userroute } from "./Constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Components/Navigations/Navbar";
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Components/Navigations/Sidebar";
import AllRoutes from "./routes/AllRoutes";
import JobApplications from "./Components/HRM/RecruitManagement/JobApplications/JobApplications";
import OnBoardDocumentUpload from "./Components/HRM/RecruitManagement/OnBoardingprocess/OnBoardDocumentUpload";
import Position from "./Components/Masters/Employees/Position/Position";
import SignIn from "./Components/Auth/SignIn";
import Cookies from "js-cookie";
import { AuthContext } from "./Context/AuthContextProvider";
import axios from "axios";
import Signin from "./Components/MobileComponents/Auth/Signin";
import Home from "./Components/MobileComponents/Home/Home";
import MobileLayout from "./Components/MobileComponents/MobileLayout/MobileLayout";

function App() {


  const { authContext, setAuthContext } = useContext(AuthContext);

  const cookieData = Cookies.get("userinfo");
  // let userData = cookieData ? JSON.parse(cookieData) : {};
  // let companyid = userData.companyid ? userData.companyid : "";
  // let companyemail = userData.companyemail ? userData.companyemail : "";
  // let email = userData.email ? userData.email : "";
  // let userRole = userData.userrole ? userData.userrole : "";

  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const currentPath = location.pathname;

  const [theme, setTheme] = useState(false); // State for theme
  const [selectedRoute, setSelectedRoute] = useState("");

  const [showButtons, setShowButtons] = React.useState(true);


  const toggleTheme = () => {
    setTheme((prevTheme) => !prevTheme); // Function to toggle theme
  };
  const darkTheme = createTheme({
    palette: {
      mode: theme ? "dark" : "light",
    },
  });

  const isActive = (path) => {
    return location.pathname === path;
  };

  // if (currentPath == "/hrm/task&events/events") {
  //   if (userRole == "User") {
  //     alert("Access denied");
  //     navigate("/hrm/task&events/tasks");
  //   }
  // }

  if (currentPath == "/hrm") {
    if (authContext && authContext.userData && authContext.userData.userrole && authContext.userData.userrole == "User") {
      navigate("/hrm/employeeselfservice/summary");
    } else {
      navigate("/hrm/summary/welcome");
    }
  }

  if (currentPath == "/settings" && authContext && authContext.userData && authContext.userData.userrole && authContext.userData.userrole != "Manager") {
    navigate("/settings/general/invoicepaysliplogo");
  } else if (currentPath == "/settings" && authContext && authContext.userData && authContext.userData.userrole && authContext.userData.userrole == "Manager") {
    navigate("/settings/performance/organizationKpi");
  }

  // const renderRoute = userRole == "User" ? userroute : routes1;

  let renderRoute;

  if (authContext && authContext.userData && authContext.userData.userrole && authContext.userData.userrole == "User") {
    renderRoute = userroute;
  } else if (authContext && authContext.userData && authContext.userData.userrole && authContext.userData.userrole == "Manager") {
    const pathsToRemove = ["/hrm/payroll", "/hrm/report"];
    renderRoute = routes1.map(route => {
      if (route.subRoutes) {
        return {
          ...route,
          subRoutes: route.subRoutes.filter(subRoute => !pathsToRemove.includes(subRoute.path))
        };
      }
      return route;
    });
  } else {
    renderRoute = routes1
  }

  useEffect(() => {
    if (currentPath == "/") {
      if (authContext && authContext.userData && authContext.userData.userrole && authContext.userData.userrole == "User") {
        navigate("/hrm/employeeselfservice/summary");
      } else {
        navigate("/hrm/summary/welcome");
      }
    }
  }, []);


  //preventing scroll behaviour for numeric inputs
  useEffect(() => {
    // Prevent negative values and scroll behavior for all number inputs
    const handleWheel = (event) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    const handleKeyPress = (event) => {
      const input = event.target;
      if (input.type === 'number' && input.value && event.key === '-') {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: true });
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const fetchUserData = async () => {
    try {
      let res = await axios.post("https://hrms.vliv.app/user/userdetails", {}, { withCredentials: true });
      if (res.data.token) {
        setAuthContext({ ...res.data })
      }
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [])

  // console.log(authContext,"auth context...")

  return (
    <>
      {/* <ThemeProvider theme={darkTheme}> */}
      {currentPath === "/jobapplications" ? (
        <JobApplications />
      ) : currentPath === "/onboarddocuments-upload" ? (
        <OnBoardDocumentUpload />
      ) : currentPath === "/signin" ? (
        <SignIn />
      ) : currentPath.startsWith("/mobile") ? (
        <AllRoutes />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Navbar theme={theme} toggleTheme={toggleTheme} />

          <Box
            sx={{
              flex: 1,
              display: "flex",
              marginTop: "60px",
              transition: "width 0.3s ease-in-out",
            }}
          >
            <Box sx={{ width: "auto" }}>
              <Sidebar setSelectedRoute={setSelectedRoute} showButtons={showButtons} setShowButtons={setShowButtons} />
            </Box>
            {/* main content */}
            <Box
              sx={{
                flex: 1,
                marginTop: "10px",
                marginLeft: showButtons ? "85px" : "50px",
                boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
                overflowY: "auto",

              }}
            >
              {currentPath !== "/" && (
                <Box className="second-nav">
                  {[...renderRoute]
                    .filter((route, ind) => currentPath.includes(route.path))
                    .map((route, ind) =>
                      route.subRoutes.map((subroute, ind) => (
                        <Button
                          key={ind}
                          startIcon={subroute.iconComponent}
                          className={
                            currentPath.includes(subroute.path)
                              ? "active-nav-btn"
                              : "second-nav-btn"
                          }
                          onClick={() => {
                            navigate(subroute.subRoutes[0].path);
                          }}
                        >
                          {subroute.name}
                        </Button>
                      ))
                    )}
                </Box>
              )}
              {currentPath && currentPath !== "/" ? (
                <Box className="second-nav third-nav">
                  {renderRoute?.map((route, ind) =>
                    route.subRoutes
                      ?.filter((subRoute) =>
                        currentPath.includes(subRoute.path)
                      )
                      .map((subRoute, ind) =>
                        subRoute.subRoutes.map((nestedRoute, ind) => (
                          <Button
                            key={ind}
                            onClick={() => {
                              navigate(nestedRoute.path);
                            }}
                            className={
                              isActive(nestedRoute.path)
                                ? "thirdnav-active-btn"
                                : "second-nav-btn"
                            }
                          >
                            {nestedRoute.name}
                          </Button>
                        ))
                      )
                  )}
                </Box>
              ) : null}
              <Box>
                <AllRoutes />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
