import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import Cookies from "js-cookie";
// import { useNotification } from '../../../Constants/NotificationAlert';
// import { TbLogout2 } from "react-icons/tb";

function UserRoute({ routeToRender, handleDrawerClose }) {
    // const isProduction = process.env.NODE_ENV === "production";
    // const navigate = useNavigate();
    // const { notifySuccess, notifyError, notifyWarning, notifyInfo } = useNotification();

    // const handleLogout = () => {
    //     Cookies.remove("companytoken", {
    //         domain: isProduction ? ".vliv.app" : "localhost",
    //         path: "/",
    //     });
    //     Cookies.remove("userinfo", {
    //         domain: isProduction ? ".vliv.app" : "localhost",
    //         path: "/",
    //     });
    //     notifySuccess('Loggedout Successfully!!');
    //     // setTimeout(() => {
    //     //     // navigate("/mobile/signin");
    //     //     window.location.reload()
    //     // }, 500)

    // }
    return (
        <ul className='sibebar-container'>
            {routeToRender.map((item, index) => {
                const { path, name, icon } = item;
                return (
                    <li key={index} className='sidebar-list'>
                        <NavLink to={path} key={index} className={`sidebarlink`} onClick={() => handleDrawerClose(name)}>
                            <div className="sidebaricon">{icon}</div>
                            <div className={`sidebarlink`}>{name}</div>
                        </NavLink>
                    </li>
                )
            }
            )}
            {/* <li className='sidebar-list' onClick={handleLogout}>
                <NavLink to={'/mobile/signin'} className={`sidebarlink`}>
                    <div className="sidebaricon"><TbLogout2 /></div>
                    <div
                        // initial="hidden"
                        // animate="show"
                        // exit="hidden"
                        className={`sidebarlink`}
                    >
                        Logout
                    </div>
                </NavLink>
            </li> */}
        </ul>
    )
}

export default UserRoute;