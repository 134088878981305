import { Avatar, Box, Grid, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import React, { useContext } from 'react';
import noprofileimage from "../../../assets/No-image.webp";
import { AuthContext } from '../../../Context/AuthContextProvider';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../Constants/NotificationAlert';
import { TbLogout2 } from "react-icons/tb";
import Cookies from "js-cookie";

function Profile() {
  const isProduction = process.env.NODE_ENV === "production";
  const navigate = useNavigate();
  const { authContext, setAuthContext } = useContext(AuthContext);
  const { userid, username, userrole, email, userphone, companyname, clientimage } = authContext.userData;
  const { notifySuccess, notifyError, notifyWarning, notifyInfo } = useNotification();

  const handleLogout = () => {
    Cookies.remove("companytoken", {
      domain: isProduction ? ".vliv.app" : "localhost",
      path: "/",
    });
    Cookies.remove("userinfo", {
      domain: isProduction ? ".vliv.app" : "localhost",
      path: "/",
    });
    notifySuccess('Loggedout Successfully!!');
    setTimeout(() => {
      navigate("/mobile/signin");
      // window.location.reload()
    }, 500)
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack alignItems={'center'}>
          <Avatar
            alt="Profile Picture"
            src={clientimage ? clientimage : noprofileimage}
            sx={{
              width: 165,
              height: 165,
              border: '2px solid #fff',
              boxShadow: '0px 0px 15px 6px rgba(0, 0, 0, 0.3)'
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box p={1.6}>
          <Typography variant="subtitle1" color="var(--title-color)">User ID</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)" sx={{ margin: "-0.2rem 0rem 0.5rem 0rem" }}>{userid ? userid : "--"}</Typography>

          <Typography variant="subtitle1" color="var(--title-color)">Name</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)" sx={{ margin: "-0.2rem 0rem 0.5rem 0rem" }}>{username ? username : "--"}</Typography>

          <Typography variant="subtitle1" color="var(--title-color)">Designation</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)" sx={{ margin: "-0.2rem 0rem 0.5rem 0rem" }}>{userrole ? userrole : "--"}</Typography>

          <Typography variant="subtitle1" color="var(--title-color)">Official Email</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)" sx={{ margin: "-0.2rem 0rem 0.5rem 0rem" }}>{email ? email : "--"}</Typography>

          <Typography variant="subtitle1" color="var(--title-color)">Organization Name</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)" sx={{ margin: "-0.2rem 0rem 0.5rem 0rem" }}>{companyname ? companyname : "--"}</Typography>

          {/* <Typography variant="subtitle1" color="var(--title-color)">Personal Email</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)">{email ? email : "--"}</Typography> */}

          <Typography variant="subtitle1" color="var(--title-color)">Phone Number</Typography>
          <Typography variant="subtitle1" color="var(--brand-color)" sx={{ margin: "-0.2rem 0rem 0.5rem 0rem" }}>{userphone ? userphone : "--"}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={"center"}>
          <Button variant="outlined" onClick={handleLogout} startIcon={<TbLogout2 />} sx={{borderRadius:"0.4rem"}}>
            Logout
          </Button>
        </Stack>
      </Grid>

    </Grid>
  )
}

export default Profile