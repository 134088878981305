import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AlertPopUp from '../../../../Constants/AlertPopUp';

function CrudHistory() {
    //for success alert
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };


    //Redux
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(
        (state) => state.recruitmentReducer
    );

    //state
    const [tabvalue, setTabValue] = useState("crudhistory");

    //useeffect

    //Function
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <>
        <h1>Crud component</h1>
            {open && (
                <AlertPopUp
                    open={open}
                    msg={msg}
                    handleClose={handleClose}
                    type={alertType}
                />
            )}
        </>
    )
}

export default CrudHistory