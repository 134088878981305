import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecruitment } from "../../../../redux/recruitmentprocess/actions";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../../../../Constants/dateFormat";
import Loader from "../../../../Constants/Loader";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useNavigate } from "react-router-dom";

import {
  getJobApplications,
  multiStatusChangeJobApplication,

} from "../../../../redux/JobApplication/actions";

import InterView from "./Interview/InterView";
import ViewDetails from "./Interview/ViewDetails";
import axios from "axios";
import { getPayrollStatusColor } from "../../../../Constants/Colors";
import { processResumeScore } from "../../../../Constants/constants";

const InterViewProcess = () => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let userRole = userData.userrole ? userData.userrole : "";
  // COOKIES PART

  const navigate = useNavigate();

  const [scoreLoading, setScoreLoading] = useState(false);

  // REDUX PART
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.jobApplicationReducer
  );

  const { data: recruitmentMaster } = useSelector(
    (state) => state.recruitmentReducer
  );
  // REDUX PART

  const [tabvalue, setTabValue] = React.useState("screening");

  //for view modal
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
    setSelectedRow(params.row.id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  //for multi delete
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // for multi status change alert

  const multiHandleClose = (event, reason) => {
    setSelectedRows([]);
    if (reason === "clickaway") {
      return;
    }
  };

  const handleGenerateScore = async () => {
    // Filter screeningrow based on selectedRows
    let filteredRows = screeningRow.filter((row) =>
      selectedRows.includes(row.id)
    );

    // Map the filtered rows to the desired format
    let rows = filteredRows.map((row) => ({
      applicant_id: row.candidateid,
      "Job Description URL": row.jd,
      "Resume URL": row.cv,
    }));

    // console.log("reqbody", JSON.stringify({ rows }));

    try {
      setScoreLoading(true);
      const response = await fetch(
        "https://server-rr.onrender.com/predict_scores",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rows }),
        }
      );
      const data = await response.json();
      const processedScore=processResumeScore(data)
  
      if (processedScore && processedScore.length > 0) {
        const array = filteredRows.map((item) => {
          // Find the corresponding address in secondArr
          const obj = processedScore.find((obj) => obj.applicant_id === item.candidateid);

          // console.log(obj,"obj")
          // const inputString = obj.Score;
          // const score = parseInt(inputString.trim(), 10);
          return {
            id: item.id,
            score: obj.Score,
          };
        }
      
      );

      // console.log(array," score data needs to send ")

        try {
          let res = await axios.post(
            "https://hrms.vliv.app/jobapplication/updatescore",
            { array },{withCredentials:true}
          );
          // console.log(res,"score update res");
          if (res.data.message == "Resume score updated successfully") {
            dispatch(
              getJobApplications(
                { companyid, companyemail },
                setOpen,
                setMsg,
                setAlertType
              )
            );
          }
        } catch (error) {
          console.log(error, "error");
          setAlertType("error");
          setOpen(true);
          setMsg("Error While Generating Score...");
        }
      }
      setScoreLoading(false);
    } catch (error) {
      setScoreLoading(false);
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error While Generating Score...");
    }
  };

  // Custom renderer for status column
  const StatusCellRenderer = (params) => {
    const [status, setStatus] = React.useState(params.value);
    const dispatch = useDispatch();

    const handleStatusChange = (event) => {
      const newStatus = event.target.value;
      setStatus(newStatus);
      dispatch(
        multiStatusChangeJobApplication(
          { idarray: [params.row.id], status: newStatus },
          setOpen,
          setMsg,
          setAlertType
        )
      );
    };

    return (
      <Select
        value={status}
        onChange={handleStatusChange}
        displayEmpty
        fullWidth
        disabled={status === "Shortlist" || status == "Reject"}
        sx={{
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <MenuItem
          value="Applied"
          sx={{ color: getPayrollStatusColor("Applied") }}
        >
          Applied
        </MenuItem>
        <MenuItem
          value="Shortlist"
          sx={{ color: getPayrollStatusColor("Shortlist") }}
        >
          Shortlist
        </MenuItem>
        <MenuItem
          value="Reject"
          sx={{ color: getPayrollStatusColor("Reject") }}
        >
          Reject
        </MenuItem>
      </Select>
    );
  };

  // For DataGrid
  const screeningColumn = [
    { field: "candidateid", headerName: "CID", flex: 1 },
    { field: "recruitmentid", headerName: "RID", flex: 1 },
    { field: "createdAt", headerName: "Applied Date", flex: 1 },
    { field: "position", headerName: "Position", flex: 1 },
    { field: "fullname", headerName: "Name", flex: 1 },
    { field: "budget", headerName: "Budget", flex: 1 },
    { field: "expectedctc", headerName: "ECTC in LPA", flex: 1 },
    {
      field: "cv",
      headerName: "Resume",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => handleJDClick(params.row.cv)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: StatusCellRenderer,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    {
      field: "score",
      headerName: "Resume Score",
      flex: 1,
      cellClassName: "MuiDataGrid-cell--withRenderer",
      renderCell:(params)=><Box>{params.row.score && params.row.score.matchpercentage }</Box>
    },

  ];

  const screeningRow =
    data.length > 0
      ? data.map((application) => {
          const recruitMent = recruitmentMaster.find(
            (recruit) => recruit.id == application.recruitmentid
          );

          return {
            id: application.id,
            jd: application.jd,
            candidateid: application.candidateid,
            recruitmentid: `RID-${application.recruitmentid}`,
            createdAt: formatDate(application.createdAt),
            position: application.position,
            fullname: application.fullname,
            budget: recruitMent
              ? `${recruitMent.frombudget} - ${recruitMent.tobudget}`
              : "-",
            expectedctc: application.expectedctc,
            cv: application.cv,
            status: application.status,
            task: application.task,
            data: application,
            score: application.score || {},
          };
        })
      : [];

  const handleJDClick = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop() || "File";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const isRowSelectable = (params) => {
    return params.row.status == "Applied";
  };

  useEffect(() => {
    dispatch(
      getJobApplications(
        { companyid, companyemail },
        setOpen,
        setMsg,
        setAlertType
      )
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRecruitment({ companyid, companyemail }));
  }, []);

  // console.log(selectedRows, "selected rows checkbox");
  // console.log(screeningRow, "screening rows");

  return (
    <>
      <Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Screening"
                  value="screening"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Interview"
                  value="interview"
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box>
            <TabPanel value="screening">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={2}
                pr={2}
              >
                <Button
                  className="cancelBtn"
                  sx={{ my: 1 }}
                  variant="outlined"
                  disabled={selectedRows.length == 0}
                  onClick={() => {
                    dispatch(
                      multiStatusChangeJobApplication(
                        { idarray: selectedRows, status: "Shortlist" },
                        setOpen,
                        setMsg,
                        setAlertType
                      )
                    );
                    multiHandleClose();
                  }}
                >
                  Mark shortlist
                </Button>

                {!scoreLoading ? (
                  <Button
                    className="saveBtn"
                    sx={{ my: 1 }}
                    variant="outlined"
                    onClick={handleGenerateScore}
                    disabled={selectedRows.length == 0}
                  >
                    Generate Resume Score
                  </Button>
                ) : (
                  <Button
                    className="saveBtn"
                    sx={{ my: 1 }}
                    variant="outlined"
                    disabled
                  >
                    Generating Score...
                  </Button>
                )}
              </Box>
              <Box sx={{ height: "auto", width: "100%" }}>
                <DataGrid
                  rows={loading ? [] : screeningRow}
                  columns={screeningColumn}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15, 20, screeningRow.length]}
                  autoHeight
                  components={{
                    LoadingOverlay: <Loader />,
                  }}
                  loading={loading}
                  onRowClick={handleRowClick}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newSelection) => {
                    handleSelectionModelChange(newSelection);
                  }}
                  rowSelectionModel={selectedRows}
                  // getRowClassName={getRowClassName}
                  isRowSelectable={isRowSelectable}
                />
              </Box>
            </TabPanel>
            <TabPanel value="interview">
              <InterView />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />

      <ViewDetails
        open={modalOpen}
        handleClose={handleCloseModal}
        rowId={selectedRow}
      />
    </>
  );
};

export default InterViewProcess;
