import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Loader from "../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";

const FBPolicy = () => {

  const columns = [
    {
      field: "name",
      headerName: "Policy Name",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Effective From",
      headerClassName: "datagrid-header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      headerClassName: "datagrid-header",
      flex: 1,
    },
  ];
  return (
    <>
      <Container maxWidth="xl">
        <Stack direction="row" gap={5}>
          <Box
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"10px"}
            marginTop={"20px"}
            borderRadius={"15px"}
            width={"30%"}
            maxHeight={"310px"}
          >
            <Typography className="colored-heading">Add FBP Policy</Typography>
            <Divider />
            <br />
            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              marginBottom="20px"
            >
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>Policy Name</h4>
                <TextField
                  fullWidth
                  className="formInputs"
                  placeholder="-"

                />
              </Grid>
              <Grid item md={5.9} width={"90%"}>
                <h4 style={{ marginBottom: "5px" }}>Effective From</h4>
                <TextField
                  type="date"
                  fullWidth
                  className="formInputs"
                  placeholder="-"

                />
              </Grid>
            </Grid>
            <Box display={"flex"} justifyContent={"flex-end"} gap={"10px"}>
              {" "}
              <Button
                className="saveBtn"
                variant="contained"
                //onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Box
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            padding={"10px"}
            marginTop={"20px"}
            borderRadius={"15px"}
            width={"70%"}
          >
            <Typography className="colored-heading">
              List of FBP Policy
            </Typography>
            <Divider />
            <br />
            <Box
              sx={{
                maxHeight: 350,
                overflow: "auto",
                position: "relative",
                p: 1,
              }}
            >
              <DataGrid
                rows={[]}
                autoHeight
                components={{
                  LoadingOverlay: <Loader />,
                }}
                //loading={loading}
                columns={columns}
                hideFooterPagination={true}
              />
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default FBPolicy;
