import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import axios from "axios";
import { getAllowanceApi } from "../../../../Constants/api";
import AddAllowance from "./AddAllowance";
import Loader from "../../../../Constants/Loader";
import { allowanceObjects } from "../../../../Constants/constants";
import EditAllowance from "./EditAllowance";

const AllowanceStructure = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editDrawerOpen, SetEditDrawerOpen] = useState(false);

  const [editData, setEditData] = useState(null); // State for handling edit data
  const [alert, setAlert] = useState({ open: false, msg: "", type: "success" });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEditDrawerClose = () => {
    SetEditDrawerOpen(false);
    setEditData(null);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleEdit = (allowance) => {
    setEditData(allowance);
    SetEditDrawerOpen(true);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        getAllowanceApi,
        {},
        { withCredentials: true }
      );
      setData(res.data || []);
    } catch (error) {
      console.log(error);
      setAlert({
        open: true,
        msg: "Error while getting Allowances",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [drawerOpen, editDrawerOpen]);

  const earningsFields = [
    "basic",
    "houserent",
    "special",
    "education",
    "medical",
    "insurance",
    "internet",
    "vehicle",
    "travel",
    "conveyance",
    "otherallowance",
  ];

  const deductionsFields = [
    "pfemployee",
    "pfemployer",
    "esicemployee",
    "esicemployer",
    "ptax",
    "tds",
    "otherdeduction",
  ];

  return (
    <>
      <Box borderRadius={2} p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Typography component="h4" variant="h5" className="primarycolor">
            Allowance Structures
          </Typography>
          <Button className="saveBtn" onClick={() => setDrawerOpen(true)}>
            Add New Structure
          </Button>
        </Box>

        {loading ? (
          <Loader />
        ) : (
          data.map((allowance) => (
            <Accordion key={allowance.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Typography fontSize={"18px"}>
                    {allowance.name} {`(${allowance.type})`}
                  </Typography>
                  <IconButton onClick={() => handleEdit(allowance)}>
                    <EditIcon />
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {/* Earnings */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Earnings</Typography>
                    {earningsFields.map((field) => (
                      <Box key={field} my={1} display={"flex"} alignItems={"center"} gap={1}>
                        <Typography className="view-page-value">
                          {allowance[field] &&
                            `${allowanceObjects[field] || field} :`}
                        </Typography>
                        <Typography className="view-page-title">
                          {`${allowance[field]} ${
                            allowance.type == "Amount" ? "₹" : "%"
                          }`}
                        </Typography>
                      </Box>
                    ))}
                  </Grid>

                  {/* Deductions */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Deductions</Typography>
                    {deductionsFields.map((field) => (
                      <Box key={field} my={1} display={"flex"} alignItems={"center"} gap={1}>
                        <Typography className="view-page-value">
                          {allowance[field] &&
                            `${allowanceObjects[field] || field} :`}
                        </Typography>
                        <Typography className="view-page-title">
                          {`${allowance[field]} ${
                            allowance.type == "Amount" ? "₹" : "%"
                          }`}
                        </Typography>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>

      <AddAllowance
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        isSmallScreen={isSmallScreen}
        data={editData} // Pass data for editing
      />
      <EditAllowance
        editDrawerOpen={editDrawerOpen}
        editData={editData}
        handleEditDrawerClose={handleEditDrawerClose}
        isSmallScreen={isSmallScreen}
        setEditData={setEditData}
      />
      <AlertPopUp
        open={alert.open}
        msg={alert.msg}
        handleClose={() => setAlert({ ...alert, open: false })}
        type={alert.type}
      />
    </>
  );
};

export default AllowanceStructure;
