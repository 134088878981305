import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Divider,
    Grid,
} from "@mui/material";

import axios from "axios";
import { recruitmentGetByIdApi } from "../../../../Constants/api";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";
import { formatDate } from "../../../../Constants/dateFormat";
import { getFileNameFromUrl } from "../../../../Constants/constants";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    overflow: "hidden",
    "&::-webkit-scrollbar": {
        width: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "teal",
    },
};

const boxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    my: 1.5,
};

const childStyle = {
    flex: 1,
};

const AttendanceDetailsModal = ({ open, handleClose, selectedRowData }) => {
    console.log("selectedRowData", selectedRowData);

    const [data, setData] = useState({
        headername: "Attendance Details",
        employeeid: "",
        name: "",
        position: "",
        month: "",
        year: "",
        location: "",
        day: "",
        companyemail: "",
        companyid: "",
        checkout: "",
        checkin: "",
        date: "",
        location2: "",
        longitude: "",
        latitude: ""
    });
    const [alertopen, setAlertOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [alertType, setAlertType] = useState("success");

    useEffect(() => {
        if (selectedRowData) {
            setData((prev) => ({
                ...prev,
                ...selectedRowData,
            }))
        }

    }, [selectedRowData]);

    // Fetch location when latitude and longitude are available
    useEffect(() => {
        if (data.latitude && data.longitude && !data.location2) {
            fetchLocationFromLogitudeAndLatitude(data.latitude, data.longitude);
        }
    }, [data.latitude, data.longitude]);

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertOpen(false);
    };

    const fetchLocationFromLogitudeAndLatitude = async (latitude, longitude) => {
        const API_KEY = "AIzaSyB3Mbhn-OA-Xd_CIQ0MtgzqgpX5Q3-RF54";
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`;

        try {
            const response = await axios.get(url);
            if (response.data.results && response.data.results.length > 0) {
                const result = response.data.results[0];
                setData((prev) => ({
                    ...prev,
                    location2: result.formatted_address,
                }));
            } else {
                console.error("No results found for the given coordinates.");
                setAlertType("error");
                setAlertOpen(true);
                setMsg("Unable to fetch location name.");
            }

        } catch (error) {
            console.error("Error fetching location name:", error);
            setAlertType("error");
            setAlertOpen(true);
            setMsg("Unable to fetch location name.");
        }
    }

    return (
        <Modal open={Boolean(open)} onClose={handleClose} disablePortal={true} disableEnforceFocus={true}>
            <Box sx={style}>
                <Box display="flex" justifyContent="space-between" px={2} py={0.5}>
                    <Typography
                        color={"#000A14"}
                        fontSize={"1.3rem"}
                        fontWeight={"500"}
                        textAlign={"center"}
                    >
                        {data.headername}
                    </Typography>

                    <Typography
                        color={"#000A14"}
                        fontSize={"1.3rem"}
                        fontWeight={"500"}
                        textAlign={"center"}
                    >
                        {/* RID-{rowId} */}
                    </Typography>

                    <IconButton onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Grid container px={2} sx={{ backgroundColor: "#F4FAFF", flex: 1, p: 2, gap: 2 }}>
                    <Grid item md={5.5} sm={12} xs={12} className="view-left">
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Employee ID{" "}
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.employeeid}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Name{" "}
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.name}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Position{" "}
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.position}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Day{" "}
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.day}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Month
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.month}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Year
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.year}
                            </p>
                        </Box>
                    </Grid>
                    <Grid item md={5.5} sm={12} xs={12} className="view-right">
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>companyemail :</p>
                            <p className="view-page-value" style={childStyle}>{data.companyemail}</p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>Company Id :</p>
                            <p className="view-page-value" style={childStyle}>{data.companyid}</p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>Date :</p>
                            <p className="view-page-value" style={childStyle}>{data.date}</p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Checkin Time :
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.checkin}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Checkout Time :
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.checkout}
                            </p>
                        </Box>
                        <Box sx={boxStyle}>
                            <p className="view-page-title" style={childStyle}>
                                Location :
                            </p>
                            <p className="view-page-value" style={childStyle}>
                                {data.location2 || "Fetching location..."}
                            </p>
                        </Box>
                    </Grid>
                </Grid>
                <AlertPopUp
                    open={alertopen}
                    msg={msg}
                    handleClose={handleAlertClose}
                    type={alertType}
                />
            </Box>


        </Modal>
    );
};

export default AttendanceDetailsModal;
