import {
  Box,
  Button,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../../../../Constants/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getEmpExit } from "../../../../../redux/actions/actions.hrms/employeeExit.actions";
import { formatDate } from "../../../../../Constants/dateFormat";
import { getPayrollStatusColor } from "../../../../../Constants/Colors";
import axios from "axios";
import AlertPopUp from "../../../../../Constants/AlertPopUp";

const Separation = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emp_exit_loading, emp_exit_error, empExitData } = useSelector(
    (state) => state.empExitReducer
  );

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    // Handle search logic here
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    return (
      empExitData &&
      empExitData.length > 0 &&
      empExitData?.filter((item) =>
        searchValue
          ? Object.values(item).some((value) =>
              value
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase().trim())
            )
          : true
      )
    );
  };

  const filteredData = filterData();
  const rows = filteredData;

  const handleAddSeparation = () => {
    navigate(`/hrm/employeemanagement/employeeexit/separation/add`);
  };

  const handleRowClick = (params) => {
    navigate(
      `/hrm/employeemanagement/employeeexit/separation/update?id=${params.row.id}&report=${params.row.reportmanager}`
    );
  };

  const StatusCellRenderer = (params) => {
    // console.log(params, "params status");

    const [status, setStatus] = useState("");

    const handleStatusChange = async (e) => {
      try {
        let res = await axios.post(
          "https://hrms.vliv.app/exit/addstatus",
          {
            id: params.row.id,
            employeeid: companyInfo.empid,
            status: e.target.value,
          },
          { withCredentials: true }
        );
        // console.log(res,"res")
        if (res.data && res.data.message == "Status updated successfully") {
          setAlertType("success");
          setOpen(true);
          setMsg(res.data.message);
          dispatch(
            getEmpExit({
              companyid: companyInfo.companyid,
              companyemail: companyInfo.companyemail,
            })
          );
        }
      } catch (error) {
        console.log(error, "error while changing status");
        setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message);
      }
    };

    useEffect(() => {
      if (params.row.managers) {
        const managerData = params.row.managers.find((ele) => {
          return ele.email == companyInfo.email;
        });
        managerData ? setStatus(managerData.status) : setStatus("Pending");
      } else {
        setStatus("Pending");
      }
    }, []);

    // console.log(status, "status cell");

    if (
      (companyInfo.userrole == "HR" && params.row.status == "Rejected") ||
      (companyInfo.userrole == "HR" && params.row.status == "Approved")
    ) {
      // console.log("i am")
      return (
        
          <Typography color={params.row.status == "Rejected" ? "red" : "green"} mt={2} ml={2}>
            {params.row.status}
          </Typography>
        
      );
    }

    return (
      <>
        <Select
          value={status}
          onChange={handleStatusChange}
          displayEmpty
          fullWidth
          sx={{
            border: "none",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            color: `${getPayrollStatusColor(status)}.main`,
          }}
        >
          <MenuItem value="Pending" sx={{ color: "warning.main" }}>
            Pending
          </MenuItem>
          <MenuItem value="Approved" sx={{ color: "success.main" }}>
            Approved
          </MenuItem>
          <MenuItem value="Rejected" sx={{ color: "error.main" }}>
            Rejected
          </MenuItem>
        </Select>
      </>
    );
  };

  const columns = [
    {
      field: "empid",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "reportmanager",
      headerName: "Reporting To",
      flex: 1,
    },
    {
      field: "applyingdate",
      headerName: "Applying Date",
      flex: 1,
      renderCell: (params) => <>{formatDate(params.row.applyingdate)}</>,
    },
    // {
    //   field: "noticeperiod",
    //   headerName: "Notice Period",
    //   flex: 1,
    // },
    {
      field: "exittype",
      headerName: "Type of Exit",
      flex: 1,
    },
    {
      field: "leavingreason",
      headerName: "Reason",
      flex: 1,
    },

    {
      field: "newstatus",
      headerName: "Status",
      flex: 1,
      renderCell: StatusCellRenderer,
    },
  ];

  useEffect(() => {
    dispatch(
      getEmpExit({
        companyid: companyInfo.companyid,
        companyemail: companyInfo.companyemail,
      })
    );
  }, [dispatch]);
  return (
    <>
      <Box>
        <Box className="searchbar-container">
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
            <Button className="saveBtn" onClick={handleAddSeparation}>
              Add
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={emp_exit_loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[5, 10, 15, 20]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={emp_exit_loading}
          />
        </Box>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default Separation;
