import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import axios from "axios";

const EditTeamBuildingKpi = ({
  openDialog,
  handleCloseDialog,
  selectedRow,
  isSmallScreen,
  allOrgKpi,
}) => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  //for alert msg
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [data, setData] = useState({
    empid: "",
    teamreview: "",
    teamrating: "",
    teamstatus: "",
    orgreview: "",
    orgrating: "",
    orgstatus: "",
    empreview: "",
    emprating: "",
    empstatus: "",
    totalrating: "",
    companyemail,
    companyid,
    id: "",
  });

  const [loading,setLoading]=useState(false);

  const handleRowChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedOrgReview = data.teamreview.map((orgkpi, i) => {
      if (i == ind) {
        if (name == "rate") {
          return { ...orgkpi, [name]: +value };
        } else {
          return { ...orgkpi, [name]: value };
        }
      } else {
        return orgkpi;
      }
    });
    setData((prev) => ({ ...prev, teamreview: updatedOrgReview }));
  };

  const handleSave = async () => {
    const totalOrgRating = data.teamreview.reduce(
      (sum, kpi) => sum + kpi.rate,
      0
    );
    // console.log(totalRating,"totalrating")
    const avgOrgRating = (totalOrgRating / data.teamreview.length).toFixed(2);

    const totalrating = (
      (Number(avgOrgRating) + Number(data.emprating || 0) + Number(data.orgrating || 0)) /
      3
    ).toFixed(2);
    try {
      setLoading(true);
      let res = await axios.post("https://hrms.vliv.app/performance/update", {
        ...data,
        teamrating: avgOrgRating,
        totalrating,
        teamstatus: "Completed",
      },{withCredentials:true});
      if (res.data.message == "Performance updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Performance updated successfully");
        setTimeout(() => {
          handleCloseDialog();
        }, 1000);
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
        setOpen(true);
        setMsg(error.response.data.message || "Something went wrong");
        setTimeout(() => {
          handleCloseDialog();
        }, 1000);
    }finally{
      setLoading(false)
    }
  };

  function stringAvatar(name, small = false) {
    return {
      sx: {
        bgcolor: "grey",
        width: small ? "40px" : "70px",
        height: small ? "40px" : "70px",
      },
      children: name ? `${name.split(" ")[0][0]}${name.split(" ")[0][1]}` : "",
    };
  }

  const getPerformanceById = async () => {
    try {
      let res = await axios.post("https://hrms.vliv.app/performance/getbyid", {
        id: selectedRow.id,
      },{withCredentials:true});
      // console.log(res, "res111");
      if (res.data) {
        if (res.data.teamreview) {
          setData((prev) => ({ ...prev, ...res.data }));
        } else {
          setData((prev) => ({
            ...prev,
            ...res.data,
            teamreview: allOrgKpi.map((orgkpi) => ({
              kpiname: orgkpi.kpiname,
              rate: 0,
              comment: "",
            })),
          }));
        }
      } else {
        setData((prev) => prev);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (selectedRow && selectedRow.id) {
      getPerformanceById();
    }
  }, [selectedRow]);

  //   console.log(data, "dataaaaaaaaaaaa");

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <Box py={1}>
            {selectedRow && selectedRow.data && (
              <Box p={1.5}>
                <Stack
                  direction={isSmallScreen ? "column" : "row"}
                  gap={isSmallScreen ? 2 : 5}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <Stack direction="row" gap={2} alignItems="center">
                    <Avatar {...stringAvatar(selectedRow.data.name)} />
                    <Box>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: isSmallScreen ? "16px" : "18px",
                        }}
                      >
                        {selectedRow.data.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: isSmallScreen ? "14px" : "16px",
                        }}
                      >
                        {selectedRow.data.role}
                      </Typography>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: isSmallScreen ? "14px" : "16px",
                        }}
                      >
                        {selectedRow.data.email}
                      </Typography>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: isSmallScreen ? "14px" : "16px",
                      }}
                    >
                      Employee ID
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: isSmallScreen ? "16px" : "18px",
                      }}
                    >
                      {selectedRow.data.empid}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: isSmallScreen ? "14px" : "16px",
                      }}
                    >
                      Department
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: isSmallScreen ? "16px" : "18px",
                      }}
                    >
                      {selectedRow.data.department}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: isSmallScreen ? "14px" : "16px",
                      }}
                    >
                      Sub-Department
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: isSmallScreen ? "16px" : "18px",
                      }}
                    >
                      {selectedRow.data.subdepartment
                        ? selectedRow.data.subdepartment
                        : "--"}
                    </Typography>
                  </Box>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Avatar
                      {...stringAvatar(selectedRow.data.reportmanager, true)}
                    />
                    <Box>
                      <Typography
                        sx={{
                          color: "grey",
                          fontSize: isSmallScreen ? "14px" : "16px",
                        }}
                      >
                        Reporting Manager
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: isSmallScreen ? "16px" : "18px",
                        }}
                      >
                        {selectedRow.data.reportmanager}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            )}
          </Box>
          <Divider />
          <Box my={1}>
            <TableContainer >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#E4E7EC" }}>
                    <TableCell>Team Building KPI</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.teamreview &&
                    data.teamreview?.map((orgkpi, ind) => (
                      <TableRow key={ind}>
                        <TableCell>
                          {orgkpi.kpiname ? orgkpi.kpiname : "-"}
                        </TableCell>
                        <TableCell>
                          <Rating
                            name="rate"
                            precision={0.5}
                            value={orgkpi.rate}
                            onChange={(e) => handleRowChange(e, ind)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            value={orgkpi.comment}
                            name="comment"
                            readOnly
                            placeholder="Enter Your Comments"
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                border: "none",
                              },
                            }}
                            sx={{
                              border: "none",
                              ".MuiInputBase-root": {
                                borderBottom: "none !important",
                              },
                            }}
                            onChange={(e) => handleRowChange(e, ind)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            className="cancelBtn"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} className="saveBtn" disabled={loading}>
            {loading?"Submitting...":"Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleCloseAlert}
        type={alertType}
      />
    </>
  );
};

export default EditTeamBuildingKpi;
