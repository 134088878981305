import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Paper,
  IconButton,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Loader from "../../../../Constants/Loader";
import {
  formatDate,
  getColorForStatus,
} from "../../../../Constants/dateFormat";
import AlertPopUp from "../../../../Constants/AlertPopUp";

const ReimbursementApprove = () => {
  const navigate = useNavigate();
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";

  let userRole = userData.userrole ? userData.userrole : "";

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [remark, setRemark] = useState("");
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedReimbursement, setSelectedReimbursement] = useState(null);
  const [newStatus, setNewStatus] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getReimbursement = async () => {
    try {
      setLoading(true);
      let res = await axios.post(
        "https://hrms.vliv.app/employee/reimbursement/get",
        { companyemail, companyid },
        { withCredentials: true }
      );
      res.data && res.data.length > 0 ? setData(res.data) : setData([]);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting Reimbursements");
    }
  };

  useEffect(() => {
    getReimbursement();
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Status options
  const statusOptions = ["Pending", "Approve", "Reject"];

  const handleStatusChange = async () => {
    if (userRole.toLowerCase() !== "hr") {
      setAlertType("error");
      setOpen(true);
      setMsg("Access Denied..!");
      return;
    }
    // console.log( { id: selectedReimbursement.id, status: newStatus, remark }, "remark status change fucntion")
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/reimbursement/updatestatus",

        { id: selectedReimbursement.id, status: newStatus, remark },
        { withCredentials: true }
      );
      if (res.data.message === "Status updated successfully") {
        setAlertType("success");
        setOpen(true);
        setMsg("Status Changed...");
        getReimbursement();
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg(error.response.data.message || "Something went wrong");
    } finally {
      setStatusDialogOpen(false);
      setRemark("");
      setSelectedReimbursement(null);
    }
  };

  const handleStatusSelect = (row, status) => {
    setSelectedReimbursement(row);
    setNewStatus(status);
    setStatusDialogOpen(true);
  };

  const columns = [
    { field: "empid", headerName: "EMP ID", width: 130 },
    { field: "empname", headerName: "EMP Name", width: 130 },
    { field: "fromdate", headerName: "From Date", width: 130 },
    { field: "tilldate", headerName: "Till Date", width: 130 },
    { field: "type", headerName: "Reimbursement Type", width: 180 },
    { field: "amount", headerName: "Amount", width: 130 },
    { field: "billno", headerName: "Bill No", width: 130 },
    {
      field: "attach",
      headerName: "Attachment",
      width: 130,
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => handleJDClick(params.row)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          onChange={(e) => handleStatusSelect(params.row, e.target.value)}
          sx={{
            border: "none",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            color: getColorForStatus(params.row.status),
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          {statusOptions.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    { field: "remark", headerName: "Remarks", width: 200 },
  ];

  const rows = data.map((reimburse) => ({
    id: reimburse.id,
    empid: reimburse.empid,
    empname: reimburse.empname,
    fromdate: reimburse.fromdate ? formatDate(reimburse.fromdate) : "-",
    tilldate: reimburse.tilldate ? formatDate(reimburse.tilldate) : "-",
    type: reimburse.type,
    amount: reimburse.amount,
    status: reimburse.status,
    billno: reimburse.billno,
    attach: reimburse.attach,
    description: reimburse.description,
    remark: reimburse.remark,
    data: reimburse,
  }));

  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
  };

  const handleJDClick = (row) => {
    const link = document.createElement("a");
    link.href = row.attach;
    link.download = row.attach.split("/").pop() || "Attachments";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box py={2}>
        <Box my={1}>
          <Paper style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={loading ? [] : rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, rows.length]}
              getRowId={(row) => row.id}
              autoHeight
              components={{
                LoadingOverlay: <Loader />,
              }}
              loading={loading}
              onRowClick={handleRowClick}
            />
          </Paper>
        </Box>
      </Box>

      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />

      <Dialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
      >
        <DialogTitle>Add Remark</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Remark"
            fullWidth
            variant="outlined"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStatusChange} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReimbursementApprove;
