import {
  Box,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import CreateEmployee from "./CreateEmployee";
import Loader from "../../../../Constants/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getEmpReg } from "../../../../redux/actions/actions.hrms/empRegistration.actions";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const Registration = () => {
  const cookieData = Cookies.get("userinfo");
  let companyInfo = cookieData ? JSON.parse(cookieData) : {};
  let companyid = companyInfo.companyid ? companyInfo.companyid : "";
  let companyemail = companyInfo.companyemail ? companyInfo.companyemail : "";
  let userRole = companyInfo.userrole ? companyInfo.userrole : "";
  let username = companyInfo.username ? companyInfo.username : "";

  const [dropDowns, setDropDowns] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { empData, error, loading } = useSelector(
    (state) => state.empRegistrationReducer
  );

  const columns = [
    {
      field: "empid",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Mail ID",
      flex: 1,
    },
    {
      field: "reportmanager",
      headerName: "Reporting Manager",
      flex: 1,
    },
  ];

  const filterData = () => {
    if (userRole == "Manager") {
      return (
        empData &&
        empData.length > 0 &&
        empData
          ?.filter((emp) => emp.reportmanager == username)
          ?.filter((item) =>
            searchValue
              ? Object.values(item).some((value) =>
                  value
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase().trim())
                )
              : true
          )
      );
    } else {
      return (
        empData &&
        empData.length > 0 &&
        empData?.filter((item) =>
          searchValue
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchValue.toLowerCase().trim())
              )
            : true
        )
      );
    }
  };

  const filteredData = filterData();
  const rows = filteredData;

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleRowClick = (params) => {
    navigate(`/hrm/employeemanagement/registration/update/${params.row.id}`);
  };

  const getDropdowns = async () => {
    try {
      let res = await axios.post(
        `https://hrms.vliv.app/employee/position/get`,
        {
          companyid,
          companyemail,
        },{withCredentials:true}
      );
      res.data && res.data.length > 0
        ? setDropDowns(res.data)
        : setDropDowns([]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    dispatch(
      getEmpReg({
        companyid,
        companyemail,
      })
    );
    getDropdowns();
  }, [dispatch]);

  // console.log(dropDowns,"dropdowns");

  return (
    <>
      <Box>
        <Box className="searchbar-container">
          <Hidden smDown>
            <Box>
              {/* Content to be shown only on screens larger than or equal to sm (600px) */}
            </Box>
          </Hidden>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Search…"
              variant="outlined"
              className="searchbar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
            <CreateEmployee dropDowns={dropDowns} allEmp={empData} />
          </Box>
        </Box>
        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            rows={loading ? [] : rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            onRowClick={handleRowClick}
            pageSizeOptions={[
              10,
              20,
              30,
              40,
              50,
              rows.length > 0 && +rows.length,
            ]}
            autoHeight
            components={{
              LoadingOverlay: <Loader />,
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default Registration;
